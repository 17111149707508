import type { ButtonProps } from '@loadsmart/miranda-react'
import { Button } from '@loadsmart/miranda-react'
import { useGoToNewFulfillment } from 'fulfillments/hooks/navigation'

export function NewFulfillmentButton({
  variant = 'primary',
}: Readonly<{ variant?: ButtonProps['variant'] }>) {
  const goToNewFulfillmentForm = useGoToNewFulfillment()

  return (
    <Button variant={variant} onClick={goToNewFulfillmentForm}>
      New fulfillment
    </Button>
  )
}
