import type { AxiosRequestConfig } from 'axios'

import httpClient from 'utils/httpClient'

import type {
  ExtraChargeType,
  GenericApiSelectOptionWithCount,
} from '../../services/types'

export const fetchProposalsEquipmentTypes = async (
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get(
    `proposals/equipment_types?allow_not_set=true&allow_counts=true`,
    config
  )
  return data
}

export const fetchAwardAcceptanceDeadlines = async (
  rfpId: number,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get(
    `proposals/award_acceptance_deadlines/?rfp_id=${rfpId}`,
    config
  )
  return data
}

export const fetchExtraChargeTypes = async (config?: AxiosRequestConfig) => {
  const { data } = await httpClient.get<ExtraChargeType[]>(
    `proposals/extra_charge_type`,
    config
  )
  return data
}

export const fetchProposalCarrierInviteStates = async (
  rfpId: string | number,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get<GenericApiSelectOptionWithCount[]>(
    `proposals/carrier_invite_states?allow_not_set=true&allow_counts=true&rfp_id=${rfpId}`,
    config
  )
  return data
}

export const fetchProposalsBidStatus = async (config?: AxiosRequestConfig) => {
  const { data } = await httpClient.get<GenericApiSelectOptionWithCount[]>(
    `proposals/bid_statuses?allow_not_set=true&allow_counts=true`,
    config
  )
  return data
}

export const fetchProposalsRateTypes = async (config?: AxiosRequestConfig) => {
  const { data } = await httpClient.get<GenericApiSelectOptionWithCount[]>(
    `proposals/rfp/rate_types?allow_not_set=true&allow_counts=true`,
    config
  )
  return data
}

export const fetchProposalsTimePeriods = async (
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get<GenericApiSelectOptionWithCount[]>(
    `proposals/rfp/time_periods?allow_not_set=true&allow_counts=true`,
    config
  )
  return data
}

export const fetchProposalsModes = async (config?: AxiosRequestConfig) => {
  const { data } = await httpClient.get<GenericApiSelectOptionWithCount[]>(
    `proposals/rfp/modes?allow_not_set=true&allow_counts=true`,
    config
  )
  return data
}
