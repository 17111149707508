import { useCallback } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import { AppRoutes } from 'router/AppRoutes'

export function useGoToOrderDetails() {
  const history = useHistory()

  const goToOrderDetails = useCallback(
    (uuid: string) => {
      history.push(
        generatePath(AppRoutes.OrderDetails, {
          orderId: uuid,
        })
      )
    },
    [history]
  )

  return goToOrderDetails
}
