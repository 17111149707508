import { defaultTo } from 'lodash'
import { useCallback } from 'react'

import { generateUseSearchParamsPlugin, useFilters } from 'utils/filters'

type OrderSelectionParams = {
  orders: string[]
}

function getDefaultOrderSelectionQueryParams(
  overrides?: Partial<OrderSelectionParams>
): OrderSelectionParams {
  return {
    orders: [],
    ...overrides,
  }
}

const useOrderSelectionQueryParamsPlugin =
  generateUseSearchParamsPlugin<OrderSelectionParams>({
    orders: {
      type: 'primitive-collection',
    },
  })

export function useOrderSelection() {
  const { setFilter, values } = useFilters(
    { initialValues: getDefaultOrderSelectionQueryParams() },
    useOrderSelectionQueryParamsPlugin
  )
  const { orders } = values
  const selectedOrderUUIDs = defaultTo(orders, [])

  const setSelectedOrders = useCallback(
    (newSelected: string[]) => {
      setFilter('orders', newSelected)
    },
    [setFilter]
  )

  const addSelectedOrders = useCallback(
    (toBeAdded: string | string[]) => {
      const array = Array.isArray(toBeAdded) ? toBeAdded : [toBeAdded]
      const newOrdersArray = [...selectedOrderUUIDs, ...array]
      setFilter('orders', newOrdersArray)
    },
    [selectedOrderUUIDs, setFilter]
  )

  const removeSelectedOrders = useCallback(
    (toBeRemoved: string | string[]) => {
      const newOrdersArray = selectedOrderUUIDs.filter((uuid) => {
        if (Array.isArray(toBeRemoved)) {
          return !toBeRemoved.includes(uuid)
        }
        return toBeRemoved !== uuid
      })

      setFilter('orders', newOrdersArray)
    },
    [selectedOrderUUIDs, setFilter]
  )

  const clearSelectedOrders = useCallback(() => {
    setFilter('orders', [])
  }, [setFilter])

  return {
    addSelectedOrders,
    clearSelectedOrders,
    removeSelectedOrders,
    selectedOrderUUIDs,
    setSelectedOrders,
  }
}
