import type { AxiosRequestConfig } from 'axios'

import httpClient from 'utils/httpClient'

export const getMetadata = async (config?: AxiosRequestConfig) => {
  const { data } = await httpClient.get('metadata', config)
  return data
}

export const getTarpTypes = async (config?: AxiosRequestConfig) => {
  const { data } = await httpClient.get('metadata/tarp-types', config)
  return data
}

export const getSubEquipment = async (config?: AxiosRequestConfig) => {
  const { data } = await httpClient.get('metadata/equipment-subtypes', config)
  return data
}
