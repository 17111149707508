import { Layout } from '@loadsmart/miranda-react'
import type {
  AlignItemsValue,
  StackProps,
  GridProps,
} from '@loadsmart/miranda-react'
import type { ReactNode } from 'react'
import styled, { css } from 'styled-components'

type GridPropsV2 = {
  rows: number
  children?: ReactNode
  columns: number
  align?: AlignItemsValue
} & Omit<GridProps, 'ref'>

interface StyledGridProps {
  $rows: GridPropsV2['rows']
  $columns: GridPropsV2['columns']
  gap: GridPropsV2['gap']
  rowGap: GridPropsV2['rowGap']
  columnGap: GridPropsV2['columnGap']
  align: GridPropsV2['align']
}

type ColumnProps = {
  columns: number
  children?: ReactNode
  columnStart?: number
} & Omit<StackProps, 'ref'>

type StyledColumnProps = {
  $columns: ColumnProps['columns']
  $columnStart: ColumnProps['columnStart']
} & Omit<StackProps, 'ref'>

const StyledGrid = styled(Layout.Grid)<StyledGridProps>`
  align-items: ${({ align }) => align || 'normal'};
  display: grid;
  grid-template-columns: repeat(${({ $columns }) => $columns}, minmax(0, 1fr));
  grid-template-rows: repeat(${({ $rows }) => $rows}, min-content);
`

export const GridV2 = ({
  columns = 1,
  rows = 1,
  gap = 'spacing-4',
  children,
  rowGap,
  columnGap,
  align,
  ...rest
}: Partial<GridPropsV2>) => (
  <StyledGrid
    $columns={columns}
    $rows={rows}
    gap={gap}
    rowGap={rowGap}
    columnGap={columnGap}
    align={align}
    {...rest}
  >
    {children}
  </StyledGrid>
)

const StyledColumn = styled(Layout.Stack)<StyledColumnProps>`
  grid-column: auto/span ${({ $columns }) => ($columns > 0 ? $columns : 1)};

  ${({ $columnStart }) => {
    if ($columnStart) {
      return css`
        grid-column-start: ${$columnStart};
      `
    }

    return null
  }}
`

export const Column = ({
  columns = 1,
  columnStart,
  children,
  ...rest
}: Partial<ColumnProps>) => (
  <StyledColumn $columns={columns} $columnStart={columnStart} {...rest}>
    {children}
  </StyledColumn>
)

export const Grid = styled.div<{
  size: number
  columns?: number
  offset?: number
}>`
  flex: 0 0 ${(p) => (p.size / (p.columns ? p.columns : 12)) * 100}%;
  margin-left: ${(p) => {
    const columns = p.columns ? p.columns : 12

    return (p.offset ? p.offset / columns : 0) * 100
  }}%;
  max-width: ${(p) => (p.size / (p.columns ? p.columns : 12)) * 100}%;
  padding: 0 30px;
`
