import { Select } from '@loadsmart/loadsmart-ui'
import { Field } from '@loadsmart/miranda-react'

import { fromSelectOption } from 'utils/selectOption'

import type { CustomFormFieldProps } from '../HandlingUnits.types'
import { WEIGHT_TYPE_OPTIONS, getWeightTypeOption } from '../HandlingUnitsUtils'
import { useHandlingUnitFormField } from './HandlingUnitsForm.hooks'

export const HandlingUnitWeightType = (props: CustomFormFieldProps) => {
  const { required, hint, style } = props
  const {
    controlProps,
    fieldProps,
    hintProps,
    labelProps,
    value,
    setValue,
    name,
  } = useHandlingUnitFormField({ name: 'weight_type', hint })

  return (
    <Field {...fieldProps} required={required} style={style}>
      <Field.Label {...labelProps}>Set the weight</Field.Label>
      <Select
        getInputProps={() => controlProps}
        disabled={Boolean(controlProps.disabled)}
        name={name}
        placeholder="Select"
        hideClear={required}
        options={WEIGHT_TYPE_OPTIONS}
        value={getWeightTypeOption(value)}
        onChange={(event) => {
          const {
            target: { value: option },
          } = event

          const newValue = fromSelectOption(option as SelectOption)
          setValue(newValue)
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
