import { useMemo, useRef } from 'react'
import type {
  QueryFunctionContext,
  QueryObserverOptions,
  UseQueryOptions,
} from 'react-query'
import { useQueries, useQuery } from 'react-query'

import { getRetries } from '_shared_/utils/query'
import type { FacilityDetailsV2 } from 'services/facilities'
import { getFacilityDetails } from 'services/facilities'

export function useFacilityDetailsV2(
  facilityUUID?: string | null,
  options?: Omit<
    UseQueryOptions<FacilityDetailsV2, Error, FacilityDetailsV2, string[]>,
    'queryKey' | 'queryFn'
  >
) {
  return useQuery({
    enabled: facilityUUID != null,
    refetchInterval: false,
    staleTime: 0,
    retry: false,
    refetchOnWindowFocus: false,
    ...options,
    queryKey: ['getFacilityDetailsV2', facilityUUID!],
    queryFn({ signal }) {
      return getFacilityDetails(facilityUUID!, { signal })
    },
  })
}

export function useMultipleFacilityDetailsV2(
  uuids: string[],
  options?: QueryObserverOptions<FacilityDetailsV2>
) {
  /**
   * There will be a "combine" option in react-query v5 (Source: https://github.com/TanStack/query/issues/5137#issuecomment-1547390616)
   * In the meantime, we can use the following easy "hack" to memoize the queries result (Source: https://github.com/TanStack/query/issues/5137#issuecomment-2199989432)
   */
  const settledQueriesCounter = useRef(0)
  const queries = useQueries(
    uuids.map((uuid) => ({
      queryKey: ['getFacilityDetailsV2', uuid],
      staleTime: 0,
      refetchOnWindowFocus: false,
      retry: getRetries(3),
      ...options,
      queryFn: ({ signal }: QueryFunctionContext) => {
        return getFacilityDetails(uuid, { signal })
      },
      onSettled: () => {
        settledQueriesCounter.current = settledQueriesCounter.current + 1
      },
    }))
  )

  const result = useMemo(() => {
    const facilities = queries.map((query) => query.data)
    const isLoading = queries.some((query) => query.isLoading)
    const isError = queries.some((query) => query.isError)

    return { facilities, isLoading, isError }
  }, [settledQueriesCounter.current])

  return result
}
