import { useFulfillmentFormContext } from 'fulfillments/create/CreateFulfillmentContext'

import { FulfillmentSelect } from './FulfillmentSelect'

export function FulfillmentOrders({
  required,
}: {
  readonly required?: boolean
}) {
  const { ordersOptions, isLoadingOrders } = useFulfillmentFormContext()

  return (
    <FulfillmentSelect
      name="order_uuid"
      label="Order"
      required={required}
      placeholder="Select order"
      loading={isLoadingOrders}
      options={ordersOptions}
    />
  )
}
