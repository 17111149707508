import type { Cell, IdType } from 'react-table'

import { ACTION_TYPES } from './Table.types'

export function stateReducer(currentState: any, action: { type: string }) {
  if (action.type === ACTION_TYPES.clearSelection) {
    return { ...currentState, selectedRowIds: {}, areAllItemsSelected: false }
  }

  if (action.type === ACTION_TYPES.selectAllItems) {
    return { ...currentState, areAllItemsSelected: true }
  }

  return currentState
}

export type TableSelectedRowsIDs = string[] | 'all'

export function convertToSelectedRowIds(
  rowIds?: TableSelectedRowsIDs
): Record<IdType<string>, boolean> {
  if (!rowIds || rowIds === 'all') {
    return {}
  }

  return rowIds.reduce((previousValue, currentValue) => {
    return {
      ...previousValue,
      [currentValue]: true,
    }
  }, {})
}

export function getCellProps<T extends object>(cell: Cell<T, any>) {
  if (cell.column.id === 'selection') {
    return {
      onClick: (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
      },
    }
  }
  return null
}

export function getSelectionCount(
  totalCount: number,
  selectedRows: TableSelectedRowsIDs
) {
  return selectedRows === 'all' ? totalCount : selectedRows.length
}
