import { Select, Tag } from '@loadsmart/loadsmart-ui'
import type { SelectOption } from '@loadsmart/loadsmart-ui/dist/components/Select'
import type { GenericOption } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'
import { Field, Layout, TextField } from '@loadsmart/miranda-react'
import { isEmpty } from 'lodash'

import type {
  HandlingUnitOrder,
  HandlingUnitOrderItem,
} from 'components/CommodityDetails/Commodity'
import { useSettings } from 'contexts/settings'
import type { ListOrder } from 'orders/types'

import type { CustomFormFieldProps } from '../HandlingUnits.types'
import { createTransientHandlingUnitOrderItem } from '../HandlingUnitsUtils'
import { useHandlingUnitFormField } from './HandlingUnitsForm.hooks'

export const HandlingUnitOrders = (props: CustomFormFieldProps) => {
  const { hint, style } = props
  const { controlProps, fieldProps, hintProps, labelProps, value } =
    useHandlingUnitFormField({ name: 'orders', hint })

  if (isEmpty(value)) {
    return null
  }

  const fieldValue = value
    .map((order: HandlingUnitOrder) => order.external_id)
    .join(', ')

  return (
    <Field {...fieldProps} style={style}>
      <Field.Label {...labelProps}>Orders</Field.Label>
      <TextField {...controlProps} value={fieldValue} disabled />
      <Field.Hint {...hintProps} />
    </Field>
  )
}

type HandlingUnitOrderItemSelectOption = HandlingUnitOrderItem & GenericOption

export const HandlingUnitOrderItems = (
  props: CustomFormFieldProps & { readonly selectableOrders: ListOrder[] }
) => {
  const {
    values: [enableShippedCount],
  } = useSettings(['flags.ENABLE_ORDER_SHIPPED_COUNT'])

  const { required, hint, style } = props

  const {
    controlProps,
    fieldProps,
    hintProps,
    index,
    labelProps,
    name,
    value,
    dispatch,
  } = useHandlingUnitFormField({ name: 'order_items', hint })

  const options: HandlingUnitOrderItemSelectOption[] =
    props.selectableOrders.flatMap((order) => {
      return order.items.map((orderItem) => ({
        value: orderItem.uuid,
        label: `Order ${order.primary_ref} - Line item #${orderItem.line_number}`,
        ...createTransientHandlingUnitOrderItem({
          uuid: orderItem.uuid,
          line_number: orderItem.line_number,
          item_description: orderItem.item_description,
          item_number: orderItem.item_number,
          shipped_package_count: enableShippedCount
            ? orderItem.package_count
            : undefined,
          order: {
            uuid: order.uuid,
            primary_ref: order.primary_ref,
            external_id: order.external_id,
            po_number: order.po_number,
            so_number: order.so_number,
          },
        }),
      }))
    })

  return (
    <Field
      {...fieldProps}
      required={required}
      style={style}
      data-testid="shipping-item-orders"
    >
      <Field.Label {...labelProps}>Order Items in this HU</Field.Label>
      <Select
        getInputProps={() => controlProps}
        disabled={Boolean(controlProps.disabled)}
        name={name}
        placeholder="Select items for this HU"
        options={options}
        multiple
        value={value}
        onChange={(e) => {
          dispatch({
            type: 'SET_ITEM',
            payload: {
              index,
              changes: {
                [name]: e.target.value as SelectOption[],
              },
            },
          })
        }}
      />
      <Field.Hint {...hintProps} />

      <Layout.Group data-testid="selected-orders-filters" paddingT="spacing-1">
        {value?.map((order: any) => (
          <Tag
            key={order.value}
            variant="default"
            removable
            onRemove={() => {
              const newValue = value.filter(
                (currentItem: GenericOption) =>
                  currentItem.value !== order.value
              )
              dispatch({
                type: 'SET_ITEM',
                payload: {
                  index,
                  changes: {
                    [name]: newValue,
                  },
                },
              })
            }}
          >
            {order.label}
          </Tag>
        ))}
      </Layout.Group>
    </Field>
  )
}
