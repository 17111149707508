import { Field, TextField } from '@loadsmart/miranda-react'

import type { CustomFormFieldProps } from '../HandlingUnits.types'
import { useCommodityFormField } from './CommoditiesForm.hooks'

export const CommodityNMFC = (props: CustomFormFieldProps) => {
  const { disabled, required, hint, style } = props
  const { controlProps, fieldProps, hintProps, labelProps, value, setValue } =
    useCommodityFormField({ name: 'nmfc_code', hint })

  return (
    <Field {...fieldProps} required={required} style={style}>
      <Field.Label {...labelProps}>NMFC</Field.Label>
      <TextField
        {...controlProps}
        disabled={disabled}
        placeholder="Enter NMFC"
        value={value || ''}
        onChange={(event) => {
          const {
            target: { value: newValue },
          } = event

          setValue(newValue)
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
