import type { AdaptedShipment } from 'screens/Shipper/Shipments/Details/types'
import type { NewShipmentPayload } from 'shipments/shipments.services'
import type { AttributeMapper } from 'utils/AdapterFactory'
import AdapterFactory from 'utils/AdapterFactory'
import { getEquipmentType } from 'utils/equipmentTypeV2'
import { getTransportationMode } from 'utils/transportationMode'

import { EXCESSIVE_LENGTH_CLASS_OPTIONS_MAP } from './FreightInformation.constants'
import type { FreightInformation } from './FreightInformation.types'

export function getExcessiveLengthOption(excessiveLength: string | null) {
  if (
    !excessiveLength ||
    !EXCESSIVE_LENGTH_CLASS_OPTIONS_MAP.has(excessiveLength)
  ) {
    return null
  }

  return EXCESSIVE_LENGTH_CLASS_OPTIONS_MAP.get(excessiveLength)
}

export const PO_NUMBER_MAPPER: AttributeMapper = {
  from: 'po_number',
  to: 'po_numbers',
  transform(po_number: FreightInformation['po_number']) {
    return po_number ? po_number.split(',').filter(Boolean) : []
  },
}

export const BOL_NUMBER_MAPPER: AttributeMapper = {
  from: 'bol_number',
  to: 'bol_number',
  transform(bol_number: FreightInformation['bol_number']) {
    return bol_number || null
  },
}

export const SO_NUMBER_MAPPER: AttributeMapper = {
  from: 'so_number',
  to: 'so_numbers',
  transform(so_number: FreightInformation['so_number']) {
    return so_number ? so_number.split(',').filter(Boolean) : []
  },
}

export const SHIPPER_REF_NUMBER_MAPPER: AttributeMapper = {
  from: 'shipper_ref_number',
  to: 'shipper_ref_number',
  transform(shipper_ref_number: FreightInformation['shipper_ref_number']) {
    return shipper_ref_number ?? undefined
  },
}

export const MODE_MAPPER: AttributeMapper = {
  from: 'mode',
  to: 'mode',
  transform(mode: FreightInformation['mode']) {
    return mode ? getTransportationMode(mode)?.abbr : ''
  },
}

export const EQUIPMENT_TYPE_MAPPER: AttributeMapper = {
  from: 'equipment_type',
  to: 'equipment_type',
  transform(equipment_type: FreightInformation['equipment_type']) {
    return equipment_type ? getEquipmentType(equipment_type)?.abbr : ''
  },
}

export const EQUIPMENT_LENGTH_MAPPER: AttributeMapper = {
  from: 'equipment_length',
  to: 'equipment_length',
  transform(equipment_length: FreightInformation['equipment_length']) {
    return equipment_length || null
  },
}

export const FREIGHT_INFORMATION_TO_PAYLOAD_MAPPER: AttributeMapper[] = [
  PO_NUMBER_MAPPER,
  BOL_NUMBER_MAPPER,
  SO_NUMBER_MAPPER,
  MODE_MAPPER,
  EQUIPMENT_TYPE_MAPPER,
  EQUIPMENT_LENGTH_MAPPER,
]

export const adaptFreightInformationToNewShipmentPayload = AdapterFactory<
  Pick<
    NewShipmentPayload,
    | 'po_numbers'
    | 'bol_number'
    | 'so_numbers'
    | 'mode'
    | 'equipment_type'
    | 'equipment_length'
  >,
  FreightInformation
>(FREIGHT_INFORMATION_TO_PAYLOAD_MAPPER)

export function freightInformationFromAdaptedShipment(
  adaptedShipment: AdaptedShipment
): FreightInformation {
  const freightInformation: FreightInformation = {
    bol_number: adaptedShipment.bol_number ?? undefined,
    carrier_ref_numbers: adaptedShipment.carrier_ref_numbers,
    // Remove single quotes from equipment length to match length map values
    equipment_length: adaptedShipment.equipment_length?.replace(/'/g, ''),
    equipment_requirements: adaptedShipment.equipment_requirements ?? undefined,
    equipment_subtypes: adaptedShipment.equipment_subtypes,
    equipment_type: adaptedShipment.equipment_type,
    hazmat: adaptedShipment.hazmat,
    mode: adaptedShipment.mode,
    po_number: adaptedShipment.po_numbers
      ? adaptedShipment.po_numbers.join(',')
      : (adaptedShipment.po_number ?? undefined),
    po_numbers: adaptedShipment.po_numbers ?? undefined,
    power_only: adaptedShipment.power_only,
    shipper_ref_number: adaptedShipment.shipper_ref_number,
    so_number: adaptedShipment.so_numbers
      ? adaptedShipment.so_numbers.join(',')
      : (adaptedShipment.so_number ?? undefined),
    so_numbers: adaptedShipment.so_numbers ?? undefined,
    tarp_size: adaptedShipment.tarp_size,
    tarp_type: adaptedShipment.tarp_type,
    with_tarp: adaptedShipment.with_tarp,
  }

  return freightInformation
}
