import { useCallback } from 'react'
import { useQueryClient } from 'react-query'
import type { QueryClient } from 'react-query'

import { triggerEvent } from '_shared_/custom-events/events'
import type { FacilityContactV2 } from 'services/facilities'

function refetchFacilityDetails(
  facilityUUID: string,
  queryClient: QueryClient
) {
  queryClient.refetchQueries({
    queryKey: ['getFacilityDetails', facilityUUID],
  })
  queryClient.refetchQueries({
    queryKey: ['getFacilityDetailsV2', facilityUUID],
  })
}

type OpenContactsManagerOptions = {
  facilityUUID: string
  onContactSaved?: (contact: FacilityContactV2) => void
  initialView?: 'details' | 'edit-form'
}

/**
 * use this hook with the EventBasedContactsManagerDrawer.
 */
export function useContactsDrawer(props?: {
  onContactSaved?: (contact: FacilityContactV2) => void
}) {
  const { onContactSaved } = props ?? {}
  const queryClient = useQueryClient()

  const openContactsManagerDrawer = useCallback(
    (options: OpenContactsManagerOptions) => {
      const { facilityUUID, onContactSaved: scopedOnContactSaved } = options

      if (!facilityUUID) {
        return
      }

      const handleContactSaved = scopedOnContactSaved ?? onContactSaved

      triggerEvent('sg:open-contacts-manager', {
        facilityUUID,
        onContactsSaved: (contacts) => {
          if (handleContactSaved) {
            contacts.forEach((contact) => handleContactSaved(contact))
          }

          refetchFacilityDetails(facilityUUID, queryClient)
        },
        initialView: options.initialView,
      })
    },
    [onContactSaved, queryClient]
  )

  /**
   * Opens the `ContactsManagerDrawer` in the edit-form view.
   * The `onContactSaved` callback provided here override the `onContactSaved` callback provided to the `useFacilityDrawer` hook (if any).
   */
  const openEditContactsForm = useCallback(
    (options: Omit<OpenContactsManagerOptions, 'initialView'>) => {
      openContactsManagerDrawer({ initialView: 'edit-form', ...options })
    },
    [openContactsManagerDrawer]
  )

  /**
   * Opens the `ContactsManagerDrawer` in the details view.
   * The `onContactSaved` callback provided here override the `onContactSaved` callback provided to the `useFacilityDrawer` hook (if any).
   */
  const openContactsDetails = useCallback(
    (options: Omit<OpenContactsManagerOptions, 'initialView'>) => {
      openContactsManagerDrawer({ initialView: 'details', ...options })
    },
    [openContactsManagerDrawer]
  )

  return {
    openContactsDetails,
    openEditContactsForm,
  }
}
