import { useQuery, useQueryClient } from 'react-query'
import type { QueryClient, QueryObserverOptions } from 'react-query'
import type { Updater } from 'react-query/types/core/utils'
import { useParams } from 'react-router-dom'

import { SHIPMENT_DETAILS_QUERY_KEY } from '../constants'
import { getShipmentDetails } from '../services/shipment'
import type { AdaptedShipment } from '../types'

export const useShipmentDetails = (
  options?: QueryObserverOptions<AdaptedShipment>,
  uuid?: string
) => {
  const { id } = useParams<{ id: string }>()
  const shipmentUuid = uuid ?? id

  return useQuery<AdaptedShipment>({
    ...options,
    queryKey: [SHIPMENT_DETAILS_QUERY_KEY, shipmentUuid],
    queryFn: ({ signal }) => getShipmentDetails(shipmentUuid, { signal }),
    staleTime: Infinity,
  })
}

type ShipmentUpdater = Updater<
  AdaptedShipment | undefined,
  AdaptedShipment | undefined
>

export const useShipmentDetailsQueryDataUpdater = () => {
  const queryClient = useQueryClient()
  const updateData = (uuid: string, updater: ShipmentUpdater) => {
    queryClient.setQueriesData<AdaptedShipment | undefined>(
      { queryKey: [SHIPMENT_DETAILS_QUERY_KEY, uuid] },
      updater
    )
  }

  return { updateData }
}

export const refetchShipmentDetails = (
  shipmentUuid: string,
  queryClient: QueryClient
) => {
  const MAGAZZINO_TIMEOUT = 1000

  // To ensure that the most up-to-date information is displayed, we are waiting for a brief period and then refreshing the shipment details.
  // This is because there might be a delay in updating the data due to the asynchronous connection between the backend and Magazzino.
  // Thus, any changes made to the shipment details related to Magazzino might not be immediately available.
  setTimeout(() => {
    queryClient.refetchQueries(
      {
        queryKey: [SHIPMENT_DETAILS_QUERY_KEY, shipmentUuid],
      },
      {
        throwOnError: true,
      }
    )
  }, MAGAZZINO_TIMEOUT)
}
