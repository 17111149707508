import { Card, Layout, Tag } from '@loadsmart/miranda-react'

import { plural } from 'utils/strings'

type FormSectionProps = React.PropsWithChildren<{
  readonly id: string
  readonly title: string
  readonly errors: number
}>

export function FormSection({ id, title, errors, children }: FormSectionProps) {
  return (
    <Card id={id}>
      <Card.Title>
        <Layout.Group align="center" padding="none" gap="spacing-1">
          {title}
          {errors > 0 && (
            <Tag variant="danger" size="small">
              {errors} {plural('Error', errors)}
            </Tag>
          )}
        </Layout.Group>
      </Card.Title>
      <Card.Divider />
      <Card.Body>{children}</Card.Body>
    </Card>
  )
}
