import type { ListFulfillment } from 'fulfillments/domain/Fulfillment'
import { useGoToFulfillmentDetails } from 'fulfillments/hooks/navigation'
import { useFulfillmentListAsCustomer } from 'fulfillments/hooks/useFulfillmentList'
import { defaultTo } from 'lodash'
import { useCallback } from 'react'

import { Table } from 'components/TableV4'
import type { CustomColumn } from 'components/TableV4/Table.types'

import {
  ActionsCell,
  DestinationCell,
  HandlingUnitsCell,
  NotesCell,
  OrdersCell,
  OriginCell,
  StatusCell,
  SupplierCell,
} from './components/ListFulfillmentsTableCells'
import { ListFulfillmentsTableEmptyState } from './components/ListFulfillmentsTableEmptyState'

const CUSTOMER_COLUMNS: ReadonlyArray<CustomColumn<ListFulfillment>> = [
  {
    id: 'ref_number',
    accessor: 'ref_number',
    Header: 'Ref number',
    customWidth: '200px',
  },
  {
    id: 'pickup_number',
    accessor: 'pickup_number',
    Header: 'Pickup number',
    customWidth: '200px',
  },
  {
    Header: 'Status',
    id: 'status',
    Cell: StatusCell,
  },
  {
    Header: 'Orders',
    id: 'order_uuids',
    Cell: OrdersCell,
  },
  {
    Header: 'Origin',
    id: 'pickup_facility',
    Cell: OriginCell,
  },
  {
    Header: 'Destination',
    id: 'delivery_facility',
    Cell: DestinationCell,
  },
  {
    Header: 'Handling units',
    id: 'handling_units',
    Cell: HandlingUnitsCell,
  },
  {
    Header: 'Supplier',
    id: 'supplier',
    Cell: SupplierCell,
  },
  {
    Header: 'Notes',
    id: 'notes',
    Cell: NotesCell,
  },
  {
    Header: '',
    id: 'row_actions',
    Cell: ActionsCell,
  },
]

type FulfillmentsTableProps = ReturnType<
  typeof useFulfillmentListAsCustomer
> & {
  columns: ReadonlyArray<CustomColumn<ListFulfillment>>
}

function FulfillmentsTable({
  columns,
  currentPage,
  data,
  isLoading,
  onPageChange,
  pageSize,
}: Readonly<FulfillmentsTableProps>) {
  const goToFulfillmentDetails = useGoToFulfillmentDetails()
  const onRowClick = useCallback(
    (fulfillment: ListFulfillment) => {
      goToFulfillmentDetails(fulfillment.uuid)
    },
    [goToFulfillmentDetails]
  )

  if (!isLoading && !data?.results?.length) {
    return (
      <ListFulfillmentsTableEmptyState
        count={data?.count}
        isLoading={isLoading}
      />
    )
  }

  return (
    <Table<ListFulfillment>
      columns={columns}
      count={data?.results?.length}
      data={defaultTo(data?.results, [])}
      isLoading={!!isLoading}
      onPageChange={onPageChange}
      onRowClick={onRowClick}
      page={currentPage}
      pageSize={pageSize}
      totalCount={data?.count}
      uniqueIDfield="uuid"
    />
  )
}

export function ListFulfillmentsAsCustomer() {
  const asCustomerList = useFulfillmentListAsCustomer()

  return <FulfillmentsTable {...asCustomerList} columns={CUSTOMER_COLUMNS} />
}
