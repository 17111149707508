import { Layout, Text } from '@loadsmart/miranda-react'
import { defaultTo, every, isEmpty } from 'lodash'

import {
  formatFacilityLocation,
  getCountryMetadataByAbbreviation,
} from 'components/FacilityDetails'
import { FacilityCountry } from 'components/FacilityDetails/FacilityCountry'
import type { ListOrder, Order } from 'orders/types'

export function OrderFacilityAddress({
  order,
  stop,
}: Readonly<{
  order: Order | ListOrder
  stop: 'pickup' | 'delivery'
}>) {
  const country = defaultTo(order[`${stop}_country`], '')
  const addressLineOne = defaultTo(order[`${stop}_address`], '')
  const addressLineTwo = formatFacilityLocation({
    city: order[`${stop}_city`],
    state: order[`${stop}_state`],
    zipcode: order[`${stop}_zipcode`],
  })

  if (every([country, addressLineOne, addressLineTwo], isEmpty)) {
    return '-'
  }

  return (
    <Layout.Stack gap="spacing-1">
      <Text>{addressLineOne}</Text>
      <Layout.Group align="center" gap="spacing-1">
        {addressLineTwo}{' '}
        {country && (
          <>
            <FacilityCountry country={country} labelPosition="tooltip" />
            {getCountryMetadataByAbbreviation(order.pickup_country)?.name}
          </>
        )}
      </Layout.Group>
    </Layout.Stack>
  )
}
