import type { Location } from '@loadsmart/react-location-select'

import type { AddressV2 } from 'components/Form'

import { addIfNotNull } from './arrays'
import { joinValidStrings } from './strings'

export function formatAddress(
  city: string | undefined,
  state: string | undefined,
  zipcode: string | undefined | null,
  country?: string
) {
  const zipCodeSpace = zipcode ? ' ' : ''
  const cityStateText = city && state ? `${city}, ${state}${zipCodeSpace}` : ''
  const zipCodeText = zipcode || ''
  const countryText = country ? `, ${country}` : ''
  return `${cityStateText}${zipCodeText}${countryText}`
}

export function formatLocationSelectAddress(value?: Address | null) {
  if (!value) {
    return ''
  }

  if (value.company_name) {
    return `${value.company_name}: ${value.address}`
  }

  if (value.address) {
    return value.address
  }

  if (value.zipcode) {
    const address = addIfNotNull(
      value.city,
      value.state,
      value.country,
      value.zipcode
    )([])

    return address.join(', ')
  }

  if (value.city) {
    return addIfNotNull(value.city, value.state, value.country)([]).join(', ')
  }

  return ''
}

export function formatLocationSelectAddressV2(value?: AddressV2 | null) {
  if (!value) {
    return ''
  }

  if (value.facility) {
    return joinValidStrings(
      [
        value.facility.name,
        joinValidStrings(
          [value.facility.address, value.facility.address_details],
          ', '
        ),
      ],
      ': '
    )
  }

  if (value.address) {
    return value.address
  }

  return joinValidStrings(
    [value.city, joinValidStrings([value.state, value.zipcode]), value.country],
    ', '
  )
}

export function formatFacilitySelectOption(value?: Address | null) {
  if (!value) {
    return ''
  }

  if (value.company_name) {
    return value.company_name
  }

  if (value.address) {
    return value.address
  }

  return ''
}

export function validateAddressBeta(location?: Location): boolean {
  return Boolean(location?.streetNumber)
}

export const getAdditionalStops = (stops: LaneStop[]) => {
  return stops.slice(1, -1).map((stop) => {
    return {
      city: stop.city,
      state: stop.state,
      zipcode: stop.zip_code,
      country: stop.country,
    }
  })
}

/**
 * Should be removed after the fully migration to LaneStop model,
 * changing the values in the LaneAddress component itself
 */
export const formatStop = (stop: LaneStop) => {
  return {
    ...stop,
    address: stop?.address || stop?.facility_address,
    company_name: stop?.facility_name,
    zipcode: stop?.zip_code,
  }
}
