import { isEmpty } from 'lodash'
import type { PropsWithChildren } from 'react'
import { createContext, useContext, useEffect, useMemo } from 'react'

import { useCurrentShipperUUID } from '_shared_/user/useCurrentShipperUUID'
import { useOrderDetails } from 'orders/hooks/useOrderDetails'
import { useTruckMileage } from 'orders/hooks/useTruckMileage'
import type { TruckMileageResponse } from 'orders/order-service'
import { mapOrderStopsToTruckMileageStops } from 'orders/orders.utils'
import type { Order } from 'orders/types'

export interface ViewOrderContextValue {
  isErrorLoadingOrder?: boolean
  isLoadingOrder?: boolean
  isLoadingMileage?: boolean
  isOrderSupplier?: boolean
  mileage?: TruckMileageResponse
  order?: Order
}

const ViewOrderContext = createContext<ViewOrderContextValue>({})

export function useViewOrderContextData(orderUUID: string) {
  const currentShipperUUID = useCurrentShipperUUID()
  const {
    data: order,
    isError: isErrorLoadingOrder,
    isLoading: isLoadingOrder,
  } = useOrderDetails(orderUUID)

  const isOrderSupplier = useMemo(() => {
    return (
      !isEmpty(order?.supplier?.uuid) &&
      order?.supplier?.uuid === currentShipperUUID
    )
  }, [order?.supplier?.uuid, currentShipperUUID])

  // Mileage
  const stops = useMemo(() => mapOrderStopsToTruckMileageStops(order), [order])
  const {
    data: mileage,
    isLoading: isLoadingMileage,
    refetch: getTruckMileage,
  } = useTruckMileage(stops)

  useEffect(() => {
    if (stops.length) {
      getTruckMileage()
    }
  }, [stops, getTruckMileage])

  return {
    mileage,
    order,
    isErrorLoadingOrder,
    isLoadingOrder,
    isLoadingMileage,
    isOrderSupplier,
  }
}

export function useViewOrderContext() {
  const context = useContext(ViewOrderContext)

  if (context === undefined) {
    throw new Error(
      'useViewOrderContext must be used within a ViewOrderContextProvider'
    )
  }

  return context
}

export function ViewOrderContextProvider({
  children,
  ...data
}: PropsWithChildren<Readonly<ViewOrderContextValue>>) {
  return (
    <ViewOrderContext.Provider value={data}>
      {children}
    </ViewOrderContext.Provider>
  )
}
