import type { AxiosRequestConfig } from 'axios'

import httpClient from 'utils/httpClient'

export const getLaneManagement = async (
  status: string,
  limit: string | number,
  offset: number | string,
  query: string,
  config?: Omit<AxiosRequestConfig, 'params'>
) => {
  const { data } = await httpClient.get(
    `/lane_management?status=${status}&limit=${limit}&offset=${offset}&query=${query}`,
    config
  )
  return data
}

export const fetchLastRFPs = async (
  groupLaneIds: number[],
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.post(
    `/lane_management/lane_group/rfps`,
    {
      group_lane_ids: groupLaneIds,
    },
    config
  )
  return data?.results || []
}

export const fetchLaneManagementAwardSummary = async (
  groupLaneIds: number[],
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.post(
    `/lane_management/award_summary`,
    {
      group_lane_ids: groupLaneIds,
    },
    config
  )
  return data || []
}

export const fetchLaneManagementCarrierAwards = async (
  groupLaneIds: number[],
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.post(
    `/lane_management/carrier_awards`,
    {
      group_lane_ids: groupLaneIds,
    },
    config
  )
  return data || []
}

export const fetchLaneManagementProposals = async (
  groupLaneIds: number[],
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.post(
    `/lane_management/proposals`,
    {
      group_lane_ids: groupLaneIds,
    },
    config
  )
  return data || []
}

export const fetchLaneManagementLaneStats = async (
  groupLaneIds: number[],
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.post(
    `/lane_management/lane_stats`,
    {
      group_lane_ids: groupLaneIds,
    },
    config
  )
  return data || {}
}

export const exportLaneManagementReport = async (
  {
    start_period_report,
    end_period_report,
    format,
  }: {
    start_period_report: string
    end_period_report: string
    format: string
  },
  config?: Omit<AxiosRequestConfig, 'params' | 'responseType'>
) => {
  const { data, headers } = await httpClient.get(
    `/proposals/rfp_export/lane_management_report?output_format=${format}&start_date=${start_period_report}&end_date=${end_period_report}`,
    {
      ...config,
      responseType: 'arraybuffer',
    }
  )
  return { data, type: headers['content-type'] }
}
