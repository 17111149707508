import { Layout, Tag, Text } from '@loadsmart/miranda-react'
import type { ReactNode } from 'react'

import { Column, GridV2 as Grid } from 'components/Grid'
import { HandlingUnitsDimensionsSummary } from 'components/HandlingUnitsManager/ContextBasedHandlingUnitDimensionsSummary'
import type { TransientHandlingUnit } from 'components/HandlingUnitsManager/HandlingUnits.types'

type HeaderProps = {
  readonly handlingUnits: TransientHandlingUnit[]
  readonly actions?: ReactNode
  readonly hasOneItemRequiredError?: boolean
}

const wrapperStyle = {
  borderInline: 'none',
  borderBlockEnd: 'none',
} as const

const titleStyle = { textAlign: 'left' } as const

export const HandlingUnitsFormHeader = ({
  handlingUnits,
  hasOneItemRequiredError,
  actions,
}: HeaderProps) => {
  return (
    <Layout.Box
      borderRadius="border-radius-none"
      borderColor="color-divider"
      borderWidth="border-thin"
      backgroundColor="color-background-primary"
      padding="spacing-6"
      style={wrapperStyle}
    >
      <Grid columns={3} align="center">
        <Column columns={2}>
          <Layout.Stack gap="spacing-2">
            <Layout.Group align="center">
              <Text
                variant="heading-sm-bold"
                color="color-text-secondary"
                style={titleStyle}
              >
                Shipping items
              </Text>
              {hasOneItemRequiredError && (
                <Tag size="small" variant="danger">
                  Requires at least one item
                </Tag>
              )}
            </Layout.Group>
            <HandlingUnitsDimensionsSummary handlingUnits={handlingUnits} />
          </Layout.Stack>
        </Column>
        {actions}
      </Grid>
    </Layout.Box>
  )
}
