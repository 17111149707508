import { Select } from '@loadsmart/loadsmart-ui'
import { Field } from '@loadsmart/miranda-react'

import {
  PACKAGE_TYPES_OPTIONS,
  getPackageTypeOption,
  hasStandardPalletDimmensions,
} from 'components/ShippingItemsManager'
import {
  STANDARD_PALLETS_LENGTH,
  STANDARD_PALLETS_WIDTH,
} from 'utils/constants'
import { fromSelectOption } from 'utils/selectOption'

import type { CustomFormFieldProps } from '../HandlingUnits.types'
import { useHandlingUnitFormField } from './HandlingUnitsForm.hooks'

export const HandlingUnitPackageType = (props: CustomFormFieldProps) => {
  const { required, hint, style } = props
  const {
    controlProps,
    fieldProps,
    hintProps,
    index,
    item,
    labelProps,
    name,
    setItem,
    value,
    setValue,
  } = useHandlingUnitFormField({ name: 'package_type', hint })

  return (
    <Field {...fieldProps} required={required} style={style}>
      <Field.Label {...labelProps}>Handling unit</Field.Label>
      <Select
        getInputProps={() => controlProps}
        disabled={Boolean(controlProps.disabled)}
        placeholder="Select"
        hideClear={required}
        id={`field-${index}-${name}`}
        name={name}
        options={PACKAGE_TYPES_OPTIONS}
        value={getPackageTypeOption(value)}
        onChange={(event) => {
          const {
            target: { value: option },
          } = event
          const selectedValue = fromSelectOption(option as SelectOption)
          setValue(selectedValue)

          if (selectedValue === 'std_pallets') {
            // Set standard pallets dimensions
            setItem({
              [name]: selectedValue,
              length: String(STANDARD_PALLETS_LENGTH),
              width: String(STANDARD_PALLETS_WIDTH),
            })

            return
          }

          if (value === 'std_pallets' && hasStandardPalletDimmensions(item)) {
            // Clear standard pallets dimensions to allow user to input custom dimensions
            setItem({
              [name]: selectedValue,
              length: '',
              width: '',
            })

            return
          }

          setValue(selectedValue)
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
