import { useState, useCallback, useMemo } from 'react'

export function useModal(isOpen = false) {
  const [modalState, setModalState] = useState<boolean>(isOpen)
  const closeModal = useCallback(() => {
    setModalState(false)
  }, [])
  const openModal = useCallback(() => {
    setModalState(true)
  }, [])
  const toggleModal = useCallback(() => {
    setModalState(!modalState)
  }, [modalState])

  return useMemo(
    () => ({ modalState, closeModal, openModal, toggleModal, setModalState }),
    [modalState, closeModal, openModal, toggleModal, setModalState]
  )
}
