import {
  Card,
  Divider,
  LabeledValue,
  Layout,
  Text,
} from '@loadsmart/miranda-react'

import { OrderFacilityAddress } from 'orders/components/OrderFacilityAddress'
import { formatOrderDates, formatOrderStop } from 'orders/orders.utils'
import { defaultEmptyString } from 'utils/strings'

import { useViewOrderContext } from '../ViewOrderContext'
import { OrderLaneMap } from './OrderLaneMap'

export function OrderLaneCard() {
  const { order } = useViewOrderContext()

  if (!order) {
    return null
  }

  return (
    <Card data-testid="order-lane-card">
      <Card.Title>Lane</Card.Title>
      <Card.Divider />
      <Card.Body>
        <Layout.Stack gap="spacing-6">
          <OrderLaneMap order={order} />

          <Layout.Stack gap="spacing-2">
            <Text variant="heading-sm-bold">
              Pickup at {formatOrderStop(order, 'pickup')}
            </Text>
            <Text variant="body-md-bold">
              {formatOrderDates(order, 'pickup')}
            </Text>
          </Layout.Stack>

          <Layout.Grid minColumnWidth="400px">
            <LabeledValue
              data-testid="order-pickup-facility-name"
              label="Facility name"
              value={defaultEmptyString(order.pickup_facility_name)}
            />
            <LabeledValue
              data-testid="order-pickup-facility-address"
              label="Address"
              value={<OrderFacilityAddress order={order} stop="pickup" />}
            />
          </Layout.Grid>

          <Divider />

          <Layout.Stack gap="spacing-2">
            <Text variant="heading-sm-bold">
              Delivery at {formatOrderStop(order, 'delivery')}
            </Text>
            <Text variant="body-md-bold">
              {formatOrderDates(order, 'delivery')}
            </Text>
          </Layout.Stack>

          <Layout.Grid minColumnWidth="400px">
            <LabeledValue
              data-testid="order-pickup-delivery-facility-name"
              label="Facility name"
              value={defaultEmptyString(order.delivery_facility_name)}
            />
            <LabeledValue
              data-testid="order-delivery-facility-address"
              label="Facility"
              value={<OrderFacilityAddress order={order} stop="delivery" />}
            />
          </Layout.Grid>
        </Layout.Stack>
      </Card.Body>
    </Card>
  )
}
