import { Field, TextField } from '@loadsmart/miranda-react'

import type { CustomFormFieldProps } from '../HandlingUnits.types'
import { useHandlingUnitFormField } from './HandlingUnitsForm.hooks'

export const HandlingUnitPackageCount = (props: CustomFormFieldProps) => {
  const { required, hint, style } = props
  const { controlProps, fieldProps, hintProps, labelProps, value, setValue } =
    useHandlingUnitFormField({ name: 'package_count', hint })

  return (
    <Field {...fieldProps} required={required} style={style}>
      <Field.Label {...labelProps}>H/U count</Field.Label>
      <TextField
        {...controlProps}
        placeholder="e.g. 2"
        type="number"
        min="1"
        inputMode="numeric"
        value={value || ''}
        onChange={(event) => {
          const {
            target: { value: newValue },
          } = event

          setValue(Number(newValue))
        }}
        style={{ width: '75px' }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
