import { Layout, SpinnerWheel } from '@loadsmart/miranda-react'

import GeneralErrorState from 'components/GeneralErrorState'

import { OrderFulfillmentsCard } from './components/OrderFulfillmentsCard'
import { OrderItemsCard } from './components/OrderItemsCard'
import { OrderLaneCard } from './components/OrderLaneCard'
import { OrderOverviewCard } from './components/OrderOverviewCard'
import { OrderShipmentsCard } from './components/OrderShipmentsCard'
import { useViewOrderContext } from './ViewOrderContext'

export function ViewOrderDetails() {
  const { isErrorLoadingOrder, isLoadingOrder, order } = useViewOrderContext()

  if (isLoadingOrder) {
    return (
      <Layout.Group align="center" justify="center" style={{ height: '100%' }}>
        <SpinnerWheel size="48px" aria-label="Loading" />
      </Layout.Group>
    )
  }

  if (isErrorLoadingOrder || (!isLoadingOrder && !order)) {
    return <GeneralErrorState />
  }

  return (
    <Layout.Stack>
      <Layout.Sidebar gap="spacing-4" sideWidth="390px">
        <Layout.Sidebar.Side padding="none">
          <Layout.Stack>
            <OrderOverviewCard />
            <OrderItemsCard />
          </Layout.Stack>
        </Layout.Sidebar.Side>

        <Layout.Sidebar.Content padding="none">
          <Layout.Stack>
            <OrderLaneCard />
            <OrderShipmentsCard />
            <OrderFulfillmentsCard />
          </Layout.Stack>
        </Layout.Sidebar.Content>
      </Layout.Sidebar>
    </Layout.Stack>
  )
}
