import type { AxiosRequestConfig } from 'axios'

import httpClient from 'utils/httpClient'

export const get = async (
  config?: AxiosRequestConfig
): Promise<{
  flags: Record<string, boolean>
  settings: Record<string, string | number>
}> => {
  const {
    data = {
      flags: {},
      settings: {},
    },
  } = await httpClient.get<{
    flags: Record<string, boolean>
    settings: Record<string, string | number>
  }>('/frontend_settings', config)
  return data
}
