import { useIsCurrentShipperASupplier } from 'suppliers/common/useIsCurrentShipperASupplier'

import { ListFulfillmentsAsCustomer } from './ListFulfillmentsAsCustomer'
import { ListFulfillmentsAsSupplier } from './ListFulfillmentsAsSupplier'

export function ListFulfillments() {
  const isSupplier = useIsCurrentShipperASupplier()

  if (isSupplier) {
    return <ListFulfillmentsAsSupplier />
  }

  return <ListFulfillmentsAsCustomer />
}
