import { defaultTo, isEmpty } from 'lodash'

import { generateUseSearchParamsPlugin } from 'utils/filters'

export type GetOrdersPagination = {
  limit?: number
  offset?: number
  search?: string | null
}

export const DEFAULT_LIMIT = 50
export const DEFAULT_OFFSET = 0

export function getDefaultOrdersPaginationParams(
  overrides?: Partial<GetOrdersPagination>
): GetOrdersPagination {
  return {
    limit: defaultTo(overrides?.limit, DEFAULT_LIMIT),
    offset: defaultTo(overrides?.offset, DEFAULT_OFFSET),
    search: defaultTo(overrides?.search, ''),
  }
}

export function mapLimitParam(pagination?: GetOrdersPagination) {
  return { limit: Number(defaultTo(pagination?.limit, DEFAULT_LIMIT)) }
}

export function mapOffsetParam(pagination?: GetOrdersPagination) {
  return { offset: Number(defaultTo(pagination?.offset, DEFAULT_OFFSET)) }
}

export function mapSearchParam(pagination?: GetOrdersPagination) {
  if (!pagination || isEmpty(pagination?.search)) {
    return { search_term: null }
  }

  return { search_term: pagination.search as string }
}

export function mapParams(pagination?: GetOrdersPagination) {
  return {
    ...mapLimitParam(pagination),
    ...mapOffsetParam(pagination),
    ...mapSearchParam(pagination),
  }
}

export function convertNumberFromSearchParam(value: string | null) {
  const parsedOffset = value ? parseInt(value, 10) : null

  if (!parsedOffset) {
    return null
  }

  return parsedOffset
}

export function convertNumberToSearchParam(value: any) {
  if (value == null) {
    return null
  }

  return value
}

export function convertSearchParam(search: string | null) {
  if (!search || isEmpty(search.trim())) {
    return null
  }

  return search
}

export const useOrdersPaginationQueryParamsPlugin =
  generateUseSearchParamsPlugin<GetOrdersPagination>({
    offset: {
      type: 'primitive',
      fromSearchParam: convertNumberFromSearchParam,
      toSearchParam: convertNumberToSearchParam,
    },
    limit: {
      type: 'primitive',
      fromSearchParam: convertNumberFromSearchParam,
      toSearchParam: convertNumberToSearchParam,
    },
    search: {
      type: 'primitive',
      fromSearchParam: convertSearchParam,
      toSearchParam: convertSearchParam,
    },
  })
