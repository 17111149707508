import { Link } from '@loadsmart/miranda-react'

import { DetailField } from 'components/DetailField'
import { getEnvSubdomain } from 'utils/envVars'
import user from 'utils/user'

import { useFreightInformationSummaryContext } from './FreightInformationSummary.context'

export function FreightInformationCarrierRefNumberSummaryField() {
  const { shipment } = useFreightInformationSummaryContext()

  const carrierRefId = shipment.carrier_ref_numbers?.loadsmart_ref_number

  if (!carrierRefId || !user.isMTInternalUser) {
    return null
  }

  return (
    <DetailField
      label="Carrier Reference #"
      value={
        <Link
          href={`https://alice.${getEnvSubdomain()}loadsmart.com/shipments/loadsmart_ref/${carrierRefId}`}
          target="_blank"
        >
          #{carrierRefId}
        </Link>
      }
    />
  )
}
