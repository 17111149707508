import { useIsFulfillmentsFlowEnabled } from 'fulfillments/hooks/useIsFulfillmentsFlowEnabled'
import { defaultTo } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useSuppliersSettings } from 'suppliers/common/useSuppliersSettings'

import { Table } from 'components/TableV4'
import type { CustomColumn } from 'components/TableV4/Table.types'
import type { TableSelectedRowsIDs } from 'components/TableV4/Table.utils'
import { OrderHistoryProvider } from 'orders/components/OrderHistory'
import { useGoToOrderDetails } from 'orders/hooks/navigation'
import type { ListOrder } from 'orders/types'

import { DeleteOrderProvider } from '../../screens/Orders/common/DeleteOrder'
import {
  ManualOrderConsolidationProvider,
  NewShipmentFromOrderSelectionButton,
} from '../../screens/Orders/ManualConsolidation'
import {
  BulkAutoPlanButton,
  OneClickConsolidationProvider,
} from '../../screens/Orders/OneClickConsolidation'
import { StartSimpleConsolidation } from '../../screens/Orders/SimpleConsolidation/SimpleConsolidation.components'
import { SimpleConsolidationProvider } from '../../screens/Orders/SimpleConsolidation/SimpleConsolidation.context'
import {
  OrdersFiltersProvider,
  useOrdersFiltersContext,
} from '../components/Filters'
import { AppliedFiltersBar } from '../components/Filters.components'
import { useOrderSelection } from '../hooks/useOrderSelection'
import { usePaginatedOrders } from '../hooks/usePaginatedOrders'
import {
  ActionsCell,
  CommodityCell,
  DeliveryLocationCell,
  FulfillmentsCell,
  IdentifierCell,
  IdentifierHeaderCell,
  OrdersTableEmptyState,
  PickupLocationCell,
  StatusCell,
  SupplierCell,
} from './ListOrders.components'
import { OrdersListProvider } from './ListOrders.context'

export function ListOrdersAsCustomerBulkActions({
  selectedOrders,
}: Readonly<{ selectedOrders: ListOrder[] }>) {
  const isFulfillmentsFlowEnabled = useIsFulfillmentsFlowEnabled()

  if (isFulfillmentsFlowEnabled) {
    return null
  }

  return (
    <>
      <StartSimpleConsolidation selectedOrders={selectedOrders} />
      <NewShipmentFromOrderSelectionButton />
      <BulkAutoPlanButton selectedOrders={selectedOrders} />
    </>
  )
}

const COLUMNS_WITH_SUPPLIER: ReadonlyArray<CustomColumn<ListOrder>> = [
  {
    id: 'ref_number',
    Header: IdentifierHeaderCell,
    Cell: IdentifierCell,
    customWidth: '200px',
  },
  {
    Header: 'Status',
    id: 'status',
    Cell: StatusCell,
  },
  {
    Header: 'Fulfillments',
    id: 'fulfillments',
    Cell: FulfillmentsCell,
  },
  {
    Header: 'Origin',
    id: 'pickup_address',
    Cell: PickupLocationCell,
  },
  {
    Header: 'Destination',
    id: 'delivery_address',
    Cell: DeliveryLocationCell,
  },
  {
    Header: 'Commodity',
    id: 'commodity',
    Cell: CommodityCell,
  },
  {
    Header: 'Supplier',
    id: 'supplier_name',
    Cell: SupplierCell,
  },
  {
    Header: '',
    id: 'row_actions',
    Cell: ActionsCell,
  },
]

const COLUMNS_WITHOUT_SUPPLIER: ReadonlyArray<CustomColumn<ListOrder>> = [
  {
    id: 'ref_number',
    Header: IdentifierHeaderCell,
    Cell: IdentifierCell,
    customWidth: '200px',
  },
  {
    Header: 'Status',
    id: 'status',
    Cell: StatusCell,
  },
  {
    Header: 'Origin',
    id: 'pickup_address',
    Cell: PickupLocationCell,
  },
  {
    Header: 'Destination',
    id: 'delivery_address',
    Cell: DeliveryLocationCell,
  },
  {
    Header: 'Commodity',
    id: 'commodity',
    Cell: CommodityCell,
  },
  {
    Header: '',
    id: 'row_actions',
    Cell: ActionsCell,
  },
]

type OrdersListTableProps = ReturnType<typeof usePaginatedOrders> & {
  readonly selectedOrderUUIDs: string[]
  readonly setSelectedOrders: (newSelected: string[]) => void
}

function OrdersListTable({
  clearFilters,
  currentPage,
  data,
  filters,
  isLoading,
  onPageChange,
  onSearchChange,
  pageSize,
  resultsCount,
  selectedOrderUUIDs,
  setFilters,
  setSelectedOrders,
}: OrdersListTableProps) {
  const isFulfillmentsFlowEnabled = useIsFulfillmentsFlowEnabled()
  const { openFilters } = useOrdersFiltersContext()
  const goToDetails = useGoToOrderDetails()
  const { isSuppliersEnabled } = useSuppliersSettings()
  const columns = isSuppliersEnabled
    ? COLUMNS_WITH_SUPPLIER
    : COLUMNS_WITHOUT_SUPPLIER

  const onRowClick = useCallback(
    (order: ListOrder) => {
      goToDetails(order.uuid)
    },
    [goToDetails]
  )

  const onSelectionChange = useCallback(
    (selection: TableSelectedRowsIDs) => {
      if (selection !== 'all') {
        setSelectedOrders(selection)
      }
    },
    [setSelectedOrders]
  )

  const selectedOrders = useMemo(() => {
    if (data?.results && selectedOrderUUIDs?.length) {
      return data?.results?.filter((order) =>
        selectedOrderUUIDs.includes(order.uuid)
      )
    }

    return []
  }, [data, selectedOrderUUIDs])

  return (
    <OrdersListProvider>
      <Table<ListOrder>
        appliedFiltersBar={
          <AppliedFiltersBar
            clearFilters={clearFilters}
            filters={filters}
            setFilters={setFilters}
          />
        }
        bulkActions={
          <ListOrdersAsCustomerBulkActions selectedOrders={selectedOrders} />
        }
        columns={columns}
        count={data?.results?.length}
        data={defaultTo(data?.results, [])}
        isLoading={isLoading}
        onOpenFilters={openFilters}
        onPageChange={onPageChange}
        onRowClick={onRowClick}
        onSearchInputChange={onSearchChange}
        onSelectionChange={onSelectionChange}
        page={currentPage}
        pageSize={pageSize}
        searchFilter={defaultTo(filters.search, '')}
        selectable={!isFulfillmentsFlowEnabled}
        selectedRows={selectedOrderUUIDs}
        totalCount={resultsCount}
        uniqueIDfield="uuid"
      />
      <OrdersTableEmptyState count={data?.count} isLoading={isLoading} />
    </OrdersListProvider>
  )
}

export function ListOrdersAsCustomer() {
  const paginatedOrdersProps = usePaginatedOrders()
  const { selectedOrderUUIDs, setSelectedOrders } = useOrderSelection()

  const { clearFilters, filters, refetch, setFilters } = paginatedOrdersProps

  return (
    <ManualOrderConsolidationProvider selectedOrderUUIDs={selectedOrderUUIDs}>
      <OneClickConsolidationProvider onSuccess={refetch}>
        <SimpleConsolidationProvider>
          <DeleteOrderProvider callback={refetch}>
            <OrderHistoryProvider>
              <OrdersFiltersProvider
                clearFilters={clearFilters}
                filters={filters}
                setFilters={setFilters}
              >
                <OrdersListTable
                  {...paginatedOrdersProps}
                  selectedOrderUUIDs={selectedOrderUUIDs}
                  setSelectedOrders={setSelectedOrders}
                />
              </OrdersFiltersProvider>
            </OrderHistoryProvider>
          </DeleteOrderProvider>
        </SimpleConsolidationProvider>
      </OneClickConsolidationProvider>
    </ManualOrderConsolidationProvider>
  )
}
