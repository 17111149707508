import { Field, TextField } from '@loadsmart/miranda-react'

import type { CustomFormFieldProps } from '../HandlingUnits.types'
import { useCommodityFormField } from './CommoditiesForm.hooks'

export const CommodityPackageCount = (props: CustomFormFieldProps) => {
  const { required, hint, style } = props
  const { controlProps, fieldProps, hintProps, value, labelProps, setValue } =
    useCommodityFormField({ name: 'package_count', hint })

  return (
    <Field
      {...fieldProps}
      required={required ?? fieldProps.required}
      style={style}
    >
      <Field.Label {...labelProps}>Total comm. count</Field.Label>
      <TextField
        {...controlProps}
        placeholder="e.g. 2"
        type="number"
        inputMode="numeric"
        min="1"
        value={value || ''}
        onChange={(event) => {
          const {
            target: { value: newValue },
          } = event

          setValue(Number(newValue))
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
