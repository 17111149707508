import type { TagProps } from '@loadsmart/miranda-react'
import { Tag } from '@loadsmart/miranda-react'

import { STATUS_SLUG_TO_LABEL } from 'orders/constants'
import type { Order } from 'orders/types'

export function formatStatusTag(status: Order['status']) {
  return STATUS_SLUG_TO_LABEL[status].toUpperCase()
}

const VARIANTS: Record<Order['status'], TagProps['variant']> = {
  open: 'warning',
  planning: 'info',
  deleted: 'danger',
  closed: 'neutral',
  planned: 'neutral',
}

export function OrderStatusTag({
  value,
  size = 'small',
}: Readonly<{
  value: Order['status']
  size?: TagProps['size']
}>) {
  return (
    <Tag size={size} variant={VARIANTS[value]}>
      {formatStatusTag(value)}
    </Tag>
  )
}
