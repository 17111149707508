import { Checkbox, Field } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'

import type { CustomFormFieldProps } from '../HandlingUnits.types'
import { useCommodityFormField } from './CommoditiesForm.hooks'

export const CommodityHazmat = (props: CustomFormFieldProps) => {
  const { required, hint, style } = props
  const { controlProps, fieldProps, hintProps, labelProps, value, setValue } =
    useCommodityFormField({ name: 'hazmat', hint })

  return (
    <Field {...fieldProps} required={required} style={style}>
      <Field.Label
        {...labelProps}
        style={{
          visibility: 'hidden',
        }}
      >
        Hazmat
      </Field.Label>
      <Checkbox
        {...controlProps}
        style={{
          minHeight: toCSSValue('global-height-default'),
          display: 'flex',
          alignItems: 'center',
        }}
        checked={value || false}
        onChange={(event) => {
          const {
            target: { checked },
          } = event

          setValue(checked)
        }}
      >
        Hazmat
      </Checkbox>
      <Field.Hint {...hintProps} />
    </Field>
  )
}
