import { Text } from '@loadsmart/miranda-react'

interface StackableTableCellProps {
  readonly stackable?: boolean
  readonly warning?: boolean
}

const StackableTableCell = ({
  stackable,
  warning,
}: StackableTableCellProps) => {
  return stackable ? (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Text
        variant="body-sm"
        color={warning ? 'color-text-error' : 'color-text-secondary'}
      >
        Stackable
      </Text>
    </div>
  ) : (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Text
        variant="body-sm"
        color={warning ? 'color-text-error' : 'color-text-secondary'}
      >
        Do not stack
      </Text>
    </div>
  )
}

export default StackableTableCell
