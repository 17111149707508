import { IconArrowsCornerUp } from '@loadsmart/icons'
import {
  Card,
  Layout,
  SpinnerWheel,
  Table,
  Tag,
  Text,
} from '@loadsmart/miranda-react'
import { defaultTo } from 'lodash'
import { generatePath, NavLink } from 'react-router-dom'

import { AppRoutes } from 'router/AppRoutes'
import { useShipmentDetails } from 'screens/Shipper/Shipments/Details/hooks/useShipmentDetails'
import { getTagVariantForStatus } from 'screens/Shipper/Shipments/utils'
import { getEquipmentLabelByAlias } from 'utils/equipmentType'

import { formatRFPDate } from '../../../utils/dateUtils'
import { useViewOrderContext } from '../ViewOrderContext'

function ShipmentsTableRow({ uuid }: Readonly<{ uuid: string }>) {
  const shipmentQuery = useShipmentDetails({}, uuid)

  if (shipmentQuery.isLoading) {
    return (
      <Table.Row>
        <Table.Cell>
          <Layout.Group justify="center">
            <SpinnerWheel size="18px" />
          </Layout.Group>
        </Table.Cell>
        <Table.Cell />
        <Table.Cell />
        <Table.Cell />
        <Table.Cell />
      </Table.Row>
    )
  }

  if (!shipmentQuery.data) {
    return null
  }

  return (
    <Table.Row>
      <Table.Cell>
        <Layout.Stack>
          <NavLink
            to={{
              pathname: generatePath(AppRoutes.ShipmentDetails, {
                id: uuid,
              }),
            }}
            style={{ textDecoration: 'none' }}
          >
            <Text color="color-text-link">
              {shipmentQuery.data.shipper_ref_number}{' '}
              <IconArrowsCornerUp
                width="12px"
                style={{
                  verticalAlign: 'middle',
                  position: 'relative',
                  top: -1,
                }}
              />
            </Text>
          </NavLink>
          <Text color="color-text-secondary" variant="body-sm">
            {shipmentQuery.data.shipper_guide_id}
          </Text>
        </Layout.Stack>
      </Table.Cell>
      <Table.Cell>
        <Layout.Stack>
          <Text>
            {shipmentQuery.data.pickup.city}, {shipmentQuery.data.pickup.state}
          </Text>
          {shipmentQuery.data.pickup.date && (
            <Text color="color-text-secondary" variant="body-sm">
              {formatRFPDate(shipmentQuery.data.pickup.date)}
            </Text>
          )}
        </Layout.Stack>
      </Table.Cell>
      <Table.Cell>
        <Layout.Stack>
          <Text>
            {shipmentQuery.data.delivery.city},{' '}
            {shipmentQuery.data.delivery.state}
          </Text>
          {shipmentQuery.data.delivery.date && (
            <Text color="color-text-secondary" variant="body-sm">
              {formatRFPDate(shipmentQuery.data.delivery.date)}
            </Text>
          )}
        </Layout.Stack>
      </Table.Cell>
      <Table.Cell>
        <Layout.Stack>
          <Text>{shipmentQuery.data.mode}</Text>

          <Text color="color-text-secondary" variant="body-sm">
            {getEquipmentLabelByAlias(shipmentQuery.data.equipment_type)}
          </Text>
        </Layout.Stack>
      </Table.Cell>
      <Table.Cell>
        <Tag
          size="small"
          variant={getTagVariantForStatus(shipmentQuery.data.status)}
        >
          {shipmentQuery.data.status.toUpperCase()}
        </Tag>
      </Table.Cell>
    </Table.Row>
  )
}

export function OrderShipmentsCard() {
  const { order } = useViewOrderContext()
  const shipmentsUUIDs = defaultTo(order?.shipments, [])

  if (!shipmentsUUIDs.length) {
    return null
  }

  return (
    <Card data-testid="order-shipments-card">
      <Card.Title>Shipments</Card.Title>
      <Card.Divider />
      <Card.Body>
        <Table size="large">
          <Table.Head>
            <Table.Row>
              <Table.HeadCell>Shipment</Table.HeadCell>
              <Table.HeadCell>Pickup</Table.HeadCell>
              <Table.HeadCell>Delivery</Table.HeadCell>
              <Table.HeadCell>Mode</Table.HeadCell>
              <Table.HeadCell>Status</Table.HeadCell>
            </Table.Row>
          </Table.Head>

          <Table.Body>
            {shipmentsUUIDs.map(({ uuid }) => (
              <ShipmentsTableRow key={uuid} uuid={uuid} />
            ))}
          </Table.Body>
        </Table>
      </Card.Body>
    </Card>
  )
}
