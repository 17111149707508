import type { AxiosRequestConfig } from 'axios'

import httpClient from 'utils/httpClient'

export const getShipperFacilities = async <T>(
  params?: Record<string, unknown>,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get<T[]>('/shippers/facilities', {
    params,
    ...config,
  })

  return data
}

export const getFacilityDetails = async <T>(
  facilityUUID: string,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get<T>(
    `/shippers/facilities/${facilityUUID}`,
    config
  )
  return data
}
