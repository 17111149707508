import { isEmpty } from 'lodash'
import { useQuery } from 'react-query'

import { listOrders } from 'orders/order-service'
import type { Order } from 'orders/types'
import type { LocationParam } from 'screens/Quotes/List/types'
import { encodeLocationParam } from 'screens/Quotes/List/utils'

export type OrdersListFiltersParams = {
  status: Order['status'][] | null
  delivery_location: LocationParam[] | null
  pickup_location: LocationParam[] | null
  pickup_date_after: string | null
  pickup_date_before: string | null
  delivery_date_after: string | null
  delivery_date_before: string | null
  uuid?: string[] | null
}

export interface UseOrdersListParams {
  limit: number
  offset: number
  search_term: string | null
  sort?: string
  filters: OrdersListFiltersParams
}

export function useOrdersList(
  { filters, ...params }: UseOrdersListParams,
  enabled?: boolean
) {
  const filterParams = {
    ...filters,
    delivery_location: !isEmpty(filters.delivery_location)
      ? encodeLocationParam(filters.delivery_location as LocationParam[])
      : null,
    pickup_location: !isEmpty(filters.pickup_location)
      ? encodeLocationParam(filters.pickup_location as LocationParam[])
      : null,
  }

  return useQuery({
    enabled,
    queryKey: ['listOrders', params, filterParams],
    queryFn: ({ signal }) =>
      listOrders(
        {
          ...params,
          ...filterParams,
          uuid: filterParams.uuid ? filterParams.uuid.join(',') : null,
        },
        { signal }
      ),
    refetchOnWindowFocus: false,
  })
}
