import type { FieldProps, SelectProps } from '@loadsmart/miranda-react'
import { Field, Select } from '@loadsmart/miranda-react'
import { useFulfillmentFormContext } from 'fulfillments/create/CreateFulfillmentContext'
import { useFulfillmentField } from 'fulfillments/create/hooks/useFulfillmentField'
import type { FulfillmentForm } from 'fulfillments/domain/Fulfillment'
import { get } from 'lodash'
import type { CSSProperties } from 'react'

export type FulfillmentSelectProps = Readonly<{
  name: keyof Omit<FulfillmentForm, 'handling_units' | 'stops'>
  label?: string
  required?: FieldProps['required']
  placeholder?: string
  hint?: string
  error?: string
  style?: CSSProperties
}> &
  SelectProps
export function FulfillmentSelect({
  name,
  label,
  required,
  placeholder = '',
  hint = '',
  error,
  style,
  options,
}: FulfillmentSelectProps) {
  const { fulfillment, setPartialFulfillment } = useFulfillmentFormContext()

  const { fieldProps, hintProps } = useFulfillmentField({
    name,
    hint,
    error,
    meta: fulfillment._metadata,
  })

  return (
    <Field
      {...fieldProps}
      required={required}
      style={style}
      data-testid={`${name}-field`}
    >
      <Field.Label id={`label-${name}`}>{label}</Field.Label>
      <Select
        name={name}
        id={name}
        aria-labelledby={`label-${name}`}
        placeholder={placeholder}
        style={{ minWidth: '100%' }}
        onChange={(event) => {
          setPartialFulfillment({ [name]: get(event, 'target.value') })
        }}
        value={get(fulfillment, name)}
        options={options}
        type="single"
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
