import { Select, Tag } from '@loadsmart/loadsmart-ui'
import type { GenericOption } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'
import type { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import type EventLike from '@loadsmart/loadsmart-ui/dist/utils/types/EventLike'
import { Layout, Text, TextField } from '@loadsmart/miranda-react'
import type { WCTextField } from '@loadsmart/miranda-react'
import type { ChangeEvent } from '@loadsmart/miranda-wc/dist/utils/types'
import { defaultTo } from 'lodash'
import { useCallback, useMemo } from 'react'

import AppliedFilters from 'components/AppliedFilters'
import type { Filters } from 'utils/filters'

import type {
  FilterOp,
  GetOrdersFilters,
} from '../hooks/useOrdersFiltersQueryParamPlugin'
import {
  getAppliedFilterTags,
  getHasAppliedFilters,
  OP_FULL_LABELS,
} from './Filters.utils'

const OPTIONS = Object.entries(OP_FULL_LABELS).map(([value, label]) => ({
  label,
  value,
  _type: 'operator',
}))

function getOption(value: keyof typeof OP_FULL_LABELS | undefined) {
  if (!value) {
    return undefined
  }

  return {
    label: OP_FULL_LABELS[value],
    value,
    _type: 'operator',
  }
}

export interface OperatorAndValueFilterProps {
  readonly field: 'package_count' | 'total_volume' | 'total_weight'
  readonly filters: GetOrdersFilters
  readonly inputPlaceholder: string
  readonly setFilters: (newFilters: Partial<GetOrdersFilters>) => void
  readonly trailing: string
}

export function OperatorAndValueFilter({
  field,
  filters,
  inputPlaceholder,
  setFilters,
  trailing,
}: OperatorAndValueFilterProps) {
  const op = `${field}_op`

  const handleOpChange = useCallback(
    (e: EventLike<Selectable | Selectable[] | null>) => {
      const option = e.target.value as GenericOption
      setFilters({
        [op]: option.value as unknown as FilterOp,
      })
    },
    [op, setFilters]
  )

  const handleValueChange = useCallback(
    (e: ChangeEvent<WCTextField>) => {
      setFilters({
        [field]: e.target.value,
      })
    },
    [field, setFilters]
  )

  return (
    <Layout.Group align="center">
      <Select
        aria-label="Operator"
        id={`${field}_op`}
        name={`${field}_op`}
        onChange={handleOpChange}
        options={OPTIONS}
        placeholder="Select operator"
        value={getOption(defaultTo(filters[`${field}_op`], undefined))}
      />
      <Layout.Box padding="none">
        <TextField
          data-testid={`input-${field}`}
          inputMode="numeric"
          min="0"
          onChange={handleValueChange}
          placeholder={inputPlaceholder}
          trailing={
            <Text color="color-text-secondary" variant="body-sm">
              {trailing}
            </Text>
          }
          type="number"
          value={defaultTo(filters[field], undefined)}
        />
      </Layout.Box>
    </Layout.Group>
  )
}

export interface AppliedFiltersBarProps {
  readonly clearFilters: () => void
  readonly filters: Filters<GetOrdersFilters>
  readonly hideStatus?: boolean
  readonly setFilters: (newFilters: Filters<GetOrdersFilters>) => void
}

export function AppliedFiltersBar({
  clearFilters,
  filters,
  hideStatus = false,
  setFilters,
}: AppliedFiltersBarProps) {
  const hasAppliedFilters = getHasAppliedFilters(filters, hideStatus)
  const appliedFiltersTags = useMemo(
    () => getAppliedFilterTags(filters, hideStatus),
    [filters, hideStatus]
  )

  const onRemoveFilter = useCallback(
    (removedFilter: keyof GetOrdersFilters) => {
      let opOverride = {}

      if (
        ['package_count', 'total_volume', 'total_weight'].includes(
          removedFilter
        )
      ) {
        opOverride = { [`${removedFilter}_op`]: null }
      }

      setFilters({ ...filters, [removedFilter]: null, ...opOverride })
    },
    [filters, setFilters]
  )

  if (!hasAppliedFilters) {
    return null
  }

  return (
    <Layout.Group align="center" gap="spacing-2">
      <AppliedFilters
        appliedFilters={appliedFiltersTags}
        handleRemove={onRemoveFilter}
        onClearFilters={clearFilters}
      />
    </Layout.Group>
  )
}

export type RemovableTagsProps<T> = {
  readonly items: T[] | null
  readonly getItemKey: (item: T) => string
  readonly onRemoveItem: (index: number) => void
  readonly getLabel: (item: T) => string
}

export function SelectedItemsTags<T>(props: RemovableTagsProps<T>) {
  if (!Array.isArray(props.items)) {
    return null
  }

  return (
    <Layout.Group gap="spacing-2" data-testid="selected-item-tags">
      {props.items.map((item, index) => (
        <Tag
          key={props.getItemKey(item)}
          data-testid={`selected-item-tag-${props.getItemKey(item)}`}
          variant="default"
          removable
          onRemove={() => props.onRemoveItem(index)}
        >
          {props.getLabel(item)}
        </Tag>
      ))}
    </Layout.Group>
  )
}
