import { useMutation } from 'react-query'

import { createShipmentFromSingleOrder } from 'orders/order-service'

export function useCreateShipmentFromSingleOrderMutation() {
  const mutation = useMutation({
    mutationFn: createShipmentFromSingleOrder,
  })

  return mutation
}
