import { Card, Layout } from '@loadsmart/miranda-react'
import type { ReactNode } from 'react'

import type { TransientHandlingUnit } from 'components/HandlingUnitsManager/HandlingUnits.types'
import { HandlingUnitsSummary } from 'components/HandlingUnitsManager/HandlingUnitsSummary'

import { HandlingUnitTitle } from './HandlingUnitTitle'

type HandlingUnitHeaderProps = {
  readonly handlingUnit: TransientHandlingUnit
  readonly actions: ReactNode
}

export const HandlingUnitHeader = ({
  handlingUnit,
  actions,
}: HandlingUnitHeaderProps) => {
  return (
    <Card.Title>
      <Layout.Group align="center" justify="space-between" gap="spacing-2">
        <Layout.Stack gap="spacing-2">
          <Layout.Group align="center" justify="flex-start" gap="spacing-2">
            <HandlingUnitsSummary value={handlingUnit}>
              <HandlingUnitsSummary.ErrorsCountTag />
              <HandlingUnitsSummary.Hazmat />
              <HandlingUnitsSummary.Stackable />
              <HandlingUnitsSummary.Turnable />
            </HandlingUnitsSummary>
          </Layout.Group>

          <HandlingUnitTitle handlingUnit={handlingUnit} />

          <HandlingUnitsSummary value={handlingUnit}>
            <Layout.Group gap="spacing-2">
              <HandlingUnitsSummary.Weight />
              <HandlingUnitsSummary.FreightClass />
            </Layout.Group>
          </HandlingUnitsSummary>
        </Layout.Stack>
        {actions}
      </Layout.Group>
    </Card.Title>
  )
}
