import { Field, TextField } from '@loadsmart/miranda-react'

import { numberFormatter } from 'utils/numbers'

import type { CustomFormFieldProps } from '../HandlingUnits.types'
import { useCommodityFormField } from './CommoditiesForm.hooks'

export const CommodityWeight = (props: CustomFormFieldProps) => {
  const { required, hint, style } = props
  const { controlProps, fieldProps, hintProps, value, labelProps, setValue } =
    useCommodityFormField({ name: 'weight', hint })

  return (
    <Field {...fieldProps} required={required} style={style}>
      <Field.Label {...labelProps}>Total comm. weight</Field.Label>
      <TextField
        {...controlProps}
        placeholder="lbs"
        type="number"
        min="1"
        inputMode="numeric"
        value={value ? numberFormatter(value).replace(/,/g, '') : ''}
        onChange={(event) => {
          const {
            target: { value: newValue },
          } = event

          setValue(newValue)
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
