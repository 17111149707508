import { useMemo } from 'react'

import type { TransientHandlingUnit } from './HandlingUnits.types'
import { HandlingUnitDimensionsSummary } from './HandlingUnitsForm/HandlingUnitDimensionsSummary'
import { useHandlingUnitsForm } from './HandlingUnitsForm/HandlingUnitsForm.hooks'
import {
  sumHandlingUnitCount,
  calculateTotalDimensions,
} from './HandlingUnitsUtils'
import { useLinearFeet } from './useLinearFeet'

export const HandlingUnitsDimensionsSummary = ({
  handlingUnits,
}: {
  readonly handlingUnits: TransientHandlingUnit[]
}) => {
  const { totalDensity, totalVolume, totalWeight } = useMemo(() => {
    return calculateTotalDimensions(handlingUnits)
  }, [handlingUnits])
  const linearFeet = useLinearFeet(handlingUnits, totalWeight)
  const handlingUnitsCount = sumHandlingUnitCount(handlingUnits)

  return (
    <HandlingUnitDimensionsSummary
      handlingUnitsCount={handlingUnitsCount}
      cubicDensity={totalDensity}
      cubicVolume={totalVolume}
      totalWeight={totalWeight}
      linearFeet={linearFeet}
    />
  )
}

export const ContextBasedHandlingUnitDimensionsSummary = () => {
  const [items] = useHandlingUnitsForm()
  return <HandlingUnitsDimensionsSummary handlingUnits={items} />
}
