import type { AxiosRequestConfig } from 'axios'
import type { QueryClient } from 'react-query'

import type { RequestForProposal } from 'rfp/rfp.types'
import getFileListForDeletion from 'utils/file'
import httpClient from 'utils/httpClient'
import { slugify } from 'utils/strings'
import toArray from 'utils/toArray'

import type { GenericApiSelectOption } from '../../services/types'

export const list = async (
  {
    state,
    limit,
    offset,
    sort,
    name,
    mode,
    frequency,
    rateType,
    bid,
  }: {
    state?: string
    limit?: number
    offset?: number
    sort?: string
    name?: string
    mode: string
    frequency: string
    rateType: string
    bid: string
  },
  config?: Omit<AxiosRequestConfig, 'params'>
) => {
  const { data } = await httpClient.get('/proposals/rfp', {
    ...config,
    params: {
      state,
      limit,
      offset,
      sort,
      name,
      mode,
      capacity_time_period: frequency,
      rate_type: rateType,
      with_bids: bid,
    },
  })
  return data
}

export const uploadAttachments = async (
  rfpId: number | string,
  attachments: File[]
) => {
  const filteredAttachments = attachments?.filter((file) => 'size' in file)
  if (filteredAttachments.length === 0) {
    return undefined
  }

  const results = []
  for (const attachment of filteredAttachments) {
    const formData = new FormData()
    formData.append('file', attachment)

    const { data } = await httpClient.post(
      `/proposals/rfp/${rfpId}/attachment`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )

    results.push(data)
  }

  return results
}

export const deleteAttachments = async (
  rfpId: number | string,
  attachmentsIds: number[]
) => {
  const results = []
  for (const id of attachmentsIds) {
    const { data } = await httpClient.delete(
      `/proposals/rfp/${rfpId}/attachment/${id}`
    )
    results.push(data)
  }

  return results
}

export const retrieve = async (
  id: number | string,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get(`/proposals/rfp/${id}`, config)
  return data
}

export const partialUpdate = async ({
  id,
  payload,
  attachments,
  queryClient,
}: {
  id: number | string
  payload: Partial<RequestForProposal>
  attachments?: { formAttachments: File[]; oldAttachments: Attachment[] }
  queryClient: QueryClient
}) => {
  const { data } = await httpClient.patch(`/proposals/rfp/${id}`, payload)

  if (attachments?.oldAttachments) {
    const attachmentIds = getFileListForDeletion(
      attachments.formAttachments,
      attachments.oldAttachments
    )
    await deleteAttachments(id, attachmentIds)
  }
  if (attachments?.formAttachments) {
    await uploadAttachments(id, attachments.formAttachments)
  }

  await queryClient.refetchQueries({
    queryKey: ['retrieveRFP', String(id)],
    exact: false,
  })

  return data
}

export const remove = async (id: number | string) => {
  const { data } = await httpClient.delete(`/proposals/rfp/${id}`)
  return data
}

export const validateLanesFile = async (file: File) => {
  const formData = new FormData()
  formData.append('file', file)
  const { data } = await httpClient.post(
    '/proposals/rfp/file_validation',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return data
}

export const create = async (form: FormData) => {
  const { data } = await httpClient.post('/proposals/rfp', form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return data
}

export const duplicate = async (id: number) => {
  const { data } = await httpClient.post(`/proposals/rfp/${id}/duplicate`)
  return data
}

export const sendAwardsByEmail = async (
  id: number,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get(
    `/proposals/rfp_export/${id}/award_pdfs`,
    config
  )
  return data
}

export const exportResource = async (
  {
    id,
    resource,
    format,
  }: {
    id: number | string
    resource: 'awards' | 'bids'
    format: 'csv' | 'excel' | 'csv_rows'
  },
  config?: Omit<AxiosRequestConfig, 'responseType'>
) => {
  const { data, headers, status } = await httpClient.get(
    `/proposals/rfp_export/${id}/${resource}_${format}`,
    {
      ...config,
      responseType: 'arraybuffer',
    }
  )
  return { data, type: headers['content-type'], status }
}

export const exportAwardLettersToEmail = async (
  rfp_id: number,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get(
    `/proposals/rfp_export/${rfp_id}/award_pdfs`,
    config
  )
  return data
}
export const exportRFPReport = async (
  {
    start_period_report,
    end_period_report,
    format,
  }: {
    start_period_report: string
    end_period_report: string
    format: string
  },
  config?: Omit<AxiosRequestConfig, 'responseType'>
) => {
  const { data, headers } = await httpClient.get(
    `/proposals/rfp_export/rfp_report_${format}?start_period_report=${start_period_report}&end_period_report=${end_period_report}`,
    {
      ...config,
      responseType: 'arraybuffer',
    }
  )
  return { data, type: headers['content-type'] }
}
export const downloadSample = async (
  rfpId?: number,
  config?: Omit<AxiosRequestConfig, 'responseType'>
) => {
  const rfpIdUrl = rfpId ? `?rfp=${rfpId}` : ''
  const { data, headers } = await httpClient.get(
    `/proposals/rfp/sample${rfpIdUrl}`,
    {
      ...config,
      responseType: 'arraybuffer',
    }
  )
  return { data, type: headers['content-type'] }
}

export const downloadResource = async (
  rfpReportdata: {
    rfpId: number
    rfpName: string
    type: string
    format: string
  },
  config?: Omit<AxiosRequestConfig, 'responseType'>
) => {
  const reportName = `${slugify(rfpReportdata.rfpName)}-${rfpReportdata.type}`
  const { data, headers } = await httpClient.get(
    `/proposals/rfp_export/${rfpReportdata.rfpId}/download_report?report_type=${rfpReportdata?.type}&report_format=${rfpReportdata?.format}`,
    {
      ...config,
      responseType: 'arraybuffer',
    }
  )
  return { data, reportName, type: headers['content-type'] }
}

export const summary = async (id: number, config?: AxiosRequestConfig) => {
  const { data } = await httpClient.get(`/proposals/rfp_summary/${id}`, config)
  return data
}

export const totalizers = async (
  id: number | string,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get(
    `/proposals/rfp/${id}/totalizers`,
    config
  )
  return data
}

export const MODES_ENDPOINT = '/proposals/modes'
export const getModes = async (config?: AxiosRequestConfig) => {
  const { data } = await httpClient.get(MODES_ENDPOINT, config)
  return data
}

export const getRateTypes = async (config?: AxiosRequestConfig) => {
  const { data } = await httpClient.get('/proposals/rate_types', config)
  return data
}

export const getTimePeriods = async (config?: AxiosRequestConfig) => {
  const { data } = await httpClient.get('/proposals/time_periods', config)
  return data
}

export const EQUIPMENT_TYPES_ENDPOINT = '/proposals/equipment_types'
export const getEquipmentTypes = async (
  modeFilter?: string | string[],
  config?: AxiosRequestConfig
) => {
  const modes = toArray(modeFilter)
  let qs = ''

  modes.forEach((mode, index) => {
    qs = `${qs}${index > 0 ? '&' : ''}modes=${mode}`
  })

  const { data } = await httpClient.get<GenericApiSelectOption[]>(
    `${EQUIPMENT_TYPES_ENDPOINT}?${qs}`,
    config
  )

  return data
}

export const getEquipmentTypesGroupedByMode = async (
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get<{
    [key: string]: GenericApiSelectOption[]
  }>('/proposals/mode_equipment_types', config)
  return data
}

export const getEquipmentSizes = async (config?: AxiosRequestConfig) => {
  const { data } = await httpClient.get<GenericApiSelectOption[]>(
    '/proposals/equipment_sizes',
    config
  )
  return data
}

export const getAwardAcceptanceDealines = async (
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get(
    '/proposals/award_acceptance_deadlines',
    config
  )
  return data
}

export const getCurrencies = async (config?: AxiosRequestConfig) => {
  const { data } = await httpClient.get('/proposals/currencies', config)
  return data
}

export const getBidTypes = async (config?: AxiosRequestConfig) => {
  const { data } = await httpClient.get('/proposals/bid_types', config)
  return data
}

export const getDistanceTypes = async (config?: AxiosRequestConfig) => {
  const { data } = await httpClient.get<GenericApiSelectOption[]>(
    '/proposals/distance_types',
    config
  )
  return data
}

export const uploadLanes = async ({
  files,
  rfpId,
  queryClient,
}: {
  files: File[]
  rfpId: string | number
  queryClient: QueryClient
}) => {
  const formData = new FormData()
  files.forEach((file: File) => formData.append('file', file))
  if (rfpId) {
    formData.append('rfp', `${rfpId}`)
  }
  const { data } = await httpClient.post(
    `/proposals/rfp/${rfpId}/lane/upload`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  await queryClient.refetchQueries({ queryKey: ['retrieveRFP'], exact: false })
  return data
}

export const bulkDeleteLanes = async ({
  rfpId,
  laneIds,
}: {
  rfpId: string | number
  laneIds: Array<string | number>
}) => {
  const { data } = await httpClient.delete(
    `/proposals/rfp/${rfpId}/lane/bulk_delete`,
    {
      data: { lane_ids: laneIds },
    }
  )

  return data
}
