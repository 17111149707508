import { defaultTo } from 'lodash'
import { useMemo } from 'react'
import { useQuery } from 'react-query'

import {
  getListOrdersParams,
  listOrders,
  MAX_LIMIT,
} from 'orders/order-service'

export function usePlannableOrdersOptions() {
  const params = getListOrdersParams({
    limit: MAX_LIMIT,
    offset: 0,
    search_term: null,
    status: ['open', 'planning'],
  })

  const { data, isError, isLoading } = useQuery({
    queryKey: ['getPlannableOrders', params],
    queryFn: () =>
      listOrders({
        ...params,
      }),
    cacheTime: 60000,
  })

  const orders = defaultTo(data?.results, [])

  const ordersOptions = useMemo(
    () =>
      defaultTo(data?.results, []).map((order) => ({
        label: order.primary_ref,
        value: order.uuid,
      })),
    [data?.results]
  )

  return {
    isError,
    isLoading,
    orders,
    ordersOptions,
  }
}
