import { Layout } from '@loadsmart/miranda-react'

import AppointmentCardScheduledBy from 'screens/Shipper/Shipments/Details/AppointmentCard/AppointmentCardScheduledBy'
import AppointmentDateCard from 'screens/Shipper/Shipments/Details/AppointmentCard/AppointmentDateCard'
import { AppointmentPendingSchedule } from 'screens/Shipper/Shipments/Details/AppointmentCard/AppointmentPendingSchedule'
import MTCAppointmentOutOfTime from 'screens/Shipper/Shipments/Details/AppointmentCard/MTCAppointmentOutOfTime'
import type { StopAppointment } from 'screens/Shipper/Shipments/Details/types'

import AppointmentDateTime from './AppointmentDateTime'

export type ReadonlyAppointmentCardProps = Readonly<{
  appointment: StopAppointment
  stopDate: string | null
  warehouseUUID?: string | null
}>

export default function ReadonlyAppointmentCard({
  appointment,
  stopDate,
  warehouseUUID,
}: ReadonlyAppointmentCardProps) {
  return (
    <Layout.Group>
      <AppointmentDateCard stopDate={stopDate} appointment={appointment} />
      <Layout.Stack gap="spacing-1">
        <AppointmentDateTime
          appointment={appointment}
          warehouseUUID={warehouseUUID}
        />
        <AppointmentPendingSchedule appointment={appointment} />
        <AppointmentCardScheduledBy appointment={appointment} />
        <MTCAppointmentOutOfTime appointment={appointment} />
      </Layout.Stack>
    </Layout.Group>
  )
}
