import type { ListOrder } from 'orders/types'

export const ID_COLUMNS_LABEL = {
  primary_ref: {
    header: 'Primary Ref #',
    option: 'Primary Reference Number',
  },
  so_number: {
    header: 'SO #',
    option: 'SO Number',
  },
  po_number: {
    header: 'PO #',
    option: 'PO Number',
  },
} satisfies Partial<Record<keyof ListOrder, object>>

export type IdentifierColumns = keyof typeof ID_COLUMNS_LABEL
