import { Button, Dialog, Icon } from '@loadsmart/miranda-react'
import { useCallback } from 'react'

import type { ListOrder } from 'orders/types'

import { ValidationResult } from '../common/PlanValidationResult'
import { useSimpleConsolidationContext } from './SimpleConsolidation.context'

export function StartSimpleConsolidation({
  selectedOrders,
}: {
  readonly selectedOrders: ListOrder[]
}) {
  const { isEnabled, openDialog } = useSimpleConsolidationContext()

  const onStartShipmentClick = useCallback(() => {
    openDialog(selectedOrders)
  }, [openDialog, selectedOrders])

  if (!isEnabled) {
    return null
  }

  return (
    <Button variant="primary" onClick={onStartShipmentClick}>
      Start shipment
    </Button>
  )
}

export function ValidationDialog() {
  const {
    selectedOrders,
    isOpen,
    removeOrder,
    closeDialog,
    validationResult,
    isLoadingValidation,
    continueShipmentCreation,
  } = useSimpleConsolidationContext()

  if (!isOpen || selectedOrders.length === 0) {
    return null
  }

  return (
    <Dialog
      open={isOpen}
      size="large"
      data-testid="dialog-simple-consolidation-validation"
      onClose={closeDialog}
    >
      <Dialog.Header>
        Plan shipment
        <Dialog.Close />
      </Dialog.Header>
      <Dialog.Body>
        <ValidationResult
          handleRemoveOrders={removeOrder}
          isLoading={isLoadingValidation}
          orderSelection={selectedOrders}
          validationResult={validationResult}
        />
      </Dialog.Body>
      <Dialog.Actions>
        <Dialog.ActionTertiary onClick={closeDialog}>
          Cancel
        </Dialog.ActionTertiary>
        <Dialog.ActionPrimary
          trailing={<Icon name="arrow-right" color="color-text-inverted" />}
          disabled={!validationResult}
          onClick={continueShipmentCreation}
        >
          Continue
        </Dialog.ActionPrimary>
      </Dialog.Actions>
    </Dialog>
  )
}
