import { IconPlus } from '@loadsmart/icons'
import { Button } from '@loadsmart/miranda-react'
import type { ReactNode } from 'react'

import { useCommoditiesForm } from './CommoditiesForm.hooks'

export const CommoditiesFormAddCommodity = ({
  children,
}: {
  readonly children?: ReactNode
}) => {
  const [, dispatch] = useCommoditiesForm()

  return (
    <Button
      variant="tertiary"
      leading={<IconPlus width={12} title={null} aria-label={null} />}
      onClick={() => {
        dispatch({ type: 'ADD_ITEM' })
      }}
    >
      {children ?? 'Add more commodities to this handling unit'}
    </Button>
  )
}
