import { Card, Layout } from '@loadsmart/miranda-react'
import { isEmpty } from 'lodash'
import type { PropsWithChildren } from 'react'
import { useMemo } from 'react'
import styled from 'styled-components'

import { FacilitySummary } from 'components/FacilityDetails'
import type { TransientHandlingUnit } from 'components/HandlingUnitsManager/HandlingUnits.types'
import { getHandlingUnitsPickedUpAtStop } from 'components/HandlingUnitsManager/HandlingUnitsUtils'
import type { TransientStop } from 'components/StopsManager'
import {
  StopSummary,
  getStopSectionTitle,
  isIntermediaryStop,
} from 'components/StopsManager'
import {
  getTransientError,
  getTransientErrorsCount,
  getTransientErrorsCountFromArray,
} from 'utils/transient'

const StyledCard = styled(Card)`
  width: 100%;
`

const getAllErrorsCount = (
  handlingUnits: Array<TransientHandlingUnit>,
  stop: TransientStop,
  overrideErrors?: boolean
) => {
  const handlingUnitsErrors = getTransientErrorsCount(handlingUnits)
  const commoditiesErrors = handlingUnits
    .map(({ commodities }) => getTransientErrorsCountFromArray(commodities))
    .reduce((acc, curr) => acc + curr, 0)
  const overrideErrorsCount = overrideErrors ? 1 : 0
  const contactErrorCount = getTransientError(stop, 'contact') ? 1 : 0

  return (
    handlingUnitsErrors +
    commoditiesErrors +
    overrideErrorsCount +
    contactErrorCount
  )
}

export type FulfillmentStopProps = PropsWithChildren<{
  readonly id?: string
  readonly stop: TransientStop
  readonly index: number
  readonly totalStops: number
  readonly handlingUnits?: Array<TransientHandlingUnit>
  readonly overrideErrors?: boolean
  readonly initialCollapsedState?: boolean
}>

export function FulfillmentStop({
  id,
  stop,
  index,
  totalStops,
  children,
  handlingUnits,
  overrideErrors,
  initialCollapsedState = true,
}: FulfillmentStopProps) {
  const handlingUnitsPickedUpAtStop = getHandlingUnitsPickedUpAtStop(
    handlingUnits,
    stop.stop_index
  )

  const errorsCount = useMemo(() => {
    return getAllErrorsCount(handlingUnitsPickedUpAtStop, stop, overrideErrors)
  }, [handlingUnitsPickedUpAtStop, overrideErrors, stop])

  return (
    <StyledCard
      id={id}
      data-testid={`stop-section-${index}`}
      key={stop.uuid}
      style={{ '--m-card-body-padding': '0' }}
      initialCollapsed={initialCollapsedState}
      collapsible
    >
      <StopSummary stop={stop}>
        <Card.Title>
          <Layout.Group gap="spacing-2" align="center">
            {getStopSectionTitle(index, totalStops)}
            {isIntermediaryStop(index, totalStops) && (
              <StopSummary.StopTypeTag />
            )}
            <StopSummary.ErrorsCountTag errorsCount={errorsCount} />
          </Layout.Group>
        </Card.Title>
        <Card.Subtitle>
          {isEmpty(stop.facility) ? (
            '-'
          ) : (
            <FacilitySummary facility={stop.facility}>
              <Layout.Group gap="spacing-1" align="center">
                <FacilitySummary.Name
                  variant="body-md"
                  color="color-text-secondary"
                />
                •
                <FacilitySummary.Location color="color-text-secondary" />
              </Layout.Group>
            </FacilitySummary>
          )}
        </Card.Subtitle>
        <Card.Divider />
        <Card.Body>{children}</Card.Body>
      </StopSummary>
    </StyledCard>
  )
}
