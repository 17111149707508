import { Card, LabeledValue, Layout } from '@loadsmart/miranda-react'
import { capitalize } from 'lodash'

import { defaultEmptyString } from 'utils/strings'

import { useViewOrderContext } from '../ViewOrderContext'

export function OrderOverviewCard() {
  const { order } = useViewOrderContext()

  if (!order) {
    return null
  }

  return (
    <Card data-testid="order-overview-card">
      <Card.Title>Overview</Card.Title>
      <Card.Divider />
      <Card.Body>
        <Layout.Grid>
          <LabeledValue
            data-testid="order-owner"
            label="Owner"
            value={defaultEmptyString(order.customer?.name)}
          />
          <LabeledValue
            data-testid="order-supplier"
            label="Supplier"
            value={defaultEmptyString(order.supplier?.name)}
          />
          <LabeledValue
            data-testid="order-external-id"
            label="External ID"
            value={defaultEmptyString(order.external_id)}
          />
          <LabeledValue
            data-testid="order-ship-with"
            label="Ship with"
            value={defaultEmptyString(order.ship_with)}
          />
          <LabeledValue
            data-testid="order-po-number"
            label="PO number"
            value={defaultEmptyString(order.po_number)}
          />
          <LabeledValue
            data-testid="order-so-number"
            label="SO number"
            value={defaultEmptyString(order.so_number)}
          />
          <LabeledValue
            data-testid="order-source"
            label="Source"
            value={capitalize(defaultEmptyString(order.source))}
          />
          <LabeledValue
            data-testid="order-integration-source"
            label="Integration source"
            value={defaultEmptyString(order.integration_source)}
          />
          <LabeledValue
            data-testid="order-movement"
            label="Movement"
            value={capitalize(defaultEmptyString(order.movement))}
          />
        </Layout.Grid>
      </Card.Body>
    </Card>
  )
}
