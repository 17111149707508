import { isEmpty } from 'lodash'
import { useQuery } from 'react-query'
import type { QueryObserverOptions } from 'react-query'

import { getFacilities } from 'services/facilities'
import type { FacilityV2 } from 'services/facilities'
import type { Filters } from 'utils/filters'
import toArray from 'utils/toArray'

import type { StateOption } from './StateSelect'

export type FacilitiesFilters = {
  limit?: number
  offset?: number
  sort?: string | null
  search?: string | null
  state?: StateOption | null
  opendock_connected?: boolean | null
}

export const DEFAULT_LIMIT = 50
export const DEFAULT_OFFSET = 0

export function getDefaultFilters(
  overrides?: Partial<FacilitiesFilters>
): FacilitiesFilters {
  return {
    limit: overrides?.limit ?? DEFAULT_LIMIT,
    offset: overrides?.offset ?? DEFAULT_OFFSET,
    sort: overrides?.sort ?? null,
    search: overrides?.search ?? '',
    state: overrides?.state ?? null,
    opendock_connected: overrides?.opendock_connected ?? false,
  }
}

export function mapLimitToURLSearchParam(
  limit?: FacilitiesFilters['limit']
): string[][] {
  return [['limit', String(limit || DEFAULT_LIMIT)]]
}

export function mapOffsetToURLSearchParam(
  offset?: FacilitiesFilters['offset']
): string[][] {
  return [['offset', String(offset ?? DEFAULT_OFFSET)]]
}

export function mapSortToURLSearchParam(
  sort?: FacilitiesFilters['sort']
): string[][] {
  const columns = toArray(sort)

  if (isEmpty(columns)) {
    return []
  }

  return columns.map((column) => ['sort', column])
}

export function mapSearchToURLSearchParam(
  search?: FacilitiesFilters['search']
): string[][] {
  if (!search || search.length <= 2) {
    return []
  }

  return [['search', search]]
}

export function mapStateToURLSearchParam(
  state?: FacilitiesFilters['state']
): string[][] {
  if (!state) {
    return []
  }

  return [
    ['state', String(state.value)],
    ['country', state._type!],
  ]
}

export function mapOpendockConnectedToURLSearchParam(
  opendockConnected?: FacilitiesFilters['opendock_connected']
): string[][] {
  if (opendockConnected !== true) {
    return []
  }

  return [['opendock_connected', 'true']]
}

export function mapFiltersToURLSearchParams(
  filters: Filters<FacilitiesFilters>
) {
  return new URLSearchParams([
    ...mapLimitToURLSearchParam(filters.limit),
    ...mapOffsetToURLSearchParam(filters.offset),
    ...mapSortToURLSearchParam(filters.sort),
    ...mapSearchToURLSearchParam(filters.search),
    ...mapStateToURLSearchParam(filters.state),
    ...mapOpendockConnectedToURLSearchParam(filters.opendock_connected),
  ])
}

export function useFacilities(props: {
  filters?: Filters<FacilitiesFilters>
  options?: QueryObserverOptions<PaginatedResult<FacilityV2>>
}) {
  const { filters = {} as Filters<FacilitiesFilters>, options = {} } = props

  return useQuery<PaginatedResult<FacilityV2>>({
    ...options,
    queryKey: ['getFacilitiesV2', filters],
    queryFn: ({ signal }) => {
      return getFacilities({
        params: mapFiltersToURLSearchParams(filters),
        signal,
      })
    },
  })
}
