import { Select } from '@loadsmart/loadsmart-ui'
import { Field } from '@loadsmart/miranda-react'

import { FREIGHT_CLASSES_OPTIONS } from 'components/ShippingItemsManager/ShippingItems.constants'
import { getFreightClassOption } from 'components/ShippingItemsManager/ShippingItemsForm.utils'
import { fromSelectOption } from 'utils/selectOption'

import type { CustomFormFieldProps } from '../HandlingUnits.types'
import { useCommodityFormField } from './CommoditiesForm.hooks'

export const CommodityFreightClass = (props: CustomFormFieldProps) => {
  const { disabled, required, hint, style } = props
  const {
    controlProps,
    fieldProps,
    hintProps,
    labelProps,
    name,
    value,
    setValue,
  } = useCommodityFormField({ name: 'freight_class', hint })

  return (
    <Field {...fieldProps} required={required} style={style}>
      <Field.Label {...labelProps}>Class</Field.Label>
      <Select
        getInputProps={() => controlProps}
        disabled={disabled || Boolean(controlProps.disabled)}
        hideClear={required}
        placeholder="Select"
        name={name}
        options={FREIGHT_CLASSES_OPTIONS}
        value={getFreightClassOption(value)}
        onChange={(event) => {
          const {
            target: { value: option },
          } = event

          const newValue = fromSelectOption(option as SelectOption)
          setValue(newValue)
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
