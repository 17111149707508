import { IconPlus } from '@loadsmart/icons'
import { Button } from '@loadsmart/miranda-react'
import type { ReactNode } from 'react'

import { useHandlingUnitsForm } from './HandlingUnitsForm.hooks'

type HandlingUnitsFormAddHandlingUnitProps = {
  readonly children?: ReactNode
  readonly onClick?: () => void
}

export const HandlingUnitsFormAddHandlingUnit = ({
  children,
  onClick,
}: HandlingUnitsFormAddHandlingUnitProps) => {
  const [, dispatch] = useHandlingUnitsForm()

  return (
    <Button
      variant="tertiary"
      leading={<IconPlus width={12} title={null} aria-label={null} />}
      onClick={() => {
        onClick?.()
        dispatch({ type: 'ADD_ITEM' })
      }}
    >
      {children ?? 'Add handling unit'}
    </Button>
  )
}
