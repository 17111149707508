import type { TagVariant } from '@loadsmart/miranda-react'

import toArray from 'utils/toArray'

import { CONTAINER_STATUS, SHIPMENT_STATUS, SOURCE_OPTIONS } from './constants'
import type { AdaptedShipment } from './Details/types'
import type { ShipmentAction, ShipmentActionKey } from './types'

export const isActionAvailable = (
  availableActions: ShipmentAction[],
  actionKey: ShipmentActionKey
) =>
  toArray(availableActions).some(
    (action) => action.available_action === actionKey
  )

type GetShipmentStatusProps = {
  status: AdaptedShipment['status']
  quoteStatus: AdaptedShipment['quoteStatus']
}

export function getShipmentStatus({
  status,
  quoteStatus,
}: GetShipmentStatusProps) {
  if (quoteStatus === SHIPMENT_STATUS.Booked || !quoteStatus) {
    return status
  }
  return quoteStatus
}

export function getTagVariantForStatus(status: string): TagVariant {
  switch (status) {
    case SHIPMENT_STATUS.New:
      return 'success'
    case SHIPMENT_STATUS.Tendered:
      return 'success'
    case SHIPMENT_STATUS.Tender_rejected:
      return 'danger'
    case SHIPMENT_STATUS.Expired:
      return 'warning'
    case SHIPMENT_STATUS.Canceled:
      return 'danger'
    default:
      return 'neutral'
  }
}

export function getTagVariantForContainerStatus(status: string): TagVariant {
  switch (status) {
    case CONTAINER_STATUS.available_at_terminal:
      return 'success'
    case CONTAINER_STATUS.on_hold:
      return 'danger'
    case CONTAINER_STATUS.delivered_to_terminal:
    case CONTAINER_STATUS.returned_to_terminal:
      return 'success'
    default:
      return 'neutral'
  }
}

export function getSourceLabel(source: string) {
  return SOURCE_OPTIONS.find((type) => type.value === source)?.label
}
