import { Layout, Text } from '@loadsmart/miranda-react'

import {
  DimensionsSummaryDensity,
  DimensionsSummaryLinearFeet,
  DimensionsSummaryVolume,
  formatWeight,
} from 'components/ShippingItemsManager'

import type { DimensionsProps } from '../HandlingUnits.types'

type HandlingUnitDimensionsWeightProps = {
  readonly count: number
  readonly totalWeight: string | number
}

const HandlingUnitDimensionsWeight = ({
  count,
  totalWeight,
}: HandlingUnitDimensionsWeightProps) => {
  return (
    <Text variant="body-md" color="color-text-primary">
      {`${count} H/U weighing ${formatWeight(totalWeight)} in total`}
    </Text>
  )
}

/**
 * read-only handling unit dimensions
 */
export const HandlingUnitDimensionsSummary = ({
  totalWeight,
  cubicVolume,
  cubicDensity,
  linearFeet,
  handlingUnitsCount,
}: DimensionsProps) => (
  <Layout.Group
    gap="spacing-4"
    align="center"
    // For testing purpose, LabeledValue miranda-wc component text is in shadow DOM
    data-testId="handling-unit-dimension"
  >
    <HandlingUnitDimensionsWeight
      count={handlingUnitsCount}
      totalWeight={totalWeight}
    />
    <DimensionsSummaryVolume volume={cubicVolume} />
    <DimensionsSummaryDensity density={cubicDensity} />
    <DimensionsSummaryLinearFeet linearFeet={linearFeet} />
  </Layout.Group>
)
