import type { AxiosRequestConfig } from 'axios'

import httpClient from 'utils/httpClient'

export type AuditLogsKind =
  | 'shipment'
  | 'shipment-charge'
  | 'order'
  | 'contract'
  | 'fulfillment'

export type AuditLogsParams = {
  kind: AuditLogsKind
  uuid: string
  related?: boolean
  page?: number
}

export type AuditLogRaw = {
  metadata: {
    uuid: string
    timestamp: string
    actor: { identifier: string; kind: string }
    event_name: string
    event_description: string
    object: { identifier: string; kind: string }
  }
  payload: {
    description?: string
    actor_metadata?: { username: string }
  }
}

export type AuditLogsResponseRaw = PaginatedResult<AuditLogRaw>

export type AuditLog = {
  uuid: string
  timestamp: string
  name: string
  description?: string
  username?: string
}

export type AuditLogsResponse = Pick<
  AuditLogsResponseRaw,
  'count' | 'next' | 'previous'
> & {
  logs: AuditLog[]
}

const adaptAuditLogs = (results: AuditLogRaw[]): AuditLog[] =>
  results.map((log) => ({
    uuid: log.metadata.uuid,
    timestamp: log.metadata.timestamp,
    name: log.metadata.event_description,
    description: log.payload.description,
    username: log.payload.actor_metadata?.username,
  }))

export const getAuditLogsURL = ({
  kind,
  uuid,
  page,
  related,
}: AuditLogsParams) => {
  let auditLogsURL = `/logs?object_kind=${kind}&object_id=${uuid}`

  if (page) {
    auditLogsURL = `${auditLogsURL}&page=${page}`
  }
  if (related) {
    auditLogsURL = `${auditLogsURL}&related=true`
  }

  return auditLogsURL
}

export const getAuditLogs = async (
  params: AuditLogsParams,
  config?: AxiosRequestConfig
): Promise<AuditLogsResponse> => {
  const { data } = await httpClient.get<AuditLogsResponseRaw>(
    getAuditLogsURL(params),
    config
  )

  return {
    count: data.count,
    logs: adaptAuditLogs(data.results),
    next: data.next,
    previous: data.previous,
  }
}
