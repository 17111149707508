import { Header } from '@loadsmart/miranda-react'
import { NewFulfillmentButton } from 'fulfillments/components/NewFulfillmentButton'

export function ListFulfillmentsPageHeader() {
  return (
    <Header>
      <Header.Content>
        <Header.Title>Fulfillments</Header.Title>
      </Header.Content>

      <Header.Actions>
        <NewFulfillmentButton />
      </Header.Actions>
    </Header>
  )
}
