import { Field, Layout, Switch, TextArea } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { get, isEmpty } from 'lodash'
import { useRef } from 'react'
import type { CSSProperties } from 'react'

import { haveNotesChanged } from './StopNotes.helpers'
import { useStopFormField } from './useStopFormField'
import type { UseStopFormFieldProps } from './useStopFormField'

type StopNotesProps = Readonly<{
  label?: string
  required?: FieldProps['required']
  hint?: UseStopFormFieldProps['hint']
  error?: UseStopFormFieldProps['error']
  style?: CSSProperties
  hideSwitch?: boolean
}>

export const StopNotes = (props: StopNotesProps) => {
  const { required, hint, error, style, hideSwitch } = props

  const {
    controlProps,
    fieldProps,
    hintProps,
    index,
    labelProps,
    name,
    stop,
    dispatch,
  } = useStopFormField({
    name: 'notes',
    hint,
    error,
  })

  let label = 'Facility instructions'

  if (stop.location) {
    label = 'Stop instructions'
  }

  const initialValueRef = useRef(get(stop, 'notes') ?? '')

  return (
    <Layout.Stack gap="spacing-2" style={style}>
      <Field {...fieldProps} required={required}>
        <Field.Label {...labelProps}>{props.label ?? label}</Field.Label>

        <TextArea
          {...controlProps}
          autosize
          name={name}
          value={get(stop, name, '')}
          onChange={(event) => {
            // to do: review notes property as an array in the facility type.
            dispatch({
              type: 'SET_ITEM',
              payload: {
                index,
                changes: {
                  [name]: event.target.value,
                },
              },
            })
          }}
        />
        <Field.Hint {...hintProps} />
      </Field>
      {!hideSwitch && isEmpty(stop.location) && (
        <Layout.Group
          align="center"
          gap="spacing-2"
          hidden={
            haveNotesChanged(initialValueRef.current, get(stop, name, ''))
              ? undefined
              : true
          }
        >
          <Switch
            checked={get(stop, 'update_facility_notes', false)}
            onChange={(event) => {
              dispatch({
                type: 'SET_ITEM',
                payload: {
                  index,
                  changes: {
                    update_facility_notes: get(event, 'target.checked', false),
                  },
                },
              })
            }}
          >
            Update the default instructions for this facility
          </Switch>
        </Layout.Group>
      )}
    </Layout.Stack>
  )
}
