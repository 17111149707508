import type { QueryObserverOptions } from 'react-query'
import { useQuery } from 'react-query'

import { getOrderDetails } from 'orders/order-service'
import type { Order } from 'orders/types'

export function useOrderDetails(
  uuid: string,
  options: QueryObserverOptions<Order> = {}
) {
  return useQuery<Order>({
    ...options,
    queryKey: ['getOrderDetails', uuid],
    queryFn: ({ signal }) => getOrderDetails(uuid, { signal }),
  })
}
