import type { AxiosRequestConfig } from 'axios'

import httpClient from 'utils/httpClient'
import toArray from 'utils/toArray'

export const ACCESORIALS_ENDPOINT = '/accessorials'

export const getAccessorialsURL = (
  modeFilter?: TransportationModeCode | TransportationModeCode[]
) => {
  const modes = toArray(modeFilter)
  let qs = ''

  modes.forEach((mode, index) => {
    qs = `${qs}${index > 0 ? '&' : ''}modes=${mode}`
  })

  return `${ACCESORIALS_ENDPOINT}?${qs}`
}

export const listAccessorials = async (
  modeFilter?: TransportationModeCode | TransportationModeCode[],
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get(getAccessorialsURL(modeFilter), config)

  return data
}
