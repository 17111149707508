import { useIsCurrentShipperASupplier } from 'suppliers/common/useIsCurrentShipperASupplier'

import { ListOrdersAsCustomer } from './ListOrdersAsCustomer'
import { ListOrdersAsSupplier } from './ListOrdersAsSupplier'

export function ListOrders() {
  const isSupplier = useIsCurrentShipperASupplier()

  if (isSupplier) {
    return <ListOrdersAsSupplier />
  }

  return <ListOrdersAsCustomer />
}
