import type { SelectDatasource } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'
import { getShipperFacilities } from 'facilities/facilities.services'
import { useFormik as useForm } from 'formik'

import { mapFiltersToURLSearchParams } from 'screens/Shipper/FacilityManagement/useFacilities'
import type { FacilityV2 } from 'services/facilities'
import { getFacilities } from 'services/facilities'
import facilitiesAdapter, {
  facilitiesV2Adapter,
} from 'utils/adapters/facilities'

interface AdaptedFacility extends Facility {
  label: string
  value: string
  className: string
}

interface AdaptedFacilityV2 extends FacilityV2 {
  label: string
  value: string
  className: string
}

const facilitySearchConfig = {
  type: 'facility',
  adapter: {
    getKey: (facility: AdaptedFacility) => facility.uuid,
    getLabel: (facility: AdaptedFacility) =>
      facility?.label || facility?.company_name || facility.address,
  },
}

const facilityV2SearchConfig = {
  type: 'facility',
  adapter: {
    getKey: (facility: AdaptedFacilityV2) => facility.uuid,
    getLabel: (facility: AdaptedFacilityV2) =>
      facility?.label || facility?.name || facility.address,
  },
}

export function filterFacilities({
  adaptedFacilities,
  regex,
}: {
  adaptedFacilities: Array<AdaptedFacility>
  regex: RegExp
}): Array<AdaptedFacility> {
  return adaptedFacilities.filter((facility) => {
    const fieldsToFilter = [
      'label',
      'address',
      'zipcode',
      'city',
      'state',
      'country',
    ] as const
    return fieldsToFilter.some((field) => regex.test(facility[field]))
  })
}

export function useSelectFacilitySearch(): SelectDatasource<AdaptedFacility> {
  return {
    ...facilitySearchConfig,
    async fetch({ query, regex }) {
      const facilities = await getShipperFacilities<Facility>({
        search: query,
        sort: 'company_name',
      })
      const adaptedFacilities = facilitiesAdapter({
        facilities,
      })

      return filterFacilities({ adaptedFacilities, regex })
    },
  }
}

export function useSelectFacilityV2Search(): SelectDatasource<AdaptedFacilityV2> {
  return {
    ...facilityV2SearchConfig,
    async fetch({ query }) {
      const { results: facilities } = await getFacilities({
        params: mapFiltersToURLSearchParams({
          search: query,
          sort: 'name',
        }),
      })
      return facilitiesV2Adapter({
        facilities,
      })
    },
  }
}

export { useForm }
