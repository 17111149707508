import { SideNavigation, Tag } from '@loadsmart/loadsmart-ui'
import { Icon } from '@loadsmart/miranda-react'
import { useHistory } from 'react-router-dom'

import { useSettings } from 'contexts/settings'
import { AppRoutes } from 'router/AppRoutes'
import { ANALYTICS_PORTAL_URL, USER_TYPE } from 'utils/constants'
import userWrapper from 'utils/user'

import { isActivePath, navbarLinkClicked } from './sidenav.utils'

type Props = {
  readonly enablePriceMonitor: boolean
}

export const AnalyticsMenu = ({ enablePriceMonitor }: Props) => {
  const history = useHistory()

  const {
    values: [enableInsightsAI, enableAnalyticsPortal],
  } = useSettings([
    'flags.ENABLE_FREIGHT_INSIGHTS_AI',
    'flags.ENABLE_ANALYTICS_PORTAL',
  ])

  if (userWrapper.isUserType([USER_TYPE.SG])) {
    return (
      <SideNavigation.Menu label="Analytics">
        {enableInsightsAI && (
          <SideNavigation.Menu.Item
            active={isActivePath(AppRoutes.Insights)}
            label="FreightIntel AI"
            url={AppRoutes.Insights}
            onClick={(event) => {
              event.preventDefault()
              navbarLinkClicked('Insights AI', AppRoutes.Insights, history)
            }}
            trailing={
              <Tag size="small" variant="success">
                NEW
              </Tag>
            }
          />
        )}
        {enablePriceMonitor && (
          <SideNavigation.Menu.Item
            active={isActivePath(AppRoutes.PriceMonitor)}
            label="Price Monitor"
            url={AppRoutes.PriceMonitor}
            onClick={(event) => {
              event.preventDefault()
              navbarLinkClicked(
                'Price Monitor',
                AppRoutes.PriceMonitor,
                history
              )
            }}
          />
        )}
        <SideNavigation.Menu.Item
          active={isActivePath(AppRoutes.ShipperReports)}
          label="Reports"
          url={AppRoutes.ShipperReports}
          onClick={(event) => {
            event.preventDefault()
            navbarLinkClicked('Reports', AppRoutes.ShipperReports, history)
          }}
        />
        {enableAnalyticsPortal && (
          <SideNavigation.Menu.Item
            label="Analytics Portal"
            url="#"
            onClick={(event) => {
              event.preventDefault()
              window.open(`${ANALYTICS_PORTAL_URL}/login`, '_blank')
            }}
            trailing={
              <Icon name="arrow-corner-up" color="color-text-placeholder" />
            }
          />
        )}
      </SideNavigation.Menu>
    )
  }

  return null
}
