import { Drawer } from '@loadsmart/miranda-react'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import type { FacilityContactV2 } from 'services/facilities'

import { ContactsDetails } from './ContactsDetails'
import { ContactsEditForm } from './ContactsEditForm'

export type ContactsManagerDrawerProps = {
  isOpen: boolean
  facilityUUID: string
  onClose: () => void
  onClosed: () => void
  onContactsSaved: (contacts: FacilityContactV2[]) => void
  initialView?: 'details' | 'edit-form'
}

export function ContactsManagerDrawer({
  isOpen,
  facilityUUID,
  onClose,
  onClosed,
  onContactsSaved,
  initialView = 'edit-form',
}: ContactsManagerDrawerProps) {
  const [currentView, setCurrentView] = useState(initialView)

  useEffect(() => {
    if (isOpen) {
      setCurrentView(initialView)
    }
  }, [initialView, isOpen])

  return createPortal(
    <Drawer
      open={isOpen}
      size="large"
      divided
      onClose={onClose}
      onClosed={onClosed}
      data-testid="contacts-drawer"
    >
      {currentView === 'details' ? (
        <ContactsDetails
          facilityUUID={facilityUUID}
          onEditContacts={() => {
            setCurrentView('edit-form')
          }}
        />
      ) : (
        <ContactsEditForm
          facilityUUID={facilityUUID}
          onClose={onClose}
          onContactsSaved={onContactsSaved}
        />
      )}
    </Drawer>,
    document.body,
    'contacts-drawer-root'
  )
}
