import type { Fulfillment } from 'fulfillments/domain/Fulfillment'
import { getFulfillmentDetails } from 'fulfillments/fulfillment-service'
import { useQuery } from 'react-query'

export function useFulfillmentDetails(uuid: string) {
  return useQuery<Fulfillment>({
    queryKey: ['getFulfillmentDetails', uuid],
    queryFn: ({ signal }) => getFulfillmentDetails(uuid, { signal }),
  })
}
