import { createIndexProvider } from 'contexts/indexer'

import { CommoditiesTransientCollectionForm } from './CommoditiesForm.hooks'
import { CommoditiesFormAddCommodity } from './CommoditiesFormAddCommodity'
import { CommoditiesFormRemoveCommodity } from './CommoditiesFormRemoveCommodity'
import { CommodityDescription } from './CommodityDescription'
import { CommodityFreightClass } from './CommodityFreightClass'
import { CommodityHazmat } from './CommodityHazmat'
import { CommodityNMFC } from './CommodityNMFC'
import { CommodityNMFCSearch } from './CommodityNMFCSearch'
import { CommodityPackageCount } from './CommodityPackageCount'
import { CommodityPackageType } from './CommodityPackageType'
import { CommodityWeight } from './CommodityWeight'

export const CommoditiesForm = Object.assign(
  CommoditiesTransientCollectionForm,
  {
    AddCommodity: CommoditiesFormAddCommodity,
    RemoveCommodity: CommoditiesFormRemoveCommodity,
  }
)

export const CommodityForm = Object.assign(createIndexProvider(), {
  Description: CommodityDescription,
  PackageType: CommodityPackageType,
  PackageCount: CommodityPackageCount,
  Weight: CommodityWeight,
  Hazmat: CommodityHazmat,
  Nmfc: CommodityNMFC,
  NmfcSearch: CommodityNMFCSearch,
  FreightClass: CommodityFreightClass,
})
