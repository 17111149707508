import { isBlank } from '@loadsmart/utils-string'
import { get } from 'lodash'

import { buildContactsPayload } from 'components/ContactsDrawer/useSaveFacilityContacts'
import { createTransientFacilityContact } from 'components/FacilityContactsForm/FacilityContactsForm.helpers'
import type {
  FacilityAccessorial,
  FacilityDetailsPayloadV2,
  FacilityDetailsV2,
  FacilityHoursOfOperationEntry,
  FacilityNoteV2,
} from 'services/facilities'
import toArray from 'utils/toArray'
import { createTransient } from 'utils/transient'
import { validateTransientWithSchema } from 'utils/yup'

import {
  createTransientAccessorialsByModeAndStop,
  createTransientAccessorialsByModeAndStopFromFacilityAccessorials,
} from './AccessorialsSection.helpers'
import { facilitySchema } from './Facility.schema'
import type {
  DailyTransientOperatingHours,
  TransientAccessorialsByModeAndStop,
  TransientFacilityInstruction,
  TransientFacilityV2,
  WeeklyTransientOperatingHours,
} from './Facility.types'
import { createTransientFacilityInstruction } from './InstructionsSection.helpers'
import { createTransientFacilityLocation } from './LocationSection.helpers'
import {
  createWeeklyTransientOperatingHours,
  createWeeklyTransientOperatingHoursFromFacilityHoursOfOperationEntries,
} from './OperatingHoursSection.helpers'

export const DEFAULT_ERROR_BANNER_MESSAGE =
  'Please fix the errors below to proceed.'
export const UNKNOWN_UPDATE_SERVER_ERROR_BANNER_MESSAGE =
  'Failed to update facility, try again later.'

export function createTransientFacilityV2(
  facility?: Partial<TransientFacilityV2>
): TransientFacilityV2 {
  return createTransient({
    location: createTransientFacilityLocation({ country: 'USA' }),
    contacts: [createTransientFacilityContact()],
    hours_of_operation: createWeeklyTransientOperatingHours(),
    notes: [createTransientFacilityInstruction()],
    accessorials: createTransientAccessorialsByModeAndStop(),
    ...facility,
  })
}

export function createTransientFacilityFromFacilityDetailsV2(
  facility: FacilityDetailsV2
): TransientFacilityV2 {
  return createTransientFacilityV2({
    location: createTransientFacilityLocation({
      name: facility.name,
      address: facility.address,
      address_details: facility.address_details,
      city: facility.city,
      state: facility.state,
      zipcode: facility.zipcode,
      country: facility.country,
      external_id: facility.external_id,
    }),
    contacts: facility.contacts.map(createTransientFacilityContact),
    hours_of_operation:
      createWeeklyTransientOperatingHoursFromFacilityHoursOfOperationEntries(
        facility.hours_of_operation
      ),
    notes: facility.notes.map(createTransientFacilityInstruction),
    accessorials:
      createTransientAccessorialsByModeAndStopFromFacilityAccessorials(
        facility.accessorials
      ),
  })
}

export function validateTransientFacility(
  facility: TransientFacilityV2
): [TransientFacilityV2, boolean] {
  return validateTransientWithSchema<TransientFacilityV2>(
    facilitySchema,
    facility
  )
}

export function buildDailyHoursOfOperationPayload(
  dayOfWeek: DayOfWeek,
  dailyOperatingHours: DailyTransientOperatingHours
): Partial<FacilityHoursOfOperationEntry>[] {
  if (!dailyOperatingHours.enabled) {
    return []
  }

  return toArray(dailyOperatingHours.operating_hours).map((operatingHours) => ({
    uuid: operatingHours.uuid ?? undefined,
    day_of_week: dayOfWeek,
    opens: operatingHours.opens ?? '',
    closes: operatingHours.closes ?? '',
    appointment_mode: operatingHours.appointment_mode ?? 'appt',
  }))
}

export function buildWeeklyHoursOfOperationPayload(
  hoursOfOperation: WeeklyTransientOperatingHours
): Partial<FacilityHoursOfOperationEntry>[] {
  return Object.entries(hoursOfOperation).flatMap(
    ([dayOfWeek, weeklyOperatingHours]) => {
      return buildDailyHoursOfOperationPayload(
        dayOfWeek as DayOfWeek,
        weeklyOperatingHours
      )
    }
  )
}

export function buildNotesPayload(
  notes: TransientFacilityInstruction[]
): Partial<FacilityNoteV2>[] {
  const isFilled = (note: TransientFacilityInstruction) =>
    !isBlank(note.note ?? '')

  return notes.filter(isFilled).map((note) => ({
    id: note.id ?? undefined,
    note: note.note ?? '',
  }))
}

export function buildFacilityAccessorialsPayload(
  accessorialsByModeAndStop: TransientAccessorialsByModeAndStop
): FacilityDetailsPayloadV2['accessorials'] {
  const accessorials: FacilityDetailsPayloadV2['accessorials'] = []

  Object.keys(accessorialsByModeAndStop).forEach((modeKey) => {
    const mode = modeKey as FacilityAccessorial['mode']
    const accessorialsByMode = accessorialsByModeAndStop[mode]

    Object.keys(accessorialsByMode).forEach((stopKey) => {
      const stop = stopKey as FacilityAccessorial['stop']
      const accessorialsByStop = accessorialsByMode[stop]

      accessorials.push(
        ...accessorialsByStop.map((accessorialOption) => ({
          uuid: accessorialOption.value,
          stop,
          mode,
        }))
      )
    })
  })

  return accessorials
}

export function buildFacilityPayload(
  facility: TransientFacilityV2
): FacilityDetailsPayloadV2 {
  return {
    name: get(facility, 'location.name', ''),
    address: get(facility, 'location.address', ''),
    address_details: get(facility, 'location.address_details', ''),
    city: get(facility, 'location.city', ''),
    state: get(facility, 'location.state', ''),
    zipcode: get(facility, 'location.zipcode', ''),
    country: get(facility, 'location.country', ''),
    external_id: get(facility, 'location.external_id', null) || null,
    contacts: buildContactsPayload(toArray(facility.contacts)),
    hours_of_operation: buildWeeklyHoursOfOperationPayload(
      facility.hours_of_operation
    ),
    notes: buildNotesPayload(toArray(facility.notes)),
    accessorials: buildFacilityAccessorialsPayload(facility.accessorials),
  }
}
