import { Header } from '@loadsmart/miranda-react'
import type { AnchorHTMLAttributes } from 'react'
import { Link } from 'react-router-dom'
import type { LinkProps } from 'react-router-dom'

const LinkComponent: LinkProps['component'] = (props) => {
  return (
    <Header.BackButton
      {...props}
      onClick={(event) => {
        event.preventDefault()
        props.navigate()
      }}
    />
  )
}

type HeaderBackLinkProps = Omit<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  'href'
> &
  Readonly<{
    href: string
  }>

export const HeaderBackLink = ({ href, ...props }: HeaderBackLinkProps) => {
  return <Link {...props} to={href} component={LinkComponent} />
}
