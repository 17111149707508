import { defaultTo } from 'lodash'
import { useCallback } from 'react'
import { useQuery } from 'react-query'

import { useOffsetPagination } from 'hooks/usePagination'
import {
  DEFAULT_LIMIT,
  DEFAULT_OFFSET,
  getListOrdersParams,
  listOrdersAsSupplier,
} from 'orders/order-service'
import { useFilters } from 'utils/filters'

import type { GetOrdersPagination } from './useOrdersPaginationQueryParamsPlugin'
import {
  getDefaultOrdersPaginationParams,
  mapParams,
  useOrdersPaginationQueryParamsPlugin,
} from './useOrdersPaginationQueryParamsPlugin'

export function useOrdersAsSupplierFilters() {
  const { setFilters, ...filters } = useFilters<GetOrdersPagination>(
    {
      initialValues: {
        ...getDefaultOrdersPaginationParams(),
      },
    },
    useOrdersPaginationQueryParamsPlugin
  )

  const clearFilters = useCallback(() => {
    setFilters({
      ...getDefaultOrdersPaginationParams(),
    })
  }, [setFilters])

  return { clearFilters, setFilters, ...filters }
}

export function useOrdersAsSupplier() {
  const {
    clearFilters,
    setFilter,
    setFilters,
    values: filters,
  } = useOrdersAsSupplierFilters()

  const filterParams = getListOrdersParams({
    ...mapParams(filters),
  })

  const query = useQuery({
    queryKey: ['listOrdersAsSupplier', filterParams],
    queryFn: () =>
      listOrdersAsSupplier({
        ...filterParams,
      }),
    refetchOnWindowFocus: false,
  })

  const { getOffsetForPage, ...pagination } = useOffsetPagination({
    resultsCount: defaultTo(query.data?.count, 0),
    pageSize: defaultTo(filters.limit, DEFAULT_LIMIT),
    currentOffset: defaultTo(filters.offset, DEFAULT_OFFSET),
  })

  const onPageChange = useCallback(
    (event: { page: number; pageSize: number }) => {
      const { page, pageSize } = event

      setFilters({
        limit: pageSize,
        offset: getOffsetForPage(page),
      })
    },
    [getOffsetForPage, setFilters]
  )

  return {
    ...query,
    ...pagination,
    clearFilters,
    filters,
    getOffsetForPage,
    onPageChange,
    setFilter,
    setFilters,
  }
}
