import type { AxiosRequestConfig } from 'axios'

import type { QueryParams } from 'rfp/components/table/laneParams'
import httpClient from 'utils/httpClient'

import type {
  GenericApiSelectOptionWithCount,
  LanePrimaryAwardStatus,
} from './types'

export const list = async (
  rfpId: number | string,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get(`/proposals/rfp/${rfpId}/lane`, config)
  return data
}

const getLocationParams = (
  locations: {
    city: string
    state: string
    country: string
    zipcode: string
  }[],
  key: string
) => {
  let result = ''
  const cities: string[] = []
  const states: string[] = []
  const countries: string[] = []
  const codes: string[] = []

  const shouldAddPropertyToList = (
    locationProperty: string,
    propertyList: string[]
  ) => {
    const isNewProperty = !propertyList.includes(locationProperty)
    return locationProperty && isNewProperty
  }

  locations.forEach((location) => {
    if (shouldAddPropertyToList(location.city, cities)) {
      cities.push(location.city)
    }
    if (shouldAddPropertyToList(location.state, states)) {
      states.push(location.state)
    }
    if (shouldAddPropertyToList(location.country, countries)) {
      countries.push(location.country)
    }
    if (shouldAddPropertyToList(location.zipcode, codes)) {
      codes.push(location.zipcode)
    }
  })

  if (cities.length > 0) {
    result = result.concat(`&${key}_city=${cities.join(',')}`)
  }
  if (states.length > 0) {
    result = result.concat(`&${key}_state=${states.join(',')}`)
  }
  if (countries.length > 0) {
    result = result.concat(`&${key}_country=${countries.join(',')}`)
  }
  if (codes.length > 0) {
    result = result.concat(`&${key}_zipcode=${codes.join(',')}`)
  }

  return result
}

export interface ListPaginatedProps {
  rfpId: number | string
  limit: string | number
  offset: number | string
  sort: { column: string; direction: string }
  isAllocated?: boolean
  isRanked?: boolean
  primaryAwardStatus?: LanePrimaryAwardStatus
  query?: string
  filters?: QueryParams['filters']
}

export const listPaginated = async (
  {
    rfpId,
    limit,
    offset,
    sort,
    isAllocated,
    isRanked,
    primaryAwardStatus,
    query,
    filters,
  }: ListPaginatedProps,
  config?: AxiosRequestConfig
) => {
  let params = ''
  let sortParam = sort.column
  let allocatedParam = ''
  let rankedParam = ''
  let primaryAwardStatusParam = ''
  let queryParam = ''
  let filterParams = ''

  if (sort.direction === 'desc') {
    sortParam = `-${sort.column}`
  }

  if (typeof isAllocated === 'boolean') {
    allocatedParam = `&is_allocated=${isAllocated}`
  }

  if (typeof isRanked === 'boolean') {
    rankedParam = `&is_ranked=${isRanked}`
  }

  if (primaryAwardStatus !== '' && primaryAwardStatus !== undefined) {
    primaryAwardStatusParam = `&primary_award_status=${primaryAwardStatus}`
  }

  if (query) {
    queryParam = `&query=${query}`
  }

  filterParams = filterParams.concat(
    getLocationParams(filters?.origin ?? [], 'origin')
  )

  filterParams = filterParams.concat(
    getLocationParams(filters?.destination ?? [], 'dest')
  )

  if (filters?.bidStatus) {
    filters.bidStatus.forEach((status) => {
      filterParams = filterParams.concat(
        `&with_bids=${status.value === 'with_bids'}`
      )
    })
  }

  if (filters?.equipmentType) {
    const types: string[] = []
    filters.equipmentType.forEach((type) => {
      types.push(type.value)
    })

    if (types.length > 0) {
      filterParams = filterParams.concat(`&equipment_type=${types.join(',')}`)
    }
  }

  params = params.concat(`sort=${sortParam}`)
  params = params.concat(`&limit=${limit}`)
  params = params.concat(`&offset=${offset}`)
  params = params.concat(allocatedParam)
  params = params.concat(rankedParam)
  params = params.concat(primaryAwardStatusParam)
  params = params.concat(queryParam)
  params = params.concat(filterParams)

  const { data } = await httpClient.get(
    `/proposals/rfp/${rfpId}/lane?${params}`,
    config
  )

  return data
}

export const listPrimaryCarriersLanes = async (
  {
    rfpId,
    carrierId,
    limit,
    offset,
    sort,
  }: {
    rfpId: number | string
    carrierId: string | number
    limit: string | number
    offset: number | string
    sort: { column: string; direction: string }
  },
  config?: Omit<AxiosRequestConfig, 'params'>
) => {
  let sortParam = sort.column

  if (sort.direction === 'desc') {
    sortParam = `-${sort.column}`
  }

  const { data } = await httpClient.get(
    `/proposals/rfp/${rfpId}/lane?primary_carrier=${carrierId}&sort=${sortParam}&limit=${limit}&offset=${offset}`,
    config
  )
  return data
}

export const retrieve = async (
  id: number | string,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get(`/proposals/lanes/${id}`, config)
  return data
}

export const patch = async ({
  rfpId,
  laneId,
  lane,
}: {
  rfpId: number
  laneId: number
  lane: Partial<Lane>
}) => {
  const { data } = await httpClient.patch(
    `/proposals/rfp/${rfpId}/lane/${laneId}?partial=true`,
    lane
  )
  return data
}

export const remove = async ({
  rfpId,
  laneId,
}: {
  rfpId: number
  laneId?: number
}) => {
  if (!laneId) {
    return undefined
  }
  return httpClient.delete(`/proposals/rfp/${rfpId}/lane/${laneId}`)
}

export const create = async (rfpId: number, lane: Partial<Lane>) => {
  const { data } = await httpClient.post(`/proposals/rfp/${rfpId}/lane`, lane)
  return data
}

export const fetchRankScenarios = async (
  {
    rfpId,
    limit,
    offset,
    sort,
    carrierEntity,
    topRank,
  }: {
    rfpId: number | string
    limit: string | number
    offset: number | string
    sort: { column: string; direction: string }
    carrierEntity: string
    topRank: number | null
  },
  config?: AxiosRequestConfig
) => {
  type Params = { carrier_entity: string; top_rank?: number }

  let sortParam = sort.column
  let params: Params = { carrier_entity: carrierEntity }

  if (sort.direction === 'desc') {
    sortParam = `-${sort.column}`
  }

  if (topRank) {
    params = { ...params, top_rank: topRank }
  }

  const { data } = await httpClient.post(
    `/proposals/rank_scenarios/${rfpId}/lowest_bid?limit=${limit}&offset=${offset}&sort=${sortParam}`,
    params,
    config
  )

  return data
}

export const fetchAwardScenarios = async (
  {
    rfpId,
    limit,
    offset,
    sort,
    carrierEntity,
    carrierIncumbency,
    backupCarrierCount,
    maxSplitCarriers,
  }: {
    rfpId: number | string
    limit: string | number
    offset: number | string
    sort: { column: string; direction: string }
    carrierEntity: string
    carrierIncumbency: string
    backupCarrierCount: string
    maxSplitCarriers: string
  },
  config?: AxiosRequestConfig
) => {
  type Params = {
    carrier_entity: string
    carrier_incumbency: string
    backup_carrier_count?: string
    max_split_carriers?: number | string
  }

  let sortParam = sort.column
  let params: Params = {
    carrier_entity: carrierEntity,
    carrier_incumbency: carrierIncumbency,
  }

  if (sort.direction === 'desc') {
    sortParam = `-${sort.column}`
  }

  if (backupCarrierCount) {
    params = { ...params, backup_carrier_count: backupCarrierCount }
  }

  if (maxSplitCarriers) {
    params = { ...params, max_split_carriers: maxSplitCarriers }
  }

  const { data } = await httpClient.post(
    `/proposals/award_scenarios/${rfpId}/lowest_bid?limit=${limit}&offset=${offset}&sort=${sortParam}`,
    params,
    config
  )

  return data
}

export const updateAwardScenarios = async (params: {
  rfpId: number | string
  laneIds: Array<number | string>
  updateAll: boolean
  carrierEntity: string
  carrierIncumbency: string
  backupCarrierCount?: string
  maxSplitCarriers?: string
}) => {
  type Params = {
    award_all_lanes: boolean
    award_lane_ids: (string | number)[]
    carrier_entity: string
    carrier_incumbency: string
    backup_carrier_count?: string
    max_split_carriers?: string
  }

  const updateParams: Params = {
    award_all_lanes: params.updateAll ?? false,
    award_lane_ids: params.laneIds ?? [],
    carrier_entity: params.carrierEntity,
    carrier_incumbency: params.carrierIncumbency,
  }

  if (params.backupCarrierCount) {
    updateParams.backup_carrier_count = params.backupCarrierCount
  }

  if (params.maxSplitCarriers) {
    updateParams.max_split_carriers = params.maxSplitCarriers
  }

  const { data } = await httpClient.post(
    `/proposals/award_scenarios/${params.rfpId}/lowest_bid`,
    updateParams
  )

  return data
}

export const updateRankScenarios = async (params: {
  rfpId: number | string
  laneIds: Array<number | string>
  updateAll: boolean
  carrierEntity: string
  topRank: number | null
}) => {
  const { data } = await httpClient.post(
    `/proposals/rank_scenarios/${params.rfpId}/lowest_bid`,
    {
      award_all_lanes: params.updateAll ?? false,
      award_lane_ids: params.laneIds ?? [],
      carrier_entity: params.carrierEntity,
      rank_count: params.topRank,
    }
  )

  return data
}

export const removeFromRankScenarios = async (params: {
  rfpId: number | string
  laneIds: Array<number | string>
  updateAll: boolean
}) => {
  const { data } = await httpClient.post(
    `/proposals/rank_scenarios/${params.rfpId}/deallocate_bids`,
    {
      all_lanes: params.updateAll ?? false,
      lane_ids: params.laneIds ?? [],
    }
  )

  return data
}

export const removeFromAwardScenarios = async (params: {
  rfpId: number | string
  laneIds: Array<number | string>
  updateAll: boolean
}) => {
  const { data } = await httpClient.post(
    `/proposals/award_scenarios/${params.rfpId}/deallocate_bids`,
    {
      all_lanes: params.updateAll ?? false,
      lane_ids: params.laneIds ?? [],
    }
  )

  return data
}

/**
 * @TODO: Adjust the endpoint and the body
 */
export const fetchRankScenariosStats = async (
  rfpId: number | string,
  carrierEntity: string,
  topRank: number | null,
  config?: AxiosRequestConfig
) => {
  type Params = { carrier_entity: string; rank_count?: number }

  let params: Params = { carrier_entity: carrierEntity }

  if (topRank) {
    params = { ...params, rank_count: topRank }
  }

  const { data } = await httpClient.post(
    `/proposals/rank_scenarios/${rfpId}/lowest_bid_stats`,
    params,
    config
  )

  return data
}

export const fetchAwardScenariosStats = async (
  {
    rfpId,
    carrierEntity,
    carrierIncumbency,
    backupCarrierCount,
    maxSplitCarriers,
  }: {
    rfpId: number | string
    carrierEntity: string
    carrierIncumbency: string
    backupCarrierCount?: string
    maxSplitCarriers?: string
  },
  config?: AxiosRequestConfig
) => {
  type Params = {
    carrier_entity: string
    carrier_incumbency: string
    backup_carrier_count?: string
    max_split_carriers?: string | number
  }

  let params: Params = {
    carrier_entity: carrierEntity,
    carrier_incumbency: carrierIncumbency,
  }

  if (backupCarrierCount) {
    params = { ...params, backup_carrier_count: backupCarrierCount }
  }

  if (maxSplitCarriers) {
    params = { ...params, max_split_carriers: maxSplitCarriers }
  }

  const { data } = await httpClient.post(
    `/proposals/award_scenarios/${rfpId}/lowest_bid_stats`,
    params,
    config
  )
  return data
}

export const fetchPrimaryCarriers = async (
  rfpId: number | string,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get(
    `/proposals/award_scenarios/${rfpId}/list_distinct_carriers`,
    config
  )
  return data
}

export const fetchLaneBidStatusOptions = async (
  rfpId: number | string,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get<GenericApiSelectOptionWithCount[]>(
    `/proposals/bid_statuses?rfp_id=${rfpId}&allow_counts=true`,
    config
  )

  return data
}

export const fetchLaneEquipmentTypeOptions = async (
  rfpId: number | string,
  config?: Omit<AxiosRequestConfig, 'params'>
) => {
  const { data } = await httpClient.get<GenericApiSelectOptionWithCount[]>(
    `/proposals/equipment_types?rfp_id=${rfpId}&allow_counts=true`,
    config
  )

  return data
}
