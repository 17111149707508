import {
  Button,
  Text,
  Table,
  Layout,
  Tooltip,
  Icon,
  LabeledValue,
} from '@loadsmart/miranda-react'
import type { TextProps } from '@loadsmart/miranda-react'
import { useEffect } from 'react'

import { GlobalSpinner } from '_shared_/components/GlobalSpinner'
import { formatWeight } from 'components/ShippingItemsManager'
import StackableTableCell from 'orders/components/StackableTableCell'
import type {
  OrderValidationResult,
  OrderValidationResultStop,
} from 'orders/order-service'
import { formatOrderItemDescription } from 'orders/orders.utils'
import type { ListOrder } from 'orders/types'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'
import { formatDate } from 'utils/dateUtils'
import { plural } from 'utils/strings'

const formatStop = (stop?: OrderValidationResultStop) => {
  if (!stop) {
    return ''
  }

  return `${stop.city}, ${stop.state} ${stop.zipcode}`
}

const formatRoute = (stops?: OrderValidationResult['stops']) => {
  if (!stops?.length) {
    return '-'
  }

  return stops.map((stop) => formatStop(stop)).join(' → ')
}

type PickupDeliveryDatesProps = Readonly<{
  firstDate?: string | null
  lastDate?: string | null
  pickup?: boolean
}>

export const PickupDeliveryDates = ({
  firstDate,
  lastDate,
  pickup,
}: PickupDeliveryDatesProps) => {
  const label = pickup ? 'Pickup' : 'Delivery'

  if (!firstDate && !lastDate) {
    return (
      <LabeledValue
        inline
        label={`${label} date`}
        value="-"
        data-testid="selected-orders-summary-dates"
      />
    )
  }

  const formattedFirstDate = firstDate ? formatDate(firstDate) : ''
  const formattedLastDate = lastDate ? formatDate(lastDate) : ''

  if (
    !formattedFirstDate ||
    !formattedLastDate ||
    formattedFirstDate === formattedLastDate
  ) {
    return (
      <LabeledValue
        inline
        label={`${label} date`}
        value={formattedFirstDate || formattedLastDate}
        data-testid="selected-orders-summary-dates"
      />
    )
  }

  return (
    <LabeledValue
      inline
      label={`${label} dates`}
      value={`${formattedFirstDate} to ${formattedLastDate}`}
      data-testid="selected-orders-summary-dates"
    />
  )
}

interface ValidationResultSummaryProps extends Partial<OrderValidationResult> {
  readonly isLoading?: boolean
  readonly orderCount: number
}

const ValidationResultSummary = ({
  isLoading,
  first_delivery_date,
  first_pickup_date,
  last_delivery_date,
  last_pickup_date,
  orderCount,
  stops,
  warnings,
  weight,
}: ValidationResultSummaryProps) => {
  useEffect(() => {
    if (!isLoading) {
      analytics.track(
        AnalyticsEvent.NewShipmentOrdersValidation,
        AnalyticsEventTrigger.view,
        {
          warning_weight: !!warnings?.weight,
          warning_different_pu_del: warnings
            ? Object.keys(warnings).filter((key) => key !== 'weight').length > 0
            : false,
        }
      )
    }
  }, [isLoading, warnings])

  if (isLoading) {
    return (
      <Layout.Stack>
        <Layout.Box>
          <GlobalSpinner />
        </Layout.Box>
      </Layout.Stack>
    )
  }
  return (
    <Layout.Stack
      gap="spacing-2"
      data-testid="selected-orders-validation-result"
    >
      <Layout.Box padding="none">
        <h1>
          <Text variant="heading-sm">{formatRoute(stops)}</Text>
        </h1>
      </Layout.Box>

      <Layout.Group>
        <Layout.Box padding="none">
          <Text variant="body-md-bold">
            {`${orderCount} ${plural('order', orderCount)}`}
          </Text>
        </Layout.Box>

        <Layout.Box padding="none">
          <LabeledValue
            inline
            valueColor={
              warnings?.weight ? 'color-text-error' : 'color-text-primary'
            }
            label="Total weight"
            value={weight ? formatWeight(Number(weight)) : '-'}
            data-testid="selected-orders-summary-dates"
          />
        </Layout.Box>
      </Layout.Group>

      <Layout.Group>
        <PickupDeliveryDates
          firstDate={first_pickup_date}
          lastDate={last_pickup_date}
          pickup
        />

        <PickupDeliveryDates
          firstDate={first_delivery_date}
          lastDate={last_delivery_date}
        />
      </Layout.Group>

      <Layout.Box paddingX="none">
        <Text color="color-text-tertiary" variant="body-sm">
          The information can be edited in the next steps
        </Text>
      </Layout.Box>
    </Layout.Stack>
  )
}

interface OrderTableRowProps {
  readonly onRemoveOrderClick: (orderUUID: string) => void
  readonly order: ListOrder
  readonly warnings?: string[]
}

const TextWithWarning = ({
  warning,
  ...props
}: { readonly warning?: boolean } & TextProps) => {
  if (warning) {
    return <Text {...props} color="color-text-error" />
  }
  return <Text {...props} />
}

const OrderTableRow = ({
  onRemoveOrderClick,
  order,
  warnings,
}: OrderTableRowProps) => {
  const hasWarnings = !!warnings?.length

  return (
    <Table.Row key={order.uuid} data-testid={`validation-row-${order.uuid}`}>
      <Table.Cell>
        <Layout.Group align="center" gap="spacing-1" padding="none">
          <TextWithWarning warning={hasWarnings}>
            {order.primary_ref}
          </TextWithWarning>
          {hasWarnings && (
            <Tooltip
              placement="bottom-start"
              trigger="click,hover"
              message={warnings.join('. ')}
            >
              <Icon name="attention" color="color-text-error" />
            </Tooltip>
          )}
        </Layout.Group>
      </Table.Cell>

      <Table.Cell>
        <TextWithWarning warning={hasWarnings}>
          {formatOrderItemDescription(order.items[0])}
        </TextWithWarning>
      </Table.Cell>

      <Table.Cell>
        <StackableTableCell
          stackable={order.items[0].stackable}
          warning={hasWarnings}
        />
      </Table.Cell>

      <Table.Cell alignment="right">
        <TextWithWarning warning={hasWarnings}>
          {order.total_weight ? formatWeight(order.total_weight) : '-'}
        </TextWithWarning>
      </Table.Cell>

      <Table.Cell>
        <Button
          onClick={() => onRemoveOrderClick(order.uuid)}
          size="small"
          variant="icon"
          data-testid="validated-table-remove-order-btn"
        >
          <Icon name="close" title="Remove order" />
        </Button>
      </Table.Cell>
    </Table.Row>
  )
}

export interface ValidationResultProps {
  readonly isLoading?: boolean
  readonly orderSelection?: ListOrder[]
  readonly validationResult?: OrderValidationResult
  readonly handleRemoveOrders: (toBeRemoved: string | string[]) => void
}

export function ValidationResult({
  isLoading,
  orderSelection = [],
  validationResult,
  handleRemoveOrders,
}: ValidationResultProps) {
  return (
    <>
      <ValidationResultSummary
        {...(validationResult ?? {})}
        isLoading={isLoading}
        orderCount={orderSelection.length}
      />
      <Table data-testid="validated-orders-for-consolidation-table">
        <Table.Head>
          <Table.Row>
            <Table.Cell>Primary ref</Table.Cell>
            <Table.Cell>Units</Table.Cell>
            <Table.Cell />
            <Table.Cell alignment="right">Weight</Table.Cell>
            <Table.Cell style={{ width: '20px' }} />
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {orderSelection.map((order) => (
            <OrderTableRow
              key={order.uuid}
              onRemoveOrderClick={handleRemoveOrders}
              order={order}
              warnings={validationResult?.warnings?.[order.uuid]}
            />
          ))}
        </Table.Body>
      </Table>
    </>
  )
}
