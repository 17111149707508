import { useMutation } from 'react-query'

import { createFulfillment } from '../../fulfillment-service'

export function useCreateFulfillment() {
  return useMutation({
    mutationKey: ['createFulfillment'],
    mutationFn: createFulfillment,
  })
}
