import { IconTrash } from '@loadsmart/icons'
import { Button } from '@loadsmart/miranda-react'

import { useIndexerContext } from 'contexts/indexer'

import { useHandlingUnitsForm } from './HandlingUnitsForm.hooks'

type HandlingUnitsFormRemoveHandlingUnitProps = {
  readonly onClick?: () => void
}

export const HandlingUnitsFormRemoveHandlingUnit = ({
  onClick,
}: HandlingUnitsFormRemoveHandlingUnitProps) => {
  const [, dispatch] = useHandlingUnitsForm()
  const index = useIndexerContext()

  return (
    <Button
      aria-label="Remove item"
      title="Remove item"
      size="small"
      variant="icon"
      onClick={() => {
        onClick?.()
        dispatch({ type: 'REMOVE_ITEM', payload: { index } })
      }}
    >
      <IconTrash width={12} height={12} title={null} />
    </Button>
  )
}
