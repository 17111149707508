import { Select } from '@loadsmart/loadsmart-ui'
import { Field } from '@loadsmart/miranda-react'

import { COMMODITY_PACKAGE_TYPES_OPTIONS } from 'components/ShippingItemsManager/ShippingItems.constants'
import { getCommodityPackageTypeOption } from 'components/ShippingItemsManager/ShippingItemsForm.utils'
import { fromSelectOption } from 'utils/selectOption'

import type { CustomFormFieldProps } from '../HandlingUnits.types'
import { useCommodityFormField } from './CommoditiesForm.hooks'

export const CommodityPackageType = (props: CustomFormFieldProps) => {
  const { disabled, required, hint, style } = props
  const {
    controlProps,
    fieldProps,
    hintProps,
    index,
    labelProps,
    name,
    setValue,
    value,
  } = useCommodityFormField({ name: 'package_type', hint })

  return (
    <Field required={required} style={style} {...fieldProps}>
      <Field.Label {...labelProps}>Package</Field.Label>
      <Select
        getInputProps={() => controlProps}
        disabled={disabled || Boolean(controlProps.disabled)}
        placeholder="Select"
        id={`field-${index}-${name}`}
        name={name}
        options={COMMODITY_PACKAGE_TYPES_OPTIONS}
        value={getCommodityPackageTypeOption(value)}
        onChange={(event) => {
          const {
            target: { value: option },
          } = event

          const newValue = fromSelectOption(option as SelectOption)
          setValue(newValue)
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
