import { isEmpty, noop } from 'lodash'
import type { PropsWithChildren } from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import { AuditLogsDrawer } from 'components/AuditLogsDrawer'

export interface OrderHistoryContextValue {
  closeDrawer: () => void
  openDrawer: (id?: string) => void
  selectedOrderId?: string
}

export const OrderHistoryContext = createContext<OrderHistoryContextValue>({
  closeDrawer: noop,
  selectedOrderId: undefined,
  openDrawer: noop,
})

export function useOrderHistoryContext() {
  return useContext(OrderHistoryContext)
}

export function OrderHistoryDrawer() {
  const { closeDrawer, selectedOrderId } = useOrderHistoryContext()

  if (!selectedOrderId || isEmpty(selectedOrderId)) {
    return null
  }

  return (
    <AuditLogsDrawer
      isOpen
      kind="order"
      onClose={closeDrawer}
      queryOptions={{ enabled: true }}
      title="Order History"
      uuid={selectedOrderId}
    />
  )
}

export function OrderHistoryProvider({ children }: PropsWithChildren) {
  const [selectedOrderId, setSelectedOrderId] = useState<string | undefined>()

  const closeDrawer = useCallback(() => {
    setSelectedOrderId(undefined)
  }, [setSelectedOrderId])
  const openDrawer = useCallback(
    (id?: string) => {
      setSelectedOrderId(id)
    },
    [setSelectedOrderId]
  )

  const context = useMemo(
    () => ({ closeDrawer, openDrawer, selectedOrderId }),
    [closeDrawer, openDrawer, selectedOrderId]
  )

  return (
    <OrderHistoryContext.Provider value={context}>
      {children}
      <OrderHistoryDrawer />
    </OrderHistoryContext.Provider>
  )
}
