import { useCallback } from 'react'

import { useFilters } from 'utils/filters'

import type { GetOrdersFilters } from './useOrdersFiltersQueryParamPlugin'
import {
  getDefaultOrdersFilters,
  useOrdersFiltersQueryParamsPlugin,
} from './useOrdersFiltersQueryParamPlugin'
import type { GetOrdersPagination } from './useOrdersPaginationQueryParamsPlugin'
import {
  getDefaultOrdersPaginationParams,
  useOrdersPaginationQueryParamsPlugin,
} from './useOrdersPaginationQueryParamsPlugin'

type GetOrdersQueryParams = GetOrdersPagination & GetOrdersFilters

export function usePaginatedOrdersFilters() {
  const { setFilters, ...filters } = useFilters<GetOrdersQueryParams>(
    {
      initialValues: {
        ...getDefaultOrdersPaginationParams(),
        ...getDefaultOrdersFilters(),
      },
    },
    useOrdersPaginationQueryParamsPlugin,
    useOrdersFiltersQueryParamsPlugin
  )

  const clearFilters = useCallback(() => {
    setFilters({
      ...getDefaultOrdersPaginationParams(),
      ...getDefaultOrdersFilters(),
    })
  }, [setFilters])

  return { clearFilters, setFilters, ...filters }
}
