import { Field, TextField } from '@loadsmart/miranda-react'
import { toCapitalizeFirstLetter } from '@loadsmart/utils-string'

import { hasStandardPalletDimmensions } from 'components/ShippingItemsManager'
import { numberFormatter } from 'utils/numbers'

import type { CustomFormFieldProps } from '../HandlingUnits.types'
import { useHandlingUnitFormField } from './HandlingUnitsForm.hooks'

export const HandlingUnitDimension = (
  props: CustomFormFieldProps & {
    readonly dimension: 'length' | 'height' | 'width'
  }
) => {
  const { required, hint, style, dimension } = props
  const {
    controlProps,
    fieldProps,
    hintProps,
    item,
    labelProps,
    name,
    setItem,
    value,
  } = useHandlingUnitFormField({ name: dimension, hint })

  return (
    <Field {...fieldProps} required={required} style={style}>
      <Field.Label {...labelProps}>
        {toCapitalizeFirstLetter(dimension)}
      </Field.Label>
      <TextField
        {...controlProps}
        placeholder="inches"
        inputMode="numeric"
        type="number"
        min="1"
        value={value ? numberFormatter(value) : ''}
        onChange={(event) => {
          const {
            target: { value: newValue },
          } = event

          if (
            item.package_type === 'std_pallets' &&
            !hasStandardPalletDimmensions({ ...item, [name]: newValue })
          ) {
            // Standard pallets should have specific dimensions
            setItem({
              [name]: newValue,
              package_type: 'pallets_non_std',
            })

            return
          }

          setItem({ [name]: newValue })
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
