import { Layout, Tag } from '@loadsmart/miranda-react'
import { Fragment } from 'react'

import {
  formatOrderItemStackable,
  formatOrderItemTurnable,
} from 'orders/orders.utils'
import type { ListOrderItem, OrderItem } from 'orders/types'

export function OrderItemTags({
  item,
  wrapInGroup = true,
}: Readonly<{ item: OrderItem | ListOrderItem; wrapInGroup?: boolean }>) {
  const Wrapper = wrapInGroup ? Layout.Group : Fragment

  return (
    <Wrapper>
      <Tag size="small" variant="neutral">
        {formatOrderItemStackable(item)}
      </Tag>

      <Tag size="small" variant="neutral">
        {formatOrderItemTurnable(item)}
      </Tag>
    </Wrapper>
  )
}
