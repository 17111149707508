import type { Customer } from 'fulfillments/domain/Customer'
import { getCustomers } from 'fulfillments/fulfillment-service'
import { useQuery } from 'react-query'

import { FulfillmentSelect } from './FulfillmentSelect'

const customersToOptions = (customers: Customer[]) => {
  return customers.map((customer) => ({
    label: customer.name,
    value: customer.uuid,
  }))
}

export function FulfillmentCustomer() {
  const { data, isLoading } = useQuery({
    queryKey: ['get-customers'],
    queryFn: getCustomers,
    cacheTime: 60000,
    select: customersToOptions,
  })

  return (
    <FulfillmentSelect
      name="customer_uuid"
      label="Customer"
      required={true}
      placeholder="Select customer"
      loading={isLoading}
      options={data}
    />
  )
}
