import { DateRangePicker, Select } from '@loadsmart/loadsmart-ui'
import type { GenericOption } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'
import type { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import type EventLike from '@loadsmart/loadsmart-ui/dist/utils/types/EventLike'
import { Drawer, Field, Layout } from '@loadsmart/miranda-react'
import type { FetchLocationOptions } from '@loadsmart/react-location-select'
import { defaultTo, noop } from 'lodash'
import type { PropsWithChildren } from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import { LocationSelect } from 'components/LocationSelect'
import { useSelectFacilitySearch } from 'hooks/useSelectFacilitySearch'
import { formatOrderDateFilters } from 'orders/orders.utils'
import type { Order } from 'orders/types'
import {
  formatLocationForSelect,
  getLocationDisplayInformation,
} from 'screens/Quotes/List/utils'

import type {
  GetOrdersFilters,
  FilterValues,
} from '../hooks/useOrdersFiltersQueryParamPlugin'
import { getDefaultOrdersFilters } from '../hooks/useOrdersFiltersQueryParamPlugin'
import { OperatorAndValueFilter, SelectedItemsTags } from './Filters.components'
import {
  DEFAULT_OP_VALUE,
  getLocationOptionKey,
  removeOpsWithoutValues,
  STATUS_SLUG_TO_LABEL,
} from './Filters.utils'

const FACILITY_DATASOURCES = [useSelectFacilitySearch]
const LOCATION_CONFIG: FetchLocationOptions = {
  types: 'regions',
  fields: ['address_components', 'place_id'],
  restrictions: { country: ['us', 'ca', 'mx'] },
}
const STATUSES = Object.entries(STATUS_SLUG_TO_LABEL).map(([value, label]) => ({
  value: value as Order['status'],
  label,
}))

interface FiltersDrawerContentProps {
  readonly clearFilters: () => void
  readonly closeFiltersDrawer: () => void
  readonly filters: FilterValues
  readonly hideStatus?: boolean
  readonly setFilters: (newFilters: FilterValues) => void
}

export function FiltersDrawerContent({
  clearFilters,
  closeFiltersDrawer,
  filters: currentFilters,
  hideStatus = false,
  setFilters: applyFilters,
}: FiltersDrawerContentProps) {
  const [filters, setFilters] = useState<GetOrdersFilters>(
    getDefaultOrdersFilters({
      ...currentFilters,
      package_count_op: defaultTo(
        currentFilters?.package_count_op,
        DEFAULT_OP_VALUE
      ),
      total_volume_op: defaultTo(
        currentFilters?.total_volume_op,
        DEFAULT_OP_VALUE
      ),
      total_weight_op: defaultTo(
        currentFilters?.total_weight_op,
        DEFAULT_OP_VALUE
      ),
    })
  )

  const unselectItem = useCallback(
    (field: keyof GetOrdersFilters, itemIndex: number) => {
      if (!Array.isArray(filters[field])) {
        return
      }

      const updatedField = filters[field]?.filter(
        (_, valIndex) => valIndex !== itemIndex
      )
      setFilters((prevFormData) => ({
        ...prevFormData,
        [field]: updatedField,
      }))
    },
    [filters, setFilters]
  )

  const handleChange = useCallback(
    (
      event:
        | EventLike<Selectable | Selectable[] | null>
        | EventLike<[string | null, string | null] | null>
    ) => {
      const { name, value } = event.target

      setFilters((prevFormData) => ({ ...prevFormData, [name]: value }))
    },
    [setFilters]
  )

  const handleStatusChange = useCallback(
    (
      event:
        | EventLike<Selectable | Selectable[] | null>
        | EventLike<[string | null, string | null] | null>
    ) => {
      const { value } = event.target

      if (Array.isArray(value)) {
        const values = value?.map((item) =>
          String((item as GenericOption).value)
        )

        setFilters((prevFormData) => ({
          ...prevFormData,
          status: defaultTo(values, null),
        }))
      }
    },
    [setFilters]
  )

  const handleDateRangeChange = useCallback(
    (event: EventLike<[string | null, string | null] | null>) => {
      const { name, value } = event.target
      if (!Array.isArray(value)) {
        return
      }
      const [date_after, date_before] = value

      if (name === 'pickup_date_range') {
        setFilters((previousFilterValues) => ({
          ...previousFilterValues,
          pickup_date_after: formatOrderDateFilters(
            defaultTo(date_after, null)
          ),
          pickup_date_before: formatOrderDateFilters(
            defaultTo(date_before, null)
          ),
        }))
      } else if (name === 'delivery_date_range') {
        setFilters((previousFilterValues) => ({
          ...previousFilterValues,
          delivery_date_after: formatOrderDateFilters(
            defaultTo(date_after, null)
          ),
          delivery_date_before: formatOrderDateFilters(
            defaultTo(date_before, null)
          ),
        }))
      }
    },
    [setFilters]
  )

  const handleApplyClick = useCallback(() => {
    applyFilters(removeOpsWithoutValues(filters))
    closeFiltersDrawer()
  }, [applyFilters, closeFiltersDrawer, filters])

  const handleClearFiltersClick = useCallback(() => {
    setFilters(getDefaultOrdersFilters())
    clearFilters()
  }, [clearFilters, setFilters])

  const handleUpdateFilters = useCallback(
    (newFilters: Partial<GetOrdersFilters> = {}) => {
      setFilters((previousFilterValues) => ({
        ...previousFilterValues,
        ...newFilters,
      }))
    },
    [setFilters]
  )

  const selectedPickupLocations = defaultTo(filters?.pickup_location, []).map(
    formatLocationForSelect
  )

  const selectedDeliveryLocations = defaultTo(
    filters?.delivery_location,
    []
  ).map(formatLocationForSelect)

  return (
    <Drawer
      onClose={closeFiltersDrawer}
      open
      size="medium"
      data-testid="filters"
    >
      <Drawer.Header>Apply Filters</Drawer.Header>
      <Drawer.Body>
        <Layout.Stack>
          {!hideStatus && (
            <Layout.Stack gap="spacing-2">
              <Field>
                <Field.Label id="status">Status</Field.Label>
                <Select
                  multiple
                  options={STATUSES}
                  name="statuses"
                  id="statuses"
                  placeholder="Select status"
                  disabled={hideStatus}
                  onChange={handleStatusChange}
                  value={
                    filters.status?.map((value) => ({
                      lable: STATUS_SLUG_TO_LABEL[value as Order['status']],
                      value,
                    })) as Selectable[]
                  }
                />
                <SelectedItemsTags
                  items={defaultTo(filters.status, [])}
                  getItemKey={(item) => item}
                  onRemoveItem={(index) => unselectItem('status', index)}
                  getLabel={(item) =>
                    STATUS_SLUG_TO_LABEL[item as Order['status']]
                  }
                />
              </Field>
            </Layout.Stack>
          )}

          <Layout.Stack gap="spacing-2" data-testid="filter-pickup-location">
            <Field>
              <Field.Label id="pickup_location">Pickup Location</Field.Label>
              <LocationSelect
                multiple
                id="pickup_location"
                name="pickup_location"
                config={LOCATION_CONFIG}
                datasources={FACILITY_DATASOURCES}
                onChange={handleChange}
                placeholder="Select pickup addresses, zipcode or facility name"
                value={selectedPickupLocations}
              />
              <SelectedItemsTags
                getItemKey={getLocationOptionKey}
                getLabel={(location) => getLocationDisplayInformation(location)}
                items={selectedPickupLocations}
                onRemoveItem={(index) => unselectItem('pickup_location', index)}
              />
            </Field>
          </Layout.Stack>

          <Layout.Stack gap="spacing-1" data-testid="filter-pickup-dates">
            <Field>
              <Field.Label id="pickup_date_range">
                Pickup date range
              </Field.Label>
              <DateRangePicker
                id="pickup_date_range"
                name="pickup_date_range"
                getRangeStartInputProps={() => ({
                  'aria-label': 'Select the start date of the range',
                })}
                getRangeEndInputProps={() => ({
                  'aria-label': 'Select the end date of the range',
                })}
                onChange={handleDateRangeChange}
                value={[filters.pickup_date_after, filters.pickup_date_before]}
              />
            </Field>
          </Layout.Stack>

          <Layout.Stack gap="spacing-2" data-testid="filter-delivery-location">
            <Field>
              <Field.Label id="delivery_location">
                Delivery Location
              </Field.Label>
              <LocationSelect
                multiple
                id="delivery_location"
                name="delivery_location"
                placeholder="Select delivery addresses, zipcode or facility name"
                value={selectedDeliveryLocations}
                datasources={FACILITY_DATASOURCES}
                config={LOCATION_CONFIG}
                onChange={handleChange}
              />
              <SelectedItemsTags
                getItemKey={getLocationOptionKey}
                getLabel={(location) => getLocationDisplayInformation(location)}
                items={selectedDeliveryLocations}
                onRemoveItem={(index) =>
                  unselectItem('delivery_location', index)
                }
              />
            </Field>
          </Layout.Stack>

          <Layout.Stack gap="spacing-1" data-testid="filter-delivery-dates">
            <Field>
              <Field.Label id="delivery_date_range">
                Delivery date range
              </Field.Label>
              <DateRangePicker
                id="delivery_date_range"
                name="delivery_date_range"
                getRangeStartInputProps={() => ({
                  'aria-label': 'Select the start date of the range',
                })}
                getRangeEndInputProps={() => ({
                  'aria-label': 'Select the end date of the range',
                })}
                onChange={handleDateRangeChange}
                value={[
                  filters.delivery_date_after,
                  filters.delivery_date_before,
                ]}
              />
            </Field>
          </Layout.Stack>

          <Layout.Stack gap="spacing-1" data-testid="filter-package-count">
            <Field>
              <Field.Label id="package_count">Piece count</Field.Label>
              <OperatorAndValueFilter
                field="package_count"
                filters={filters}
                inputPlaceholder="Piece count"
                setFilters={handleUpdateFilters}
                trailing="units"
              />
            </Field>
          </Layout.Stack>

          <Layout.Stack gap="spacing-1" data-testid="filter-total-weight">
            <Field>
              <Field.Label id="total_weight">Total weight</Field.Label>
              <OperatorAndValueFilter
                field="total_weight"
                filters={filters}
                inputPlaceholder="Total weight"
                setFilters={handleUpdateFilters}
                trailing="lbs"
              />
            </Field>
          </Layout.Stack>

          <Layout.Stack gap="spacing-1" data-testid="filter-total-weight">
            <Field>
              <Field.Label id="total_volume">Total volume</Field.Label>
              <OperatorAndValueFilter
                field="total_volume"
                filters={filters}
                inputPlaceholder="Total volume"
                setFilters={handleUpdateFilters}
                trailing="ft³"
              />
            </Field>
          </Layout.Stack>
        </Layout.Stack>
      </Drawer.Body>
      <Drawer.Actions>
        <Layout.Group justify="space-between" style={{ width: '100%' }}>
          <Layout.Group gap="spacing-4">
            <Drawer.ActionPrimary
              data-testid="apply-filters"
              onClick={handleApplyClick}
              variant="primary"
              type="button"
            >
              Apply Filters
            </Drawer.ActionPrimary>
            <Drawer.ActionSecondary type="button" onClick={closeFiltersDrawer}>
              Close
            </Drawer.ActionSecondary>
          </Layout.Group>
          <Drawer.ActionSecondary
            data-testid="clear-filters"
            onClick={handleClearFiltersClick}
            type="button"
            variant="tertiary"
          >
            Clear Filters
          </Drawer.ActionSecondary>
        </Layout.Group>
      </Drawer.Actions>
    </Drawer>
  )
}

export interface FiltersDrawerProps {
  readonly clearFilters: () => void
  readonly closeFiltersDrawer: () => void
  readonly filters: FilterValues
  readonly hideStatus?: boolean
  readonly isFiltersDrawerOpen: boolean
  readonly setFilters: (filters: FilterValues) => void
}

export function FiltersDrawer({
  clearFilters,
  closeFiltersDrawer,
  filters,
  hideStatus,
  isFiltersDrawerOpen,
  setFilters,
}: FiltersDrawerProps) {
  if (isFiltersDrawerOpen) {
    return (
      <FiltersDrawerContent
        clearFilters={clearFilters}
        closeFiltersDrawer={closeFiltersDrawer}
        filters={filters}
        hideStatus={hideStatus}
        setFilters={setFilters}
      />
    )
  }

  return null
}

interface ContextValue {
  closeFilters: () => void
  openFilters: () => void
}

const Context = createContext<ContextValue>({
  closeFilters: noop,
  openFilters: noop,
})

export function useOrdersFiltersContext() {
  return useContext(Context)
}

export interface OrdersFiltersProviderProps extends PropsWithChildren {
  readonly clearFilters: () => void
  readonly filters: FilterValues
  readonly setFilters: (newFilters: FilterValues) => void
}

export function OrdersFiltersProvider({
  children,
  clearFilters,
  filters,
  setFilters,
}: OrdersFiltersProviderProps) {
  const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = useState(false)
  const openFilters = useCallback(() => {
    setIsFiltersDrawerOpen(true)
  }, [setIsFiltersDrawerOpen])
  const closeFilters = useCallback(() => {
    setIsFiltersDrawerOpen(false)
  }, [setIsFiltersDrawerOpen])

  const contextValue = useMemo(
    () => ({ closeFilters, openFilters }),
    [closeFilters, openFilters]
  )

  return (
    <Context.Provider value={contextValue}>
      {children}

      <FiltersDrawer
        clearFilters={clearFilters}
        closeFiltersDrawer={closeFilters}
        filters={filters}
        isFiltersDrawerOpen={isFiltersDrawerOpen}
        setFilters={setFilters}
      />
    </Context.Provider>
  )
}
