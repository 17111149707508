import type { ReactNode } from 'react'
import { createContext, useContext, useMemo } from 'react'

import useLocalStorageState from 'hooks/useLocalStorageState'

import type { IdentifierColumns } from './ListOrders.types'

export type OrdersListContextValue = {
  identifier: IdentifierColumns
  onIdentifierChange: (identifier: IdentifierColumns) => void
}

export const OrdersListContext = createContext<
  OrdersListContextValue | undefined
>(undefined)

export function useOrdersListContext() {
  const context = useContext(OrdersListContext)

  if (context === undefined) {
    throw new Error(
      'useOrdersListContext must be used within a OrdersListProvider.Provider'
    )
  }

  return context
}

export function useOrderListProviderState() {
  const [identifier, onIdentifierChange] =
    useLocalStorageState<IdentifierColumns>('order-list-id-column-2', {
      initialValue: 'primary_ref',
    })

  const value = useMemo(
    () => ({
      identifier,
      onIdentifierChange,
    }),
    [identifier, onIdentifierChange]
  )

  return value
}

export const OrdersListProvider = ({
  children,
}: {
  readonly children?: ReactNode
}) => {
  const value = useOrderListProviderState()

  return (
    <OrdersListContext.Provider value={value}>
      {children}
    </OrdersListContext.Provider>
  )
}
