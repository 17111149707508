import { Card, Divider, Layout, Tag } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { useFulfillmentFormContext } from 'fulfillments/create/CreateFulfillmentContext'
import { get } from 'lodash'

import { FacilitySummary } from 'components/FacilityDetails'
import type { TransientHandlingUnit } from 'components/HandlingUnitsManager/HandlingUnits.types'
import type { TransientStop } from 'components/StopsManager'
import {
  createTransientStop,
  StopForm,
  StopsForm,
} from 'components/StopsManager'
import { StopFacilitySummary } from 'components/StopsManager/StopFacilitySummary'
import { REQUIRED_FIELD_MESSAGE } from 'constants/errors'
import { getTransientError, getTransientErrorsCount } from 'utils/transient'

import { FulfillmentHandlingUnit } from './FulfillmentHandlingUnit'
import { FulfillmentStop } from './FulfillmentStop'

export function FulfillmentLane() {
  const { fulfillment, setPartialFulfillment } = useFulfillmentFormContext()

  const errorsCount = getTransientErrorsCount(fulfillment.stops)

  function handleStopChange(stops: TransientStop[]) {
    setPartialFulfillment({ stops })
  }

  const handleHandlingUnitChange = (
    newHandlingUnits: TransientHandlingUnit[]
  ) => {
    setPartialFulfillment({
      handling_units: newHandlingUnits,
    })
  }

  const [pickup, delivery] = get(fulfillment, 'stops', [])
  const oneItemRequiredError =
    getTransientError(fulfillment, 'items') == REQUIRED_FIELD_MESSAGE

  return (
    <StopsForm
      stops={fulfillment.stops}
      createStop={createTransientStop}
      onChange={handleStopChange}
    >
      <Card id="fulfillment-lane">
        <Card.Title>
          <Layout.Group align="center" padding="none" gap="spacing-1">
            Lane
            {errorsCount > 0 && (
              <Tag variant="danger" size="small">
                {errorsCount} {errorsCount > 1 ? 'Errors' : 'Error'}
              </Tag>
            )}
          </Layout.Group>
        </Card.Title>
        <Card.Divider />
        <Card.Body>
          <Layout.Stack>
            <StopForm index={0}>
              <Layout.Group data-testid="pickup-stop">
                {/* TODO - Implement customer's facilitiy field */}
                {fulfillment.owner == 'us' && (
                  <StopForm.Facility
                    label="Pickup"
                    required
                    style={{ flex: 1 }}
                  />
                )}

                <StopForm.Date label="Pickup-ready date" required />
              </Layout.Group>
            </StopForm>
            <StopForm index={fulfillment.stops.length - 1}>
              <Layout.Group data-testid="delivery-stop">
                {/* TODO - Implement customer's facilitiy field */}
                {fulfillment.owner == 'us' && (
                  <StopForm.Facility
                    label="Delivery"
                    required
                    style={{ flex: 1 }}
                  />
                )}

                <StopForm.Date label="Delivery date" required />
              </Layout.Group>
            </StopForm>
          </Layout.Stack>
        </Card.Body>
      </Card>
      {Boolean(pickup.facility) && (
        <FulfillmentStop
          key={`${pickup._metadata.id}-${0}`}
          id="pickup"
          stop={pickup}
          index={0}
          totalStops={2}
          initialCollapsedState={false}
        >
          <StopFacilitySummary
            stop={pickup}
            style={{
              margin: 0,
              borderTopWidth: 0,
              borderBottomColor: toCSSValue('color-divider'),
              borderBottomWidth: toCSSValue('border-thin'),
            }}
          />
          {/* TODO - Make this section only available for "us" ownership */}
          <StopForm key={pickup._metadata.id} index={0}>
            <Layout.Stack gap="spacing-4" padding="spacing-6">
              <StopForm.Contact required />
              <StopForm.Notes />
            </Layout.Stack>
          </StopForm>
          <FulfillmentHandlingUnit
            hasOneItemRequiredError={oneItemRequiredError}
            handlingUnits={fulfillment.handling_units || []}
            onHandlingUnitChange={handleHandlingUnitChange}
            stops={[pickup, delivery]}
          />
          <Divider />
          <Layout.Group padding="spacing-6">
            <FacilitySummary facility={pickup.facility}>
              <FacilitySummary.EditButton />
            </FacilitySummary>
          </Layout.Group>
        </FulfillmentStop>
      )}
      {Boolean(delivery.facility) && (
        <FulfillmentStop
          key={`${delivery._metadata.id}-${1}`}
          id="delivery"
          stop={delivery}
          index={1}
          totalStops={2}
          initialCollapsedState={false}
        >
          <StopFacilitySummary
            stop={delivery}
            style={{
              margin: 0,
              borderTopWidth: 0,
              borderBottomColor: toCSSValue('color-divider'),
              borderBottomWidth: toCSSValue('border-thin'),
            }}
          />
          {/* TODO - Make this section only available for "us" ownership */}
          <StopForm key={delivery._metadata.id} index={1}>
            <Layout.Stack gap="spacing-4" padding="spacing-6">
              <StopForm.Contact required />
              <StopForm.Notes />
            </Layout.Stack>
          </StopForm>

          <Divider />
          <Layout.Group padding="spacing-6">
            <FacilitySummary facility={delivery.facility}>
              <FacilitySummary.EditButton />
            </FacilitySummary>
          </Layout.Group>
        </FulfillmentStop>
      )}
    </StopsForm>
  )
}
