import { Themes } from '@loadsmart/loadsmart-ui/dist/theming'
import * as Sentry from '@sentry/react'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { UserProvider } from '_shared_/user/UserProvider'
import BeamerLoader from 'components/BeamerLoader'
import { EventBasedContactsManagerDrawer } from 'components/ContactsDrawer/EventBasedContactsManagerDrawer'
import {
  EventBasedCreateFacilityDrawer,
  EventBasedFacilityManagerDrawer,
} from 'components/FacilityDrawer'
import { Toast } from 'components/ToastContainer'
import { SettingsProvider } from 'contexts/settings'
import { Router } from 'router/Router'
import { GlobalStyle } from 'styles/global'
import { MirandaCompatFix, MirandaOverrides, theme } from 'styles/theme'
import queryClient from 'utils/queryClient'

import '@loadsmart/miranda-react/dist/styles/variables.css'
import '@loadsmart/strangercodes/style.css'
import 'react-datepicker/dist/react-datepicker.min.css'
import 'react-toastify/dist/ReactToastify.css'

function App() {
  return (
    <Sentry.ErrorBoundary fallback={<div>An error has occurred</div>}>
      <BrowserRouter>
        <ThemeProvider
          theme={{
            ...Themes.Miranda,
            ...theme,
            ...MirandaCompatFix,
            ...MirandaOverrides,
          }}
        >
          <Toast />
          <GlobalStyle />
          <QueryClientProvider client={queryClient}>
            <UserProvider>
              <SettingsProvider>
                <EventBasedCreateFacilityDrawer />
                <EventBasedFacilityManagerDrawer />
                <EventBasedContactsManagerDrawer />
                <Router />
                <ReactQueryDevtools initialIsOpen={false} />
                <BeamerLoader />
              </SettingsProvider>
            </UserProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  )
}

export default App
