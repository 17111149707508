import { PageContent } from '@loadsmart/miranda-react'
import { useParams } from 'react-router-dom'

import { WindowTitle } from '_shared_/components/WindowTitle'
import { OrderHistoryProvider } from 'orders/components/OrderHistory'
import { MirandaPageGlobalStyle } from 'styles/global'

import { ViewOrderPageHeader } from './components/ViewOrderPageHeader'
import {
  useViewOrderContextData,
  ViewOrderContextProvider,
} from './ViewOrderContext'
import { ViewOrderDetails } from './ViewOrderDetails'

export default function ViewOrderPage() {
  const { id } = useParams<{ id: string }>()
  const data = useViewOrderContextData(id)

  return (
    <>
      <WindowTitle title="Order Details | ShipperGuide" />
      <ViewOrderContextProvider {...data}>
        <OrderHistoryProvider>
          <MirandaPageGlobalStyle />
          <PageContent>
            <ViewOrderPageHeader />
          </PageContent>

          <PageContent.Body>
            <ViewOrderDetails />
          </PageContent.Body>
        </OrderHistoryProvider>
      </ViewOrderContextProvider>
    </>
  )
}
