import type { ListOrder } from 'orders/types'

export type OneClickAvailableModes = 'FTL' | 'LTL'
export const ONE_CLICK_AVAILABLE_MODES: OneClickAvailableModes[] = [
  'FTL',
  'LTL',
]

export type OrderMapPerMode = Record<
  OneClickAvailableModes,
  Record<'suitable' | 'unsuitable', ListOrder[]>
> & { alreadyPlanned: ListOrder[]; incompatibleModes: ListOrder[] }
