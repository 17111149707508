import type { AxiosRequestConfig } from 'axios'
import { defaultTo } from 'lodash'

import type { PackageType } from 'components/ShippingItemsManager'
import type { MODES } from 'utils/constants'
import { formatDate } from 'utils/dateUtils'
import httpClient from 'utils/httpClient'
import { createSearchParams } from 'utils/searchParams'

import type { ListOrder, Order } from './types'

export const DEFAULT_LIMIT = 50
export const MAX_LIMIT = 500
export const DEFAULT_OFFSET = 0

type ListOrdersParams = {
  limit: number
  offset: number
  search_term: string | null
  delivery_date_after: string | null
  delivery_date_before: string | null
  delivery_location: string | null
  package_count?: string | null
  package_count_lt?: string | null
  package_count_lte?: string | null
  package_count_gt?: string | null
  package_count_gte?: string | null
  pickup_date_after: string | null
  pickup_date_before: string | null
  pickup_location: string | null
  status: string[] | null
  total_volume?: string | null
  total_volume_lt?: string | null
  total_volume_lte?: string | null
  total_volume_gt?: string | null
  total_volume_gte?: string | null
  total_weight?: string | null
  total_weight_lt?: string | null
  total_weight_lte?: string | null
  total_weight_gt?: string | null
  total_weight_gte?: string | null
  uuid?: string | null
}

export function getListOrdersParams(
  params: Partial<ListOrdersParams> = {}
): ListOrdersParams {
  return {
    limit: defaultTo(params?.limit, DEFAULT_LIMIT),
    offset: defaultTo(params?.offset, DEFAULT_OFFSET),
    search_term: defaultTo(params?.search_term, null),
    delivery_date_after: defaultTo(params?.delivery_date_after, null),
    delivery_date_before: defaultTo(params?.delivery_date_before, null),
    delivery_location: defaultTo(params?.delivery_location, null),
    pickup_date_after: defaultTo(params?.pickup_date_after, null),
    pickup_date_before: defaultTo(params?.pickup_date_before, null),
    pickup_location: defaultTo(params?.pickup_location, null),
    package_count: defaultTo(params?.package_count, null),
    package_count_lt: defaultTo(params?.package_count_lt, null),
    package_count_lte: defaultTo(params?.package_count_lte, null),
    package_count_gt: defaultTo(params?.package_count_gt, null),
    package_count_gte: defaultTo(params?.package_count_gte, null),
    status: defaultTo(params?.status, null),
    total_volume: defaultTo(params?.total_volume, null),
    total_volume_lt: defaultTo(params?.total_volume_lt, null),
    total_volume_lte: defaultTo(params?.total_volume_lte, null),
    total_volume_gt: defaultTo(params?.total_volume_gt, null),
    total_volume_gte: defaultTo(params?.total_volume_gte, null),
    total_weight: defaultTo(params?.total_weight, null),
    total_weight_lt: defaultTo(params?.total_weight_lt, null),
    total_weight_lte: defaultTo(params?.total_weight_lte, null),
    total_weight_gt: defaultTo(params?.total_weight_gt, null),
    total_weight_gte: defaultTo(params?.total_weight_gte, null),
    uuid: defaultTo(params?.uuid, null),
  }
}

export async function listOrders(
  params: ListOrdersParams,
  config?: Omit<AxiosRequestConfig, 'params'>
): Promise<PaginatedResult<ListOrder>> {
  const { data } = await httpClient.get('orders', {
    ...config,
    params: createSearchParams(params),
  })
  return data
}

type ListOrdersAsSupplierParams = {
  limit: number
  offset: number
}

export async function listOrdersAsSupplier(
  params: ListOrdersAsSupplierParams,
  config?: Omit<AxiosRequestConfig, 'params'>
) {
  const { data } = await httpClient.get('/orders/orders-as-supplier', {
    ...config,
    params: createSearchParams(params),
  })
  return data
}

export async function getOrderDetails(
  id: string,
  config?: AxiosRequestConfig
): Promise<Order> {
  const { data } = await httpClient.get(`orders/${id}`, config)
  return data
}

export async function deleteOrder(id: string) {
  return await httpClient.delete(`orders/${id}`)
}

type ValidateOrdersForConsolidationParams = {
  orderUUIDs: string[]
}

export type OrderValidationResultStop = {
  city: string
  state: string
  country: string
  address: string
  zipcode: string
  facility_uuid: string
}

export type OrderValidationResult = {
  uuid: string
  weight: number
  first_pickup_date: string | null
  last_pickup_date: string | null
  first_delivery_date: string | null
  last_delivery_date: string | null
  stops: OrderValidationResultStop[]
  warnings: Record<string, string[]>
}

export async function validateOrdersForConsolidation(
  { orderUUIDs }: ValidateOrdersForConsolidationParams,
  options?: AxiosRequestConfig
): Promise<OrderValidationResult> {
  const { data } = await httpClient.post(
    'orders/plan/validate',
    orderUUIDs,
    options
  )
  return data
}

export type OrderStop = {
  city: string
  state: string
  country: string
  address: string
  zipcode: string
  latitude: string
  longitude: string
  facilityName: string
  facilityUUID: string
  stopType: 'pickup' | 'delivery'
}

export type OrderPlanPreviewStop = OrderStop & {
  stopLabel: string
  pickupIndex?: number
  pickupOrderUUIDs: string[]
  deliveryIndex?: number
  deliveryOrderUUIDs: string[]
  canMoveUp?: boolean
  canMoveDown?: boolean
}

export type OrderPlanPreview = {
  firstDeliveryDate: string | null
  firstPickupDate: string | null
  lastDeliveryDate: string | null
  lastPickupDate: string | null
  packageCount: number | null
  packageType: PackageType | null
  stops: OrderPlanPreviewStop[]
  stopsMap: Record<string, OrderPlanPreviewStop>
  totalWeight: number
  warnings: {
    facilityWithPickupAndDelivery?: boolean
    itemsWithDifferentPackaging?: boolean
    ordersWithoutWeight?: boolean
    totalWeightOverLimit?: boolean
  }
}

export type TruckMileageStop = {
  postal_code?: string | null
  latitude?: number | null
  longitude?: number | null
}

export type TruckMileageResponse = {
  total_miles: number
  stops_mileages: number[]
}

export async function getTruckMileage(
  stops: TruckMileageStop[],
  config?: AxiosRequestConfig
): Promise<TruckMileageResponse> {
  const { data } = await httpClient.post(
    'compass/truck-mileage',
    { stops },
    config
  )

  return data
}

export function mapListOrderToCreateShipmentFromSingleOrderRequest(
  mode: MODES,
  order?: ListOrder | null
): CreateShipmentFromSingleOrderRequest | null {
  if (!order) {
    return null
  }

  return {
    equipment_type: order.equipment_type,
    mode,
    provider: 'ONE_CLICK',
    orders: [
      {
        id: order.uuid,
        delivery_stop_index: 1,
        pickup_stop_index: 0,
      },
    ],
    shipper_ref_number: order.primary_ref,
    stops: [
      {
        stop_index: 0,
        date: formatDate(
          defaultTo(order.pickup_window_start, ''),
          'YYYY-MM-DD'
        ),
        stop_type: 'pickup',
      },
      {
        stop_index: 1,
        date: formatDate(
          defaultTo(order.delivery_window_start, ''),
          'YYYY-MM-DD'
        ),
        stop_type: 'delivery',
      },
    ],
  }
}

export interface CreateShipmentFromSingleOrderRequest {
  equipment_length?: string
  equipment_type: string
  mode: MODES
  provider: 'ONE_CLICK'
  orders: {
    id: string
    delivery_stop_index: number
    pickup_stop_index: number
  }[]
  shipper_ref_number: string
  stops: {
    date: string // format: "2023-10-01"
    expected_arrival?: string
    expected_departure?: string
    stop_index: number
    stop_type: 'delivery' | 'pickup'
  }[]
}

export interface CreateShipmentFromSingleOrderResponse {
  shipment_uuid: string
}

export async function createShipmentFromSingleOrder(
  payload: CreateShipmentFromSingleOrderRequest
): Promise<CreateShipmentFromSingleOrderResponse> {
  const { data } = await httpClient.post('orders/plan', payload)

  return data
}
