export const SHIPMENT_ACTIONS = [
  'create_spot_quote',
  'get_spot_quote_details',
  'get_routing_guide_rates',
  'delete_shipment',
  'tender_shipment',
  'archive_shipment',
  'unarchive_shipment',
  'duplicate_shipment',
  'cancel_shipment',
  'request_scheduling',
] as const

export const SHIPMENT_STATUS = {
  New: 'new',
  Quoting: 'quoting',
  Expired: 'expired',
  Booked: 'booked',
  Tendering: 'tendering',
  Tendered: 'tendered',
  Tender_rejected: 'tender_rejected',
  Archived: 'archived',
  Canceled: 'canceled',
  Delivered: 'accounting-review',
} as const

export const CONTAINER_STATUS = {
  arrived_at_port: 'arrived_at_port',
  at_facility: 'at_facility',
  available_at_terminal: 'available_at_terminal',
  booked: 'booked',
  delivered_to_terminal: 'delivered_to_terminal',
  departed_facility: 'departed_facility',
  discharged: 'discharged',
  en_route_to_facility: 'en_route_to_facility',
  en_route_to_port: 'en_route_to_port',
  loaded: 'loaded',
  on_hold: 'on_hold',
  returned_to_terminal: 'returned_to_terminal',
} as const

export const QUOTE_STATUS = {
  QUOTING: 'quoting',
  TENDERED: 'tendered',
  BOOKED: 'booked',
  DELETED: 'deleted',
  TENDER_REJECTED: 'tender_rejected',
  EXPIRED: 'expired',
  ARCHIVED: 'archived',
}

export const DOCUMENTS = {
  POD: 'POD',
  BOL: 'BOL',
  Ratecon: 'ratecon',
  Invoice: 'invoice',
} as const

export const SOURCE_OPTIONS = [
  { value: 'created_by_user', label: 'Created by user' },
  { value: 'imported_via_spreadsheet', label: 'Imported via spreadsheet' },
  { value: 'imported_via_integration', label: 'Imported via integration' },
]
