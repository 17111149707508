import {
  IconTruckBulktanker,
  IconTruckDryvan,
  IconTruckFlatbed,
  IconTruckLtlDryvan,
  IconTruckReefer,
  IconTruckDrayage,
  IconTruckRailDrybox,
  IconTruckBobtail,
  IconTruckVentilated,
  IconTruckContainer,
  IconTruckConestoga,
  IconDryvan,
  IconTruckFlatrack,
  IconTruckRollOnRollOff,
  IconTruckStraight,
  IconTruckSprinter,
  IconTruckHotshot,
  IconBoxTruck,
} from '@loadsmart/icons'

type EquipmentType = {
  alias: string
  icon: any
  label: string
  type: string
}

export const EQUIPMENT_TYPES: Record<string, Omit<EquipmentType, 'type'>> = {
  DryVan: {
    icon: IconDryvan,
    alias: 'DRV',
    label: 'Dry Van',
  },
  Flatbed: {
    icon: IconTruckFlatbed,
    alias: 'FBE',
    label: 'Flatbed',
  },
  'Less-than-truckload': {
    icon: IconTruckLtlDryvan,
    alias: 'LTL',
    label: 'Less-than-truckload',
  },
  Reefer: {
    icon: IconTruckReefer,
    alias: 'RFR',
    label: 'Reefer',
  },
  'Port Drayage': {
    icon: IconTruckDrayage,
    alias: 'IMC',
    label: 'Port Drayage',
  },
  'Domestic Rail': {
    icon: IconTruckRailDrybox,
    alias: 'RAIL',
    label: 'Rail',
  },
  'Power Only': {
    icon: IconTruckBobtail,
    alias: 'PWR',
    label: 'Power Only',
  },
  'Bulk Tanker': {
    icon: IconTruckBulktanker,
    alias: 'TANK',
    label: 'Bulk Tanker',
  },
  'Bulk Pneumatics': {
    icon: IconTruckDryvan,
    alias: 'PNMC',
    label: 'Bulk Pneumatics',
  },
  'Reefer or Vented Van': {
    icon: IconTruckVentilated,
    alias: 'VNT',
    label: 'Reefer or Vented Van',
  },
  '40ft High Cube Container': {
    icon: IconTruckContainer,
    alias: '40HC',
    label: '40ft High Cube Container',
  },
  Conestoga: {
    icon: IconTruckConestoga,
    alias: 'CST',
    label: 'Conestoga',
  },
  DryBox: {
    icon: IconTruckContainer,
    alias: 'DRB',
    label: 'Dry Box',
  },
  FlatRack: {
    icon: IconTruckFlatrack,
    alias: 'FRA',
    label: 'Flat Rack',
  },
  Hotshot: {
    icon: IconTruckHotshot,
    alias: 'HOT',
    label: 'Hotshot',
  },
  'Roll on Roll off': {
    icon: IconTruckRollOnRollOff,
    alias: 'RORO',
    label: 'Roll on Roll off',
  },
  'Straight Box Truck': {
    icon: IconTruckStraight,
    label: 'Straight Box Truck',
    alias: 'SBT',
  },
  'Sprinter Van': {
    icon: IconTruckSprinter,
    label: 'Sprinter Van',
    alias: 'SPR',
  },
  'Cargo Van': {
    icon: IconTruckSprinter,
    label: 'Cargo Van',
    alias: 'CGV',
  },
  'Straight Truck': {
    icon: IconTruckStraight,
    label: 'Straight Truck',
    alias: 'STR',
  },
  'Box Truck': {
    icon: IconBoxTruck,
    label: 'Box Truck',
    alias: 'BTR',
  },
} as const

function getInfo(
  equipmentType: string | null | undefined,
  info: 'alias' | 'icon' | 'label'
) {
  const key = Object.keys(EQUIPMENT_TYPES).find((type) => {
    if (!equipmentType) {
      return false
    }
    return type.toLocaleLowerCase() === equipmentType.toLocaleLowerCase()
  })

  if (!key) {
    return ''
  }
  return EQUIPMENT_TYPES[key][info]
}

// TODO: Fix the return type, string is not a valid ReactComponent
function getInfoByAlias(alias: string, info: 'alias' | 'icon' | 'label') {
  const equipmentType = Object.values(EQUIPMENT_TYPES).find((type) => {
    if (!alias) {
      return false
    }
    return type.alias.toLocaleLowerCase() === alias.toLocaleLowerCase()
  })

  if (!equipmentType) {
    return ''
  }
  return equipmentType[info]
}

export const getEquipmentLabel = (equipmentType: string | null | undefined) =>
  getInfo(equipmentType, 'label')

export const getEquipmentLabelByAlias = (alias: string) =>
  getInfoByAlias(alias, 'label')

export const getEquipmentIcon = (equipmentType: string) =>
  getInfo(equipmentType, 'icon')

export const getEquipmentIconByAlias = (alias: string) =>
  getInfoByAlias(alias, 'icon')

export const getEquipmentAlias = (equipmentType: string) =>
  getInfo(equipmentType, 'alias')

export const FILTER_EQUIPMENT_OPTIONS = [
  { value: 'DRV', label: 'Dryvan (DRV)' },
  { value: 'FBE', label: 'Flatbed (FBE)' },
  { value: 'RFR', label: 'Reefer (RFR)' },
  { value: 'DRB', label: 'Dry Box (DRB)' },
  { value: 'TANK', label: 'Tanker (TANK)' },
]

export const FILTER_EQUIPMENT_OPTIONS_WITH_EXP = [
  { value: 'DRV', label: 'Dryvan (DRV)' },
  { value: 'FBE', label: 'Flatbed (FBE)' },
  { value: 'RFR', label: 'Reefer (RFR)' },
  { value: 'DRB', label: 'Dry Box (DRB)' },
  { value: 'TANK', label: 'Tanker (TANK)' },
  { value: 'SPR', label: 'Sprinter Van (SPR)' },
  { value: 'CGV', label: 'Cargo Van (CGV)' },
  { value: 'STR', label: 'Straight Truck (STR)' },
  { value: 'BTR', label: 'Box Truck (BTR)' },
  { value: 'HOT', label: 'Hotshot (HOT)' },
]
