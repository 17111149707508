import { Field, Layout } from '@loadsmart/miranda-react'
import { NmfcSearchDrawer } from '@loadsmart/strangercodes'
import type { SearchDrawerProps } from '@loadsmart/strangercodes'

import { searchNMFC } from 'services/newQuote'

import type { CustomFormFieldProps } from '../HandlingUnits.types'
import { useCommodityFormField } from './CommoditiesForm.hooks'

type CommodityNMFCSearchProps = CustomFormFieldProps &
  Pick<SearchDrawerProps, 'customEmptyState' | 'buttonProps'> & {
    /**
     * When filterByClass is true, the freight class is used to filter the NMFC search results
     */
    readonly filterByClass?: boolean
  }

export const CommodityNMFCSearch = (props: CommodityNMFCSearchProps) => {
  const {
    hint,
    customEmptyState,
    buttonProps,
    filterByClass,
    ...fieldPropsWithoutHint
  } = props
  const { item, setItem, hintProps } = useCommodityFormField({
    name: 'nmfc_search',
    hint,
    ...fieldPropsWithoutHint,
  })

  return (
    <Field {...fieldPropsWithoutHint}>
      {/* This label is used only for field alignment purposes */}
      <Field.Label style={{ visibility: 'hidden' }} aria-hidden="true" />
      <Layout.Group>
        <NmfcSearchDrawer
          customEmptyState={customEmptyState}
          buttonProps={buttonProps}
          initialSearchTerm={item.description}
          filterByClass={
            filterByClass && item.freight_class ? item.freight_class : undefined
          }
          fetch={searchNMFC}
          onSelect={(nmfcItem, searchTerm) => {
            setItem({
              freight_class: nmfcItem.freightClass,
              nmfc_code: nmfcItem.code,
              description: searchTerm,
            })
          }}
        />
      </Layout.Group>
      <Field.Hint {...hintProps} />
    </Field>
  )
}
