import { getHexColor } from '@loadsmart/miranda-react/dist/tokens'
import { useMemo } from 'react'

import type { MarkerRoutePoint } from 'components/Map'
import { FitBounds, MapV2, MarkerRoute, Polyline } from 'components/Map'
import type { Order } from 'orders/types'
import { StopPin } from 'screens/Shipper/Shipments/Details/map/icons/StopPin'
import { SHIPMENT_MAP_GOOGLE_MAP_ID } from 'utils/constants'

const STRAIGHT_ROUTE_COLOR = getHexColor('color-neutral-60')

export const getOrderPath = (order: Order) => {
  return [
    {
      lat: Number(order.pickup_latitude),
      lng: Number(order.pickup_longitude),
    },
    {
      lat: Number(order.delivery_latitude),
      lng: Number(order.delivery_longitude),
    },
  ]
}

export const getMarkerRoutePointFromShipment = (
  order: Order
): MarkerRoutePoint[] => {
  return [
    {
      icon: <StopPin checkedIn={false} />,
      info: 'Pickup',
      markerStyle: { transform: 'translateY(50%)' },
      position: {
        lat: Number(order.pickup_latitude),
        lng: Number(order.pickup_longitude),
      },
    },
    {
      icon: <StopPin checkedIn={false} />,
      info: 'Delivery',
      markerStyle: { transform: 'translateY(50%)' },
      position: {
        lat: Number(order.delivery_latitude),
        lng: Number(order.delivery_longitude),
      },
    },
  ]
}

export type OrderLaneMapProps = Readonly<{
  order: Order
  height?: string
}>

export const OrderLaneMap = ({
  order,
  height = '300px',
}: OrderLaneMapProps) => {
  const path = useMemo(() => {
    return getOrderPath(order)
  }, [order])

  return (
    <div style={{ height }}>
      <MapV2
        page="Order details"
        mapId={SHIPMENT_MAP_GOOGLE_MAP_ID}
        zoomControl
        fullscreenControl
      >
        <Polyline path={path} strokeColor={STRAIGHT_ROUTE_COLOR} />

        <MarkerRoute points={getMarkerRoutePointFromShipment(order)} />

        <FitBounds path={path} control />
      </MapV2>
    </div>
  )
}
