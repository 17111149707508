import { isEmpty, min } from 'lodash'

import { ALLOWED_STATUSES_FOR_CONSOLIDATION } from 'orders/constants'
import type { ListOrder, ListOrderItem, Order, OrderItem } from 'orders/types'
import { PACKAGE_TYPE_LABELS } from 'screens/NewQuote/LTLQuote/LTLQuote.constants'
import { numberFormatter } from 'utils/numbers'

export function formatPackageInfo(
  package_count: ListOrderItem['package_count'],
  package_type: ListOrderItem['package_type']
) {
  const count = numberFormatter(package_count)

  if (!package_type) {
    return count
  }

  return `${count} ${PACKAGE_TYPE_LABELS.get(package_type)}`
}

export function isOrderPlannable(order?: ListOrder | Order) {
  return !!order && ALLOWED_STATUSES_FOR_CONSOLIDATION.includes(order.status)
}

export function calculateOrdersTotalWeight(orders: Order[] | ListOrder[]) {
  return orders.reduce((sum, order) => {
    if (
      !isEmpty(order.total_weight) &&
      !Number.isNaN(Number(order.total_weight))
    ) {
      return sum + Number(order.total_weight)
    }

    return sum
  }, 0)
}

export function convertCelsiusToFahrenheit(
  temperatureInCelsius: number | string | null | undefined
) {
  const convertedTemperatureInCelsius = Number(temperatureInCelsius)

  if (
    temperatureInCelsius == null ||
    (typeof temperatureInCelsius === 'string' &&
      isEmpty(temperatureInCelsius)) ||
    Number.isNaN(convertedTemperatureInCelsius)
  ) {
    return undefined
  }

  return (convertedTemperatureInCelsius * 9) / 5 + 32
}

/**
 * Finds the lowest max_temperature in fahrenheit among Order Items.
 * It converts temperatures in celsius to fahrenheit before the comparison.
 * @param items Order items
 * @returns The lowest, non-NaN value of max_temperature and its UOM (fahrenheit). Empty string in case no value is found.
 */
export function getOrderItemsLowestTemperature(
  items:
    | OrderItem[]
    | ListOrderItem[]
    | Pick<OrderItem, 'max_temperature' | 'max_temperature_uom'>[]
) {
  const temperatures = items
    .filter(
      (item) =>
        !isEmpty(item.max_temperature) &&
        !isEmpty(item.max_temperature_uom) &&
        !Number.isNaN(Number(item.max_temperature))
    )
    .map((item) => {
      if (item.max_temperature_uom === 'fahrenheit') {
        return Number(item.max_temperature)
      }

      return convertCelsiusToFahrenheit(item.max_temperature)
    })

  const lowestTemperature = min(temperatures)

  if (lowestTemperature === undefined) {
    return {
      max_temperature_uom: '',
      max_temperature: '',
    }
  }

  return {
    max_temperature_uom: 'fahrenheit',
    max_temperature: String(lowestTemperature),
  }
}
