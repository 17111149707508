import { convertNumericToString } from 'utils/strings'

export function numberFormatter(
  numberString: string | number | undefined | null,
  decimalPlaces = 3
): string {
  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: decimalPlaces,
  }).format
  const number = Number(numberString || 0)

  if (isNaN(number)) {
    return formatter(0)
  }

  return formatter(number || 0)
}

export function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max)
}

export function removeTrailingDecimalZeroes(value?: string | number | null) {
  const input = convertNumericToString(value)
  if (typeof input === 'string' && !Number.isNaN(Number(input))) {
    return parseFloat(input).toString()
  }

  return value
}

export function removeThousandsSeparators(value?: string | number | null) {
  const input = convertNumericToString(value)
  if (typeof input === 'string') {
    return input.replace(/[^\d.]/g, '')
  }

  return value
}

export function calculatePercentage(partial: number, total: number) {
  if (total === 0) {
    return 0
  }

  return Math.min(100, (100 * partial) / total)
}
