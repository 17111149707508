import type { LiteralUnion } from 'type-fest'

export const SHIPMENT_STATUS_ALIASES = {
  new: ['new'],
  imported: ['imported'], // there's no mention of this status on the backend side

  quoting: ['quoting'],
  tendering: ['tendering'],
  tender_rejected: ['tender_rejected'],
  booked: ['booked'],
  en_route_pickup: ['en_route_pickup', 'en-route-pickup', 'en route pickup'],
  at_pickup: ['at_pickup', 'at-pickup', 'at pickup'],
  in_transit: ['in_transit', 'in-transit', 'in transit'],
  en_route_delivery: [
    'en_route_delivery',
    'en-route-delivery',
    'en route delivery',
  ],
  at_delivery: ['at_delivery', 'at-delivery', 'at delivery'],

  accounting_review: [
    'accounting_review',
    'accounting-review',
    'accounting review',
    'delivered',
  ],

  canceled: ['canceled'],
  expired: ['expired'],
  deleted: ['deleted'],
  archived: ['archived'],
}

export type ShipmentStatus = keyof typeof SHIPMENT_STATUS_ALIASES

export type ShipmentStatusOrString = LiteralUnion<ShipmentStatus, string>

export const SHIPMENT_STATUSES: Record<
  ShipmentStatus,
  {
    order: number
    value: ShipmentStatus
    label: string
  }
> = {
  new: {
    order: 10,
    value: 'new',
    label: 'New',
  },
  // evaluate if this is a terminal status
  imported: {
    order: 20,
    value: 'imported',
    label: 'Imported',
  },
  quoting: {
    order: 30,
    value: 'quoting',
    label: 'Quoting',
  },
  tendering: {
    order: 40,
    value: 'tendering',
    label: 'Tendering',
  },
  tender_rejected: {
    order: 50,
    value: 'tender_rejected',
    label: 'Tender rejected',
  },
  booked: {
    order: 60,
    value: 'booked',
    label: 'Booked',
  },
  en_route_pickup: {
    order: 70,
    value: 'en_route_pickup',
    label: 'En route to pickup',
  },
  at_pickup: {
    order: 80,
    value: 'at_pickup',
    label: 'At pickup',
  },
  in_transit: {
    order: 90,
    value: 'in_transit',
    label: 'In transit',
  },
  en_route_delivery: {
    order: 100,
    value: 'en_route_delivery',
    label: 'En route to delivery',
  },
  at_delivery: {
    order: 110,
    value: 'at_delivery',
    label: 'At delivery',
  },
  accounting_review: {
    order: 120,
    value: 'accounting_review',
    label: 'Accounting review',
  },
  canceled: {
    order: 130,
    value: 'canceled',
    label: 'Canceled',
  },
  expired: {
    order: 140,
    value: 'expired',
    label: 'Expired',
  },
  deleted: {
    order: 150,
    value: 'deleted',
    label: 'Deleted',
  },
  archived: {
    order: 160,
    value: 'archived',
    label: 'Archived',
  },
}

/**
 * Converts a shipment status string to a canonical shipment status name.
 * The canonical status name is considered an implicit alias of itself.
 * @param status string for status to be resolved to a canonical shipment status name.
 * @returns canonical name for the given `status`; `null` if `status` is invalid.
 */
function resolveShipmentStatus(status: string): ShipmentStatus | null {
  if (!status) {
    return null
  }

  const lowerCaseStatus = status.toLowerCase()

  if (lowerCaseStatus in SHIPMENT_STATUS_ALIASES) {
    return lowerCaseStatus as ShipmentStatus
  }

  for (const canonicalCode in SHIPMENT_STATUS_ALIASES) {
    if (
      SHIPMENT_STATUS_ALIASES[canonicalCode as ShipmentStatus].includes(
        lowerCaseStatus
      )
    ) {
      return canonicalCode as ShipmentStatus
    }
  }

  return null
}

export function getShipmentStatus(status: ShipmentStatusOrString) {
  const canonicalStatus = resolveShipmentStatus(status)

  if (canonicalStatus == null) {
    return null
  }

  return SHIPMENT_STATUSES[canonicalStatus]
}

/**
 * Function that is used to determine whether a given shipment status
 * is before, after, after or equals, before or equals, or equals to another status.
 * The function takes a status as input and returns an object with the methods: before, after,
 * afterOrEquals, beforeOrEquals, or equals.
 * @param status
 */
export function isShipmentStatus(status: ShipmentStatusOrString) {
  const resolvedStatus = getShipmentStatus(status)

  return {
    after(otherStatus: ShipmentStatusOrString) {
      const resolvedOtherStatus = getShipmentStatus(otherStatus)

      if (resolvedStatus == null || resolvedOtherStatus == null) {
        return false
      }

      return resolvedStatus.order > resolvedOtherStatus.order
    },
    afterOrEquals(otherStatus: ShipmentStatusOrString) {
      const resolvedOtherStatus = getShipmentStatus(otherStatus)

      if (resolvedStatus == null || resolvedOtherStatus == null) {
        return false
      }

      return resolvedStatus.order >= resolvedOtherStatus.order
    },
    before(otherStatus: ShipmentStatusOrString) {
      const resolvedOtherStatus = getShipmentStatus(otherStatus)

      if (resolvedStatus == null || resolvedOtherStatus == null) {
        return false
      }

      return resolvedStatus.order < resolvedOtherStatus.order
    },
    beforeOrEquals(otherStatus: ShipmentStatusOrString) {
      const resolvedOtherStatus = getShipmentStatus(otherStatus)

      if (resolvedStatus == null || resolvedOtherStatus == null) {
        return false
      }

      return resolvedStatus.order <= resolvedOtherStatus.order
    },
    equals(otherStatus: ShipmentStatusOrString) {
      const resolvedOtherStatus = getShipmentStatus(otherStatus)

      if (resolvedStatus == null || resolvedOtherStatus == null) {
        return false
      }

      return resolvedStatus.order === resolvedOtherStatus.order
    },
    differentThan(...otherStatuses: ShipmentStatusOrString[]) {
      const otherStatusesOrders = otherStatuses
        .map((otherStatus) => getShipmentStatus(otherStatus)?.order)
        .filter(Boolean)

      if (resolvedStatus == null || otherStatusesOrders.length === 0) {
        return false
      }

      return !otherStatusesOrders.includes(resolvedStatus.order)
    },
    oneOf(...otherStatuses: ShipmentStatusOrString[]) {
      const otherStatusesOrders = otherStatuses
        .map((otherStatus) => getShipmentStatus(otherStatus)?.order)
        .filter(Boolean)

      if (resolvedStatus == null || otherStatusesOrders.length === 0) {
        return false
      }

      return otherStatusesOrders.includes(resolvedStatus.order)
    },
  }
}

export function isShipmentExpired(shipment?: {
  status: ShipmentStatusOrString
}): boolean {
  if (!shipment) {
    return false
  }

  const shipmentStatus = getShipmentStatus(shipment.status)
  return shipmentStatus?.value === 'expired'
}
