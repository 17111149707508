import type { AxiosRequestConfig } from 'axios'

import httpClient from 'utils/httpClient'

export const PRICE_ITEM_TYPES_ENDPOINT = '/accounting/price-item-types'

export type PriceItemTypesOptions = {
  modes?: TransportationModeCode | TransportationModeCode[]
  usage?: AccountingObjectType | AccountingObjectType[]
}

export const priceItemTypes = async (
  options?: PriceItemTypesOptions,
  config?: Omit<AxiosRequestConfig, 'params' | 'paramsSerializer'>
) => {
  const { data } = await httpClient.get<PriceItemType[]>(
    PRICE_ITEM_TYPES_ENDPOINT,
    {
      ...config,
      params: options,
      paramsSerializer: (params) => {
        return new URLSearchParams(params).toString()
      },
    }
  )

  return data
}
