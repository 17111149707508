import { IconTrash } from '@loadsmart/icons'
import { Button } from '@loadsmart/miranda-react'

import { useIndexerContext } from 'contexts/indexer'

import { useCommoditiesForm } from './CommoditiesForm.hooks'

export const CommoditiesFormRemoveCommodity = () => {
  const [, dispatch] = useCommoditiesForm()
  const index = useIndexerContext()

  return (
    <Button
      aria-label="Remove item"
      title="Remove item"
      size="small"
      variant="icon"
      onClick={() => {
        dispatch({ type: 'REMOVE_ITEM', payload: { index } })
      }}
    >
      <IconTrash width={12} height={12} title={null} />
    </Button>
  )
}
